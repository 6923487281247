import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {isMobile,isTablet} from 'react-device-detect';
import CloseableModal from '../../components/CloseableModal';

class ConfirmNotif extends Component {
    render() {
        return (
            <CloseableModal 
                onCancel={this.props.onCancel}
                content={
                    <div className={'confirm-notif-wrapper ' + (isMobile?'window-fullscreen':'window')} style={{maxWidth:isTablet?'500px':'400px',borderRadius:'8px'}}>
                        <div className='confirm-notif' style={{}}>
                            <h1 class={isMobile?'c-h4':'c-h3'} style={{margin:'12px 0px 4px 0px'}}>{this.props.mainText}</h1>
                            <h1 class='c-p1' style={{margin:'2px 0px 18px 0px',color:'rgb(80,80,80)'}}>{this.props.seondaryText}</h1>
                            <div style={{display:'flex'}}>
                                <Link className='btn1 b100 btn-gray' to={this.props.onCloseLink} onClick={this.props.onCancel} tabIndex={-1} style={{textDecoration:'none',marginRight:'3px'}}>
                                    Cancel
                                </Link>
                                <button class='btn1 b100 btn-red' onClick={this.props.onAccept} tabIndex={-1} style={{marginLeft:'3px'}}>
                                    {this.props.acceptLabel}
                                </button>
                            </div>
                        </div>
                    </div>
            }/>
        );
    }
}

export default ConfirmNotif;