import React, { Component } from 'react';
import history from '../../history';
import {LOGIN_STATUS} from '../../constants.js';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { auth } from "../../integrations/firebase";
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import OutlinedInput from '@mui/material/OutlinedInput';
import {signInWithEmailAndPassword} from 'firebase/auth';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

class LoginByEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            onAuth:false,
            email:'',
            password:'',
            errorMsg:'',
            errorInputs:''
        }
    }

    validateEmail = (email)=>{
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    changeInputHandler=(event)=>{
        let errorInputs = this.state.errorInputs;
        switch(event.target.id){
            case 'email-input':
                errorInputs=errorInputs.replace(' email','');
                this.setState({email:event.target.value, errorInputs:errorInputs, errorMsg:''});
            break;
            case 'password-input':
                errorInputs=errorInputs.replace(' password','');
                this.setState({password:event.target.value, errorInputs:errorInputs, errorMsg:''});
            break;
            default:break;
        }
    }

    login=async()=>{
            signInWithEmailAndPassword(auth, this.state.email,this.state.password).then((userCreds)=>{
                if(userCreds.user.emailVerified){
                    history.push('/');
                    history.go();
                }else{
                    this.props.setLoginStatus(LOGIN_STATUS.LOGIN_EMAIL_NOT_VERIFIED);
                }
            }).catch((error)=>{
                console.log('loginByEmail->login->signInWithEmailAndPassword: error', JSON.stringify(error));
                switch(error.code){
                    case "auth/user-not-found": this.setState({onAuth:false,errorMsg:"User not found"});break;
                    case "auth/invalid-email":  this.setState({onAuth:false,errorMsg:"Invalid email address"});break;
                    case "auth/wrong-password": this.setState({onAuth:false,errorMsg:"Incorrect password"});break;
                    case "auth/network-request-failed":this.setState({onAuth:false,errorMsg:"Network error. Check your connection and try again."}); break;
                    case "auth/invalid-login-credentials": this.setState({onAuth:false,errorMsg:"Invalid email/password"});break;
                    default:this.setState({onAuth:false,errorMsg:error.code});break;
                }
            }); 
    }

    loginClicked= async()=>{ 
        this.setState({onAuth:true},()=>{
            let errorInputs = '';
            let errorMsg= '';
            if(this.state.email===''){
                errorInputs+=' email';
            } else  if(this.validateEmail(this.state.email)===false){
                errorInputs+=' email';
                errorMsg = "Invalid email address";
            }
            if(this.state.password===''){
                errorInputs+=' password';
            }

            if(errorInputs===''){
                this.login();
            }else{
                this.setState({errorInputs:errorInputs,errorMsg:errorMsg,onAuth:false});
            }

        });
    }

    render() {
        
        return (
            <div class='email-login'>
            <div style={{display:'flex'}}>
                {this.props.backButton}
                <h1 class="c-h3" style={{textAlign:'center',width:'100%'}}>Login using email</h1>
            </div>
            <br/>
            <TextField 
                id="email-input" 
                label="Email"
                error={this.state.errorInputs.includes('email')}
                variant="outlined"
                value={this.state.email}
                onChange={this.changeInputHandler}
                color='success'
                sx={{width:'100%',marginBottom:'20px'}}
            />

            <FormControl sx={{ m: 1, width: '100%',margin:'0px 0px 16px 0px' }} variant="outlined">  
                <OutlinedInput
                    id="password-input"
                    placeholder="Password"
                    error={this.state.errorInputs.includes('password')}
                    value={this.state.password}
                    onChange={this.changeInputHandler} 
                    type={this.state.showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={()=>{this.setState({showPassword:!this.state.showPassword});}}
                                edge="end">
                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>}
                        />
            </FormControl>
              
            <div style={{justifyContent:'right',display:'flex'}}>
                <p className='link-1' onClick={()=>this.props.setLoginStatus(LOGIN_STATUS.LOGIN_FORGOT_PW)}>Forgot password?</p>
            </div>
            <br/>

            
            <p class='error-msg'>{this.state.errorMsg}</p>
            
            <button class="btn1 log-btns b100" tabIndex={-1}
                 style={{pointerEvents:this.state.onAuth?'none':''}} onClick={this.loginClicked}>
                {this.state.onAuth?<CircularProgress fontSize={"small"} sx={{color:'white'}}/>:<p>Login</p>}
            </button>   

            <p style={{width:'100%',textAlign:'center',marginTop:'20px'}}>
                <span>Dont have an account? </span>
                <span className='link-1' onClick={()=>this.props.setLoginStatus(LOGIN_STATUS.SIGNUP_SELECT)}>Register now</span>
            </p>
        </div>
        );
    }
}

export default LoginByEmail;