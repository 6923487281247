export const DEFAULT_PRODUCT_META = 'dflt-product'
export const PRODUCT_META = 
{
    'dflt-product':{
        priceBgColor:'rgb(192, 232, 133)',
        priceColor:'black'
    },
    'monthly-subscription-6':{
        productId:'monthly-subscription-6',
        priceBgColor:'rgb(192, 232, 133)',
        priceColor:'black'
    }
}