const SESSION_KEY = "_SESSION";
const PAGE_CACHE = "_PAGE_CACHE";
class CacheService {
    
    setPageCacheItem=(page,item,value)=>{
        let pageCache = sessionStorage.getItem(PAGE_CACHE);
        pageCache=pageCache!=null?JSON.parse(pageCache):{};
        let specificPageCache = pageCache[page];

        if(specificPageCache==null){specificPageCache={}; }
        specificPageCache[item]=value;
        
        pageCache[page]=specificPageCache;
        sessionStorage.setItem(PAGE_CACHE,JSON.stringify(pageCache));
     }

    getPageCacheItem=(page,item)=>{
       let pageCache = sessionStorage.getItem(PAGE_CACHE);
       if(pageCache!=null){
            pageCache=JSON.parse(pageCache);
            if(pageCache[page]!=null){
                return pageCache[page][item]
            }
       }
       return null;
    }

    updateBusiness = (business, callback) => {
        let session_obj=JSON.parse(localStorage.getItem(SESSION_KEY));
        
        if(business.name!=null){session_obj.business.name=business.name;};
        if(business.lastUpdated!=null){session_obj.business.lastUpdated=business.lastUpdated;};
        if(business.inventoryType!=null){session_obj.business.inventoryType=business.inventoryType;};
        console.log('Cache.updateBusiness',session_obj);
        localStorage.setItem(SESSION_KEY, JSON.stringify(session_obj));
        if(callback!=null){
            callback();
        }
    }
    
    storeSession = (user, business, callback) => {
        let session_obj = {
             user: user
            ,business: business
        };
        localStorage.setItem(SESSION_KEY, JSON.stringify(session_obj));
        if(callback!=null){
            callback();
        }
    }
    
    removeSession = () => {
        this.storeSession(null,null)
    }

    transformVariantArray=(variants)=>{
        let vObject = {};
        variants.forEach((v,idx)=>{
            vObject[v.id] = v;
        });

        return vObject;
    }

    addMenuItem = (newMenuItem,callback) => {
        let session_obj = this.getSession();
        let updatedItems = session_obj.business.menu.items || [];

        if(newMenuItem.variants!=null && newMenuItem.variants.length>0){
            newMenuItem.variants = this.transformVariantArray(newMenuItem.variants);
        }

        updatedItems.push(newMenuItem);
        session_obj.business.menu.items=updatedItems;

        localStorage.setItem(SESSION_KEY, JSON.stringify(session_obj));

        if(callback!=null){
            callback();
        }
    }

    addMenuCategory = (categoryName,categoryId,callback) => {
        let session_obj = this.getSession();
        if(session_obj.business.menu==null){
            session_obj.business.menu={};
        }
        if(session_obj.business.menu.category==null){
            session_obj.business.menu.category={};
        }
        let updatedCategory = session_obj.business.menu.category;
        
        updatedCategory[categoryId] = categoryName;
        session_obj.business.menu.category=updatedCategory;

        localStorage.setItem(SESSION_KEY, JSON.stringify(session_obj));

        if(callback!=null){
            callback();
        }
    }

    editCategoryName = (categoryName,categoryId,callback) => {

        let session_obj = this.getSession();
        let updatedCategory = session_obj.business.menu.category;
        updatedCategory[categoryId] =categoryName;
        session_obj.business.menu.category=updatedCategory;
        localStorage.setItem(SESSION_KEY, JSON.stringify(session_obj));
        if(callback!=null){
            callback();
        }
    }

    deleteMenuCategory = (categoryId,callback) => {

        let session_obj = this.getSession();
        let updatedCategory = session_obj.business.menu.category;

        delete updatedCategory[categoryId]
        session_obj.business.menu.category=updatedCategory;
        localStorage.setItem(SESSION_KEY, JSON.stringify(session_obj));
        if(callback!=null){
            callback();
        }
    }

    removeMenuItem = (idx,callback) => {
        let session_obj = this.getSession();
        let updatedItems = session_obj.business.menu.items; 
        updatedItems.splice(idx,1);
        session_obj.business.menu.items=updatedItems;
        localStorage.setItem(SESSION_KEY, JSON.stringify(session_obj));
        if(callback!=null){
            callback();
        }
    }

    updateMenuItem = (idx,binagongItem, callback) => {
        let session_obj = this.getSession();
        let updatedItems = session_obj.business.menu.items; 

        if(binagongItem.variants!=null && binagongItem.variants.length>0){
            binagongItem.variants = this.transformVariantArray(binagongItem.variants);
        }
        updatedItems[idx]=binagongItem;
        session_obj.business.menu.items=updatedItems;
        localStorage.setItem(SESSION_KEY, JSON.stringify(session_obj));
        if(callback!=null){
            callback();
        }
    }

    
    getSession = () => {
        let storedSessionRaw = localStorage.getItem(SESSION_KEY);
        let storedSession = null;
        if(storedSessionRaw!=null){
            storedSession = JSON.parse(storedSessionRaw);
        }
        return storedSession;
    }
}

export default new CacheService()