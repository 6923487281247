import React, { Component } from 'react';
import ItemService from '../../../service/Firebase/ItemService';
import { Link } from 'react-router-dom';
import {VIEW_TYPE} from './ItemsPage.jsx';
import { DataGrid, GridColDef,GridActionsCellItem } from '@mui/x-data-grid';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    editable: true,
    width:200
  },
  {
    field: 'barcode',
    headerName: 'Barcode',
    align: 'left',
    headerAlign: 'left',
    editable: true,
    width:120
  },
  {
    field: 'sku',
    headerName: 'SKU',
    editable: true,
    width:120
  },
  {
    field: 'price',
    headerName: 'Price',
    editable: true,
  },
  {
    field: 'cost',
    headerName: 'Cost',
    editable: true,
  },
  {
    field: 'variantCount',
    headerName: 'Variants',
    type:'number',
    editable: false,
  }
  
];

class ItemsTable extends Component {
    
    constructor(props) {
        super(props);

        const modifiedColumns = JSON.parse(JSON.stringify(columns))
        modifiedColumns.splice(5,0,{
            field: 'category',
            headerName: 'Category',
            editable: true,
            type: 'singleSelect',
            valueOptions: this.props.exchange.storedSession.business.menu!=null?
                                Object.values(this.props.exchange.storedSession.business.menu.category)
                                :[]
          })
          modifiedColumns.push({
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 100,
            cellClassName: 'actions',
          getActions: ({ id }) => {
            return [
              <Link className='link-2' to={'/items/' + VIEW_TYPE.TABLE.id + '/' + id}>
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={()=>{
                  // @TODO use item. Maybe for view edit or something
                  // let item = this.props.exchange.storedSession.business.menu.items.filter(it=>it.id===id)[0]; 
                }}
                color="inherit"
              />
              </Link>,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={()=>{
                  let item = this.props.exchange.storedSession.business.menu.items.filter(it=>it.id===id)[0];
                  this.props.deleteItemClicked(item)
                }}
                color="inherit"
              />,
            ];
          }
        });    

        let items = this.props.exchange.storedSession.business.menu!=null && this.props.exchange.storedSession.business.menu.items!=null?
                        JSON.parse(JSON.stringify(this.props.exchange.storedSession.business.menu.items))
                        :[]
        items.forEach((item,idx)=>{
            item.category = this.props.exchange.storedSession.business.menu.category[item.categoryId]
            item.variantCount = '-'
            if(item.hasVariant){
                item.variantCount = Object.values(item.variants).length;
            }
        })

        this.state = {
            itemsDatagrid:{
                rows:items,
                columns:modifiedColumns
            }
        }
    }

    async componentDidMount(){
    }
    render() {

        return (
            <div className='items-table-comp' style={{padding:'1.5vh 12px'}}>
                <div className='items-data-grid-wrp'>
                    <DataGrid 
                        sx={{width:'100%',backgroundColor:'white'}}
                        processRowUpdate={async(updatedRow, originalRow) =>{
                            let newCategoryIdx = Object.values(this.props.exchange.storedSession.business.menu.category).indexOf(updatedRow.category);
                            let newCategoryId = Object.keys(this.props.exchange.storedSession.business.menu.category)[newCategoryIdx];
                            updatedRow.categoryId = newCategoryId;
                            let retItenm = originalRow
                            await ItemService.updateV2(updatedRow).then(()=>{
                              this.props.exchange.refreshCache()
                              retItenm= updatedRow;
                            }).catch((error) => {
                              console.log(error)
                              retItenm= originalRow;
                            });

                          return retItenm;
                        }

                    } 
                      rows={this.state.itemsDatagrid.rows} 
                      columns={this.state.itemsDatagrid.columns} />
                </div>
            </div>
        );
    }
}
export default ItemsTable;