
import {ref, get, update, child} from "firebase/database";
import {db} from '../../integrations/firebase';
class SettingsService {
    
    updatePaymentMethods = async (businessId, paymentMethods) => {  
        let path = 'business/'+businessId+'/paymentMethods' 

        const updates = {};
        updates[path] = paymentMethods;
        return update(ref(db), updates);
    }

    getPaymentMethods = async () => {  
        return get(child(ref(db), 'global/business/paymentMethods'));
        
    }

}
export default new SettingsService()