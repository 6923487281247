import React, { Component } from 'react';
import TextUtil from '../util/TextUtil';
import { isMobile, isMobileOnly} from 'react-device-detect';
import Modal from 'react-modal'; 
import { Link } from 'react-router-dom';
import AuthService from '../service/AuthService';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

const MODAL_STYLE = {
    content : {
        transform:'translate(-100%, 0%)', top:'50px', left:'calc(100% - 50px)', right:'auto', bottom:'auto',backgroundColor:'transparent'
       ,boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px', padding:'0px', border:'none',borderRadius:'10px'
    },
     overlay: { 
        transition:'0.4s',zIndex:'100' ,backgroundColor:'transparent'}
}
class UserControlPanel extends Component {

    render() {
        let email = TextUtil.elipsikels(this.props.user.email,29);
        let fullName = ((this.props.user.firstName || '')+ ' ' + (this.props.user.lastName||'')).trim();
        let BTN_STYLE = {padding:'10px 20px',display:'inline-block', color:'black'};
       
        return( 
            <div class='user-control-panel'>
                <div style={{padding:'10px 20px',borderBottom:'solid 1px rgba(0,0,0,0.1)',display:'flex'}}>
                    <div className='photo-d' style={{justifyContent:'center',display:'flex', alignItems:'center', minWidth:'55px',height:'55px',backgroundColor:'rgb(180, 204, 228)',borderRadius:'8px',overflow:'hidden'}}>
                        {this.props.photo}
                    </div>
                    <div style={{padding:'4px 10px'}}> 
                        <p class='c-h4'>{fullName}</p>
                        <p class='c-p2' style={{color:'gray',paddingTop:fullName===''?'12px':''}}>{email}</p>
                    </div>
                </div>

                <div class='cntrol-div-1' style={{borderBottom:'solid 1px rgba(0,0,0,0.1)',width:'100%'}}>
                    <Link class='btn2' style={BTN_STYLE} to={'/settings'} onClick={this.props.closePanel}>Account settings</Link> 
                    <Link class='btn2' style={BTN_STYLE} to={'/help'}  onClick={this.props.closePanel}>Help</Link> 
                </div>

                <div class='cntrol-div-end' style={{borderBottom:'solid 1px rgba(0,0,0,0.1)',width:'100%'}}>
                    <Link class='btn2' onClick={()=>AuthService.logout()}  style={BTN_STYLE} >Log out</Link>
                </div>
            </div>
        );
    }
}
class UserPanel extends Component {
 
    constructor(props) {
        super(props);
        this.state = {
            panelVisible:false
        }
    }
    render() {
        let firstName=this.props.exchange.storedSession.user.firstName;
        let lastName=this.props.exchange.storedSession.user.lastName;

        let photo = null;
        if(this.props.exchange.storedSession.user.picture != null){
            photo = <img class='usr-img' style={{width:'100%',height:'100%'}} alt='profile' src={this.props.exchange.storedSession.user.picture}/>;
        }else if(firstName!=null && lastName!=null) {
            photo = <p class='c-h3' style={isMobileOnly?{fontSize:'1rem',textAlign:'center'}:{fontSize:'1.2rem',textAlign:'center'}}>{(firstName[0] || '')+(lastName[0] || '')}</p>
        }else {
            photo = <p class={isMobile?'c-h5':'c-h4'}>{(this.props.exchange.storedSession.user.email[0] || '').toUpperCase()}</p>;
        }
        return (
            <div class='user-panel' style={isMobile?{margin:'0px',height:'40px',padding:'4px 12px'}:{}}>
                <div class='panel-btn' style={{marginRight:isMobile?'8px':'12px'}}  data-tooltip-id="app-tooltip" data-tooltip-content={"Notifications"}>
                    <NotificationsNoneIcon/>
                </div>
                <div class='user-photo' style={isMobileOnly?{width:'28px',height:'28px',marginTop:'3px'}:{}} onClick={()=>{this.setState({panelVisible:true})}}>{photo}</div> 
                <Modal id="user-control-panel-modal" 
                    shouldCloseOnOverlayClick={true} 
                    isOpen={this.state.panelVisible}
                    shouldCloseOnEsc={true}
                    style={MODAL_STYLE}
                    onRequestClose={()=>{this.setState({panelVisible:false});}}
                    >
                    <UserControlPanel 
                        photo={photo}
                        user={this.props.exchange.storedSession.user}
                        closePanel={()=>{this.setState({panelVisible:false});}}
                        />
                </Modal>
            </div>
        );
    }
}

export default UserPanel;