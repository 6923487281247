import ItemsPage, {VIEW_TYPE} from './ItemsPage';
import { useParams } from 'react-router';
export const ItemsPageComponentWrapper = (props) => {
    const { viewType, itemId, itemAction} = useParams();
    let view = VIEW_TYPE.CATEGORY;
    switch(viewType){
        case VIEW_TYPE.CATEGORY.id: view = VIEW_TYPE.CATEGORY; break;
        case VIEW_TYPE.TABLE.id: view = VIEW_TYPE.TABLE; break;
        default: break;
    }
    return <ItemsPage exchange={props.exchange} view={view} itemId={itemId} itemAction={itemAction} />;
  };