import React, { Component } from 'react';
import './LoginComponent.css';
import {LOGIN_STATUS} from '../constants.js';
import LoginByEmail from './logins/LoginByEmail';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EmailNotVerified from './logins/EmailNotVerified';
import ForgotPassword from './logins/ForgotPassword';

class LoginComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            onLoading:false
        }
    }
    loginDefault=()=>{
        return (
            <div>
                {this.state.onLoading&&
                <div style={{position:'absolute', transform:'translate(-50%, -50%)', top:'50%', left:'50%'}}>
                    <CircularProgress sx={{}}/>
                </div>}
                <div style={{opacity:this.state.onLoading?'0.5':''}}>
                    <h1 class="c-h3" style={{marginBottom:10}}>Log in</h1>
                    <h2 class="c-p1" style={{marginBottom:26}}>Use your email or another service to continue with Cashr POS!</h2>
                    <div style={{pointerEvents:this.state.onLoading?'none':''}}>
                        {/* <button class="login-opt-btn" type="button" onClick={this.googleSignUpClicked}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="24" width="24"><path fill="#4285f4" d="M386 400c45-42 65-112 53-179H260v74h102c-4 24-18 44-38 57z"></path><path fill="#34a853" d="M90 341a192 192 0 0 0 296 59l-62-48c-53 35-141 22-171-60z"></path><path fill="#fbbc02" d="M153 292c-8-25-8-48 0-73l-63-49c-23 46-30 111 0 171z"></path><path fill="#ea4335" d="M153 219c22-69 116-109 179-50l55-54c-78-75-230-72-297 55z"></path></svg>
                            <div class="login-opt-title">Continue with Google</div>
                        </button> */}
                        {/* <button class="login-opt-btn" type="button" onClick={this.facebookSignUpClicked}>
                            <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M21.5 12.058c0-5.28-4.253-9.558-9.5-9.558s-9.5 4.279-9.5 9.558c0 4.771 3.473 8.725 8.016 9.442v-6.68H8.104v-2.762h2.412V9.952c0-2.395 1.417-3.718 3.588-3.718 1.04 0 2.126.186 2.126.186v2.352h-1.198c-1.18 0-1.548.738-1.548 1.494v1.792h2.635l-.421 2.763h-2.214V21.5c4.543-.717 8.016-4.67 8.016-9.442z" fill="currentColor"></path></svg>
                            <div class="login-opt-title">Continue with Facebook</div>
                        </button> */}
                        <button class="login-opt-btn" type="button" onClick={()=>this.props.setLoginStatus(LOGIN_STATUS.LOGIN_BY_EMAIL)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M20.37 5.03A2 2 0 0 1 22 7v10a2 2 0 0 1-1.96 2H4a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h16.1H4zm.13 2.07-4.53 5.31 4.53 4.63a.5.5 0 0 0 0-.04V7.1zm-17-.14a.5.5 0 0 0 0 .04v10a.5.5 0 0 0 0 .04l4.59-4.7L3.5 6.97zm5.57 6.53-3.92 4 13.7.01L15 13.56a4 4 0 0 1-5.93-.07zm9.88-6.99H5l5.07 5.96a2.5 2.5 0 0 0 3.81 0l5.07-5.96z"></path></svg>
                            <div class="login-opt-title">Continue with email</div>
                        </button>    
                    </div>
                    <br/>
                    <p class="c-p2" style={{color:'rgba(13,18,22,.86)'}}>By continuing, you agree to Cashr's <a class="oUJMcQ qN_0PQ" href="https://www.cashr.cc/terms-of-use/" draggable="false" target="_blank" rel="noopener">Terms of Use</a>. Read our <a class="oUJMcQ qN_0PQ" href="https://www.cashr.cc/privacy-policy" draggable="false" target="_blank" rel="noopener">Privacy Policy</a>.</p>            
                </div>
               </div>
        )
    } 

    backButton=(newStatus)=>{
        return (
            <IconButton aria-label="close"
                onClick={()=>this.props.setLoginStatus(newStatus)}
                sx={{"&:hover": { color: "green", backgroundColor:'transparent'}, padding:'10px 2px'}}>
                    <ArrowBackIosNewIcon fontSize="small" />
            </IconButton>    
        )
    }

    render() {
        let content = null;
        switch(this.props.status){
            case LOGIN_STATUS.LOGIN_SELECT:             content=this.loginDefault();       break;
            case LOGIN_STATUS.LOGIN_BY_EMAIL:           content= <LoginByEmail setLoginStatus={this.props.setLoginStatus} onClose={this.props.onClose} backButton={this.backButton(LOGIN_STATUS.LOGIN_SELECT)}/>;break;
            case LOGIN_STATUS.LOGIN_EMAIL_NOT_VERIFIED: content= <EmailNotVerified  setLoginStatus={this.props.setLoginStatus} />; break;
            case LOGIN_STATUS.LOGIN_FORGOT_PW:          content= <ForgotPassword setLoginStatus={this.props.setLoginStatus}  backButton={this.backButton(LOGIN_STATUS.LOGIN_BY_EMAIL)}/>; break;
            default:break;
        }
        return (
            <div className='login-signup-component'>
                <div className='lsc-main'>
                    {content}
                </div>
            </div>
        );
    }
}

export default LoginComponent;