import React, { Component } from 'react';
import { auth } from "../../integrations/firebase";
import {LOGIN_STATUS} from '../../constants.js';
import CircularProgress from '@mui/material/CircularProgress';

import {sendEmailVerification} from 'firebase/auth';

class EmailNotVerified extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            onLoading:false,
            errorMessage:''
        }
    }

    resendEmailVerification=()=>{
        // this.setState({ onLoading:true,errorMessage:''},
        //     ()=>{
                auth.onAuthStateChanged((response) => {
                    console.log('response',response)
                    if (response) {
                        sendEmailVerification(response).then(()=>{
                            console.log('Email resend success');
                            // this.props.setLoginStatus(LOGIN_STATUS.SIGNUP_VERIFY_EMAIL);
                            this.setState({onLoading:false});
                        }).catch((verErr)=>{
                            console.log(' resendEmailVerification verErr',verErr);
                            switch(verErr.code){
                                case "auth/too-many-requests":
                                    this.setState({onLoading:false,errorMessage:'Too many request, try again later.'})
                                break;
                                case "auth/network-request-failed":
                                    this.setState({onLoading:false,errorMessage:"Network error. Check your connection and try again."});
                                break;
                                default: this.setState({onLoading:false,errorMessage:'Something went wrong, please try again later'});break;
                            }
                           
                        });
                    } else {
                        this.setState({onLoading:false,errorMessage:'Something went wrong, please try again later'});
                    }
                  });
            // });
    }

    render() {
        return (
            <div className='verify-email' style={{pointerEvents:this.state.onLoading?'none':''}}>
                <h1 class="c-h2" style={{margin:'8px 0px 10px 4px',textAlign:'center'}}>Email not yet verified</h1>
                <h2 class="c-p1" style={{marginBottom:26,textAlign:'center'}}>{"To complete signup, verify your account by clicking the link sent to your email"}</h2>
                <button class="btn1 b100" onClick={()=>this.props.setLoginStatus(LOGIN_STATUS.LOGIN_BY_EMAIL)}>
                    Go to login
                </button>
                <br/>
                <div style={{textAlign:'center'}}>
                    <span>Didn't get e-mail? </span>
                    <span className='link-1' onClick={this.resendEmailVerification}>Resend verification</span>
                    {this.state.onLoading&&<CircularProgress size="12px" sx={{marginLeft:'6px'}}/>}
                </div>
                <br/>
                <p style={{color:'red',textAlign:'center'}}>{this.state.errorMessage}</p>
            </div>
        );
    }
}

export default EmailNotVerified;