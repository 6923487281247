import React, { Component } from 'react';
import './CheckoutPage.css';
import Cart from '../cart/Cart';
import history from '../../../history';
import PosHeader from '../PosHeader';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import WaitPage from '../../waiting/WaitPage';
import MenuItem from '@mui/material/MenuItem';
import OrderTotal from './../order/OrderTotal';
import TextField from '@mui/material/TextField';
import VirtualCashInput from './VirtualCashInput';
import FormControl from '@mui/material/FormControl';
import CancelIcon from '@mui/icons-material/Cancel';
import CartService, {DISCOUNT_TYPE,DISCOUNT_TYPE_ID,SENIOR_DISCOUNT_PERC,PWD_DISCOUNT_PERC} from '../../../service/CartService';
import AddCardIcon from '@mui/icons-material/AddCard';
import PercentIcon from '@mui/icons-material/Percent';
import MessageBoard from '../../../components/MessageBoard';
import FirebaseService from '../../../service/FirebaseService';
import CloseableModal from '../../../components/CloseableModal';
import CircularProgress from '@mui/material/CircularProgress';
import { getDatabase, ref, onValue } from "firebase/database";
import Select from '@mui/material/Select';
import {SIDE_ACTION_WIDTH,SIDE_ACTION_WIDTH_M} from '../register/RegisterPage';
import OfflineDatabaseService from '../../../service/OfflineDatabaseService';
import { isMobile,isTablet,isMobileOnly,BrowserView,MobileView, isDesktop } from 'react-device-detect';
import {PAYMENT_METHODS} from '../../../constants';
const db = getDatabase(); 
const CASH_DENOMINATION=[20,50,100,200,500,1000];

const CART_WINDOW_URI = 'cart';
const DISCOUNT_WINDOW_URI = 'discount';

const KB_ESC_KEY = 27;
const KB_ENTER_KEY = 13;
class CheckoutPage extends Component {
    constructor(props) {
        super(props);

        this.backButtonRef = React.createRef(); 
        this.chargeButtonRef = React.createRef(); 
        this.posHeaderElementRef = React.createRef();
        
        this.state = {
            onAddPayment:false,
            payments:[],
            onInputCustomDiscount:false,
            showVirtualCashInput:false,
            custom:'',
            discount:{id:''},
            onLoad:true,
            width:window.innerWidth,
            billInput:this.resetBillInput(),
            inventory:{},
            onChargeLoad:false,
            KEY_SHORTCUTS:{BACKBUTTON:KB_ESC_KEY,CHARGE_BUTTON:KB_ENTER_KEY},
            allowOtherPaymentMethod:this.isOtherPaymentMethodAllowed()
        }
        
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        this.setState({onLoad:false});

        //listener for the back button
        this.unlisten = history.listen((location, action) => {
            if(location.location.pathname==='/checkout'){
                this.forceUpdate();
            }
          });

        window.addEventListener('resize', this.updateDimensions);

        const invetoryRef = ref(db, 'inventory/' + this.props.exchange.storedSession.business.id);
        onValue(invetoryRef, (snapshot) => {
            const data = snapshot.val();
            if(this.state.onChargeLoad===false){//prevent ui updates when already charging
                this.setState({inventory:data});
            }
        });


        //Listen to keyboard
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        this.unlisten();
        window.removeEventListener('resize', this.updateDimensions);
        document.removeEventListener("keydown", this.handleKeyDown);
    }


    handleKeyDown = (event) => {
        // console.log('keyCode',event.keyCode);
        switch(event.keyCode) {
            case this.state.KEY_SHORTCUTS.BACKBUTTON:
                if(this.backButtonRef.current!=null){
                    this.backButtonRef.current.click();
                }
            break;
            case this.state.KEY_SHORTCUTS.CHARGE_BUTTON:
                if(this.chargeButtonRef.current!=null){
                    this.chargeButtonRef.current.click();
                }
            break;
            
            default: 
                break;
        }
    }

    updateDimensions = () => {
        this.setState({ width:window.innerWidth});
    }
    
    isOtherPaymentMethodAllowed=()=>{
        let allowOtherPaymentMethod = false;
        let paymentMethods = this.props.exchange.storedSession.business.paymentMethods
        if(paymentMethods!=null){
            paymentMethods=Object.values(paymentMethods);
            allowOtherPaymentMethod=paymentMethods.filter(pm=>pm.enabled&&pm.id!=='cash').length>0;
        }
        return allowOtherPaymentMethod;
    }
    
    resetBillInput=()=>{
        let billInput = [];
        CASH_DENOMINATION.forEach(bill=>{
            billInput.push({count:0})
        });
        return billInput;
    }

    changeInputHandler=(event)=>{
        switch(event.target.id){
            case 'custom-cash-input': 
            if(!isNaN(event.target.value)){
                let val = Number(event.target.value);
                if(event.target.value===''){
                    val='';
                }
                if(val<10000000000){
                    this.setState({custom:val});
                }
            }
            break;
            
        default:
                break;
        }
    }

    getCustomAmountInput=()=>{
        let isVirtualKeyInput = isMobile;

        return (
            <div className='cash-denom-btn' onClick={isVirtualKeyInput?()=>this.setState({showVirtualCashInput:true}):null} style={{minWidth:'40%',display:'flex',justifyContent:'center', width:'fit-content', maxWidth:'none',padding:'6px'}}>
                    <input
                        id="custom-cash-input"
                        placeholder={'Amount received'}
                        value={this.state.custom}
                        type="tel"
                        onChange={this.changeInputHandler}
                        style={{border:'0', pointerEvents:isVirtualKeyInput?'none':'', outline:'none', boxShadow:'none', backgroundColor:'transparent',color: 'black',fontSize:'0.9rem',textAlign:'center'}}>
                    </input> 
                    {/* <div className='ibtn3' style={{position:'fixed', marginTop:'3px',opacity:this.state.custom!==''?'':'0',marginBottom:'2px'}} onClick={()=>this.setState({custom:''})}>
                        <CancelIcon  sx={{color:'lightgray',fontSize:'18px'}}/>
                    </div> */}
            </div>
        )
    }

    renderBillInput=(orderInfo)=>{
        let inputButtons=[];

        inputButtons.push(
            <div style={{width:'100%',display:'flex',justifyContent:'center'}}>{
                this.getCustomAmountInput()}
            </div>)

        CASH_DENOMINATION.forEach((val,idx)=>{
            let count =this.state.billInput[idx].count;
            inputButtons.push(
                <div className='cash-denom-btn' onClick={()=>
                    {   
                        let billInput=this.state.billInput;
                        billInput[idx].count = count+1;
                        this.setState({billInput:billInput});
                    }}>
                    {count>0&&
                    <div class='bill-count-wrapper'>
                        <div class='bill-count'>
                            <p style={{width:'100%'}}>{count}</p>
                        </div>
                    </div>}
                    <div class='centerItem' style={{userSelect:'none'}}>
                        {val}
                    </div>
                </div>
            )
        });

        inputButtons.push(
            <div className='cash-denom-btn' onClick={()=>{
                    this.setState({custom:'',billInput:this.resetBillInput()});
                }}>
                <div class='centerItem'>Clear </div>
            </div>
        )

        inputButtons.push(
            <div className='cash-denom-btn' style={orderInfo.items.size>0&&this.getCashReceived()===orderInfo.totalPrice?{backgroundColor:'rgb(61,239,209)',borderColor:'rgb(61,239,209)'}:{}}
                onClick={()=>{
                    this.setState({custom:orderInfo.remainingAmountDue,billInput:this.resetBillInput()});
                }}>
                <div class='centerItem'>Exact</div>
            </div>
        )
        return (
            <div className='cash-denomination'>
                {inputButtons}
            </div>
        );
    }

    getCashReceived=()=>{
        let totalBills = 0;
        CASH_DENOMINATION.forEach((cashVal,idx)=>{
            totalBills+= cashVal*this.state.billInput[idx].count;
        })
        return Number(totalBills) + Number(this.state.custom);
    }

    buildOrderInfo=()=>{
        return CartService.buildOrderInfo(
                    this.state.payments,
                    this.state.discount,
                    this.getCashReceived())
    }

    onChargeClicked=()=>{

        this.setState({onChargeLoad:true}
            ,this.props.exchange.noInternet?
                 this.saveOrderOffline
                :this.saveOrderToFirebase
        );
    }

    addPayment=(pay)=>{
        let payments = this.state.payments;
        payments.push(pay);
        this.setState({payments:payments,onAddPayment:false},()=>{
            let orderInfo = this.buildOrderInfo();
            if(pay.amount===orderInfo.totalPrice){
                this.onChargeClicked()
            }

        })
    }

    saveOrderToFirebase=()=>{
        let orderInfo = this.buildOrderInfo();
        FirebaseService.saveOrder(this.props.exchange.storedSession.business.id, orderInfo)
            .then(()=>this.onOrderSaveComplete(orderInfo))
            .catch(err=>{
                this.setState({onChargeLoad:false});
                console.error('CP00001:',err);
            });
    }

    saveOrderOffline=()=>{
        let orderInfo = this.buildOrderInfo();
        OfflineDatabaseService.saveOrder(this.props.exchange.storedSession.business.id, orderInfo, this.onOrderSaveComplete);
    }

    onOrderSaveComplete=(orderInfo)=>{
        CartService.removeOrderInfo();
        history.push('/sale-complete/'+orderInfo.orderNumber);
        history.go();
    }

    numberWithCommas=(x)=>{
        x = Math.round((x + Number.EPSILON) * 100) / 100;
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    renderCashRendered=(orderInfo)=>{
        let hdrStyle = 
            {
                 color:'rgb(100,100,100)'
                ,fontSize:isMobileOnly?'0.9rem':'1rem'
                ,fontWeight:isMobile?'500':''
            };
        return (
            <div class='cash-rendered'>
                <div class='cash-resib' style={{width:'100%'}}>
                    <h3 style={hdrStyle}>Amount Due</h3>
                    <BrowserView><h1>{"₱"+this.numberWithCommas(orderInfo.remainingAmountDue)}</h1></BrowserView>
                    <MobileView><h2>{"₱"+this.numberWithCommas(orderInfo.remainingAmountDue)}</h2></MobileView>
                    {this.state.discount.id!==''&&
                    <div style={{fontSize:'10px',display:'flex',justifyContent:'center',height:'17px'}}>
                        <p style={{backgroundColor:'rgb(232,44,121)',color:'white',width:'fit-content',padding:'1px 6px',borderRadius:'5px'}}>Discounted</p>
                    </div>}
                </div>
                <div class='cash-sukli' style={{width:'100%',borderLeft:'solid 1px rgb(240,240,240)'}}>
                    <h3 style={hdrStyle}>Change</h3>
                    {(orderInfo.cashPayment.change<0)&&<h2>-</h2>}
                    
                    {orderInfo.cashPayment.change>=0&&
                        <h1 style={{color:orderInfo.cashPayment.change<0?'red':''}}>{"₱"+this.numberWithCommas(orderInfo.cashPayment.change)}</h1>}    
                </div>
           </div>
        );
    }

    renderPaymentInputs=(orderInfo,cartEmpty)=>{
        let remaining = orderInfo.remainingAmountDue-orderInfo.cashPayment.cashReceived
        return (
            <div class='payment-inputs' style={{pointerEvents:cartEmpty?'none':''}}>
                <div className='pay-in-status'>
                    <p style={{fontSize:'0.9rem', textAlign:'center', fontWeight:'500'}}>{"Cash: ₱"+this.numberWithCommas(orderInfo.cashPayment.cashReceived)}</p>
                    {remaining>0&&
                    <p style={{fontSize:'0.9rem',textAlign:'center', fontWeight:'500'}}>{"Remaining: ₱"+this.numberWithCommas(remaining)}</p>}
                    
                </div>
                <div className='bill-input-cont' style={{textAlign:'center',justifyContent:'center',alignItems:'center',display:'flex'}}>
                    {this.renderBillInput(orderInfo)}
                </div>
            </div>
        );
    }

    setDiscountPercentage=(percent)=>{
        if(percent===0){
            this.setState({onInputCustomDiscount:false,discount:{id:''}});
        }else{
            this.setState({onInputCustomDiscount:false,discount:{id:DISCOUNT_TYPE_ID.CUSTOM_PERC,type:DISCOUNT_TYPE.PERCENTAGE,value:percent}});
        }
     }

    setDiscount=(id)=>{
        if(id==null){
            this.setState({discount:{id:''}});
        } else{
            switch(id){
                case DISCOUNT_TYPE_ID.SENIOR:
                    if(id===this.state.discount.id){
                        this.setState({discount:{id:''}});
                    } else {
                        this.setState({discount:{id:id,type:DISCOUNT_TYPE.PERCENTAGE,value:SENIOR_DISCOUNT_PERC}});
                    }
                break;
                case DISCOUNT_TYPE_ID.PWD:
                    if(id===this.state.discount.id){
                        this.setState({discount:{id:''}});
                    } else {
                        this.setState({discount:{id:id,type:DISCOUNT_TYPE.PERCENTAGE,value:PWD_DISCOUNT_PERC}});
                    }
                break;
                case DISCOUNT_TYPE_ID.CUSTOM_PERC:
                   this.setState({onInputCustomDiscount:true});
                break; 
                default:break;
            }
        }
    }

    renderDiscountsBar=(isWindow,cartEmpty)=>{

        return(
            <div className='discounts-bar' style={{pointerEvents:cartEmpty?'none':'',textAlign:'center'}}>
                {isWindow?
                <h3 style={{ marginBottom:'12px' }}>Discount</h3>:
                <p style={{ fontSize: '12px', fontWeight: '500', margin:'2px 0px', color: 'rgb(104, 104, 104)'}}>Discount</p>}
                <div className='discount-opts'>
                    <button className={(isMobile?'btn1-m ':'btn1 ')+(this.state.discount.id===DISCOUNT_TYPE_ID.SENIOR?'disc-btn-selected':'disc-btn')}
                        style={isMobile?{width:'20px'}:{}}
                        onClick={()=>this.setDiscount(DISCOUNT_TYPE_ID.SENIOR)} tabIndex={-1}>
                        Senior
                    </button>

                    <button className={(isMobile?'btn1-m ':'btn1 ')+(this.state.discount.id===DISCOUNT_TYPE_ID.PWD?'disc-btn-selected':'disc-btn')}
                        onClick={()=>this.setDiscount(DISCOUNT_TYPE_ID.PWD)} tabIndex={-1}>
                        PWD
                    </button>

                    <button className={(isMobile?'btn1-m ':'btn1 ')+(this.state.discount.id===DISCOUNT_TYPE_ID.CUSTOM_PERC?'disc-btn-selected':'disc-btn')}
                        onClick={()=>this.setDiscount(DISCOUNT_TYPE_ID.CUSTOM_PERC)} tabIndex={-1}>
                        {this.state.discount.id===DISCOUNT_TYPE_ID.CUSTOM_PERC?this.state.discount.value+'%':
                        <PercentIcon sx={{fontSize:'21px'}}/>}
                    </button>
                </div>
            </div>
        )
    }

    render() {
        let cartItems = CartService.getCartItems();
        let orderInfo=this.buildOrderInfo()
        let isSmall = this.state.width < 800 || isMobile;
        let cartEmpty = cartItems.length===0;

        let cashIsEnough = orderInfo.cashPayment.amount >= orderInfo.totalPrice
        let canAddOtherPayment = orderInfo.payments.length===0 && !cashIsEnough;

        let ChargeButton = (props)=>{ 
            return (cartEmpty?null:
                <button className={'btn1 ' + (isMobile?'charge-btn-mobile':'charge-btn')} ref={this.chargeButtonRef} tabIndex={-1}
                    onClick={this.onChargeClicked}>
                    {this.state.onChargeLoad?
                        <CircularProgress sx={{color:'white'}}/>:"Submit Sale"} 
                </button>
            );
        }

        let PaymentOptions = ()=>{ 

            return <div className='pay-opts'>
                        <button className={'btn1 btn-second split-pay-option' + (canAddOtherPayment?'':' btn-second-disabled')} tabIndex={-1}
                            onClick={()=>this.setState({onAddPayment:true})}>
                            <AddCardIcon/><p style={{paddingLeft:'6px'}}>Add Payment</p>
                        </button>
                    </div>
        }

        let CartActionDiv = (props) => {
            return(
                <div className='cart-action-div'>
                    {this.state.allowOtherPaymentMethod&&<PaymentOptions payments={this.state.payments} />}
                    <ChargeButton isSmall={isSmall}/>
                </div>
            )
        }

        let headerHeight = '0px'
        if(this.posHeaderElementRef!=null && this.posHeaderElementRef.current!=null){
            headerHeight = this.posHeaderElementRef.current.clientHeight +'px';
        }
        let sideActionVisible = isSmall;
        return (
            this.state.onLoad?<WaitPage/> :
            <div className='page checkout-page' style={this.state.onChargeLoad?{pointerEvents:'none'}:{}} >
                <PosHeader 
                    title={'Checkout'}
                    content={this.renderCashRendered(orderInfo)}
                    exchange={this.props.exchange}
                    backLink={{path:'/register', ref:this.backButtonRef}} 
                    elementRef={this.posHeaderElementRef}
                    />
                <div className='page-body' style={{display:'flex',overflow:'hidden',height:'calc(100vh - ' + headerHeight+ ')'}}>
                    <div className='payment-inputs-div' style={{width:sideActionVisible?'calc(100% - ' +(isMobile?SIDE_ACTION_WIDTH_M:SIDE_ACTION_WIDTH)+'px)':'100%',paddingBottom:'20vh', overflowY:'scroll'}}>
                        
                        {cartEmpty&&
                            <MessageBoard contents={
                                <div style={{fontSize:'0.9rem'}}>
                                <span>😟 Cart is empty! </span>
                                <Link class='link-1' to='/register'>Click here to add items</Link>
                                </div>
                        }/>}
                        <div className='payment-div' style={{opacity:cartEmpty?'0.5':''}}>
                            {this.renderPaymentInputs(orderInfo,cartEmpty)}
                            {!isSmall&&
                            <div className='pay-actions-div' style={{}}>
                                {this.renderDiscountsBar(false,cartEmpty)}
                            </div>}

                            {isSmall&&
                            <OrderTotal
                                items={cartItems}
                                orderInfo={orderInfo}
                                isSmall={isSmall}
                                // actionDiv={<PaymentOptions payments={this.state.payments}/>}
                            />}
                
                        </div>
                    </div>
                  
                    {!isSmall&&
                    <div className='cart-containter-wrpr' style={{padding:'6px 10px'}}>
                        <div class='cart-container'>
                            <Cart 
                                actionDiv={<CartActionDiv/>} 
                                orderInfo={orderInfo} 
                                isSmall={isSmall} 
                                inventory={this.state.inventory}
                                showCheckout={false} 
                                parentUpdate={()=>{this.forceUpdate()}}
                                />
                        </div>
                    </div>}

                    
                    {sideActionVisible&&
                    <div className='reg-side-action' style={{backgroundColor:'rgb(244,244,246)',minWidth:(isMobile?SIDE_ACTION_WIDTH_M:SIDE_ACTION_WIDTH)+'px'}}>
                        <Link className='btn1 rega-btn' tabIndex={-1}  to={'/checkout/'+CART_WINDOW_URI} onClick={()=>this.forceUpdate()}>Cart</Link>
                        <Link className='btn1 rega-btn' tabIndex={-1}  to={'/checkout/'+DISCOUNT_WINDOW_URI} onClick={()=>this.forceUpdate()}>Discount</Link>
                        <div style={{height:'2px',margin:'4px 0px'}}></div>

                        {this.state.allowOtherPaymentMethod&&
                        <Link className={'btn1 rega-btn' +(canAddOtherPayment?'':' rega-btn-disabled')} tabIndex={-1}
                             onClick={()=>this.setState({onAddPayment:true})} style={{borderTop:'solid 1px gainsboro'}}>
                             <AddCardIcon/>
                        </Link>}
                    </div>}
                </div>

                {isSmall&&
                <div className='action-btns' style={{position:'sticky',bottom:this.props.exchange.hasSideBar?'0px':'38px', display:'flex',width:isSmall?'calc(100% - ' + SIDE_ACTION_WIDTH + 'px)':'100%'}}>
                    <div style={{width:'100%',margin:'0px 10px',justifyItems:'center',alignContent:'center',display:'grid',marginBottom:'18px'}}>
                        <ChargeButton/>
                    </div>
                </div>}
                
                {this.props.windowId===CART_WINDOW_URI&&
                <CloseableModal
                     content={
                        <Cart 
                            floating={true} 
                            showCheckout={false}
                            onCancelLink={'/checkout'}
                            parentUpdate={()=>{this.forceUpdate()}}
                            inventory={this.state.inventory}
                            /> 
                     }
                     onCancelLink={'/checkout'}
                />}

                {this.state.onInputCustomDiscount&&
                <CloseableModal
                     content={
                      <DiscountPercentageInput
                        setDiscountPercentage={this.setDiscountPercentage}
                        discount={this.state.discount}
                        orderInfo={orderInfo}
                        onCancel={()=>this.setState({onInputCustomDiscount:false})}
                      />
                     }
                     onCancel={()=>this.setState({onInputCustomDiscount:false})}
                />}

                {this.state.showVirtualCashInput&&
                <CloseableModal
                    content={
                        <VirtualCashInput
                            amount={this.state.custom}    
                            onCancel={()=>this.setState({showVirtualCashInput:false})}
                            setCustomAmountInput={(inputzx)=>{this.setState({custom:inputzx,showVirtualCashInput:false})}}
                        />
                    }
                    onCancel={()=>this.setState({showVirtualCashInput:false})}
                />}

                {this.state.onAddPayment&&
                <CloseableModal
                    content={
                        <AddPayment 
                            orderInfo={orderInfo}
                            exchange={this.props.exchange}
                            onChargeClicked={this.onChargeClicked}
                            addPayment={this.addPayment}
                            onCancel={()=>this.setState({onAddPayment:false})}
                        />
                    }
                    onCancel={()=>this.setState({onAddPayment:false})}
                />}

                {this.props.windowId===DISCOUNT_WINDOW_URI&&
                <CloseableModal
                     content={
                        <div style={{backgroundColor:'white',padding:'30px 20px 20px 20px',width:'90vw',maxWidth:'480px'}}>
                            {this.renderDiscountsBar(true,cartEmpty)}
                            <div style={{width:'100%',marginTop:'8px'}}>
                                <Link class='btn1' style={{width:'100%'}} to={'/checkout'}>Okay</Link>
                                {/* <div class='centerItem' style={{marginTop:'8px'}}>
                                    <Link style={{width:'100%',textAlign:'center'}} to={'/checkout'}  onClick={()=>this.setDiscount(null)} >Cancel</Link>
                                </div> */}
                            </div>
                        </div>
                     }
                     onCancelLink={'/checkout'}
                />}

            </div>
        );
    }
}

class AddPayment extends Component{

    constructor(props) {
        super(props);
        let paymentMethods = 
                Object.values(this.props.exchange.storedSession.business.paymentMethods)
                    .filter(pm=>pm.enabled&&pm.id!=='cash')
                    .map(pm=>({id:pm.id,label:PAYMENT_METHODS[pm.id].label}))
        
        this.state={
            paymentMethods:paymentMethods,
            selectedMethod:null,
            amount:'',
            errorMsg:''
        }
    }

    isExactAmount=()=>{
        console.log('xxx', this.props.orderInfo)
        let cardPaymentAmount = Number(this.state.amount);
        return this.props.orderInfo.totalPrice===cardPaymentAmount || 
                (this.props.orderInfo.cashPayment.amount+cardPaymentAmount === this.props.orderInfo.totalPrice);
    }
    addClicked=()=>{ 
        this.props.addPayment({
            type:this.state.selectedMethod,
            amount:Number(this.state.amount)});
    }

    pesoWithCommas=(x)=>{
        let roundedX= Math.round((x + Number.EPSILON) * 100) / 100;
        return '₱'+roundedX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    render(){
        let canAccept = this.state.selectedMethod!==null && this.state.amount!=='' && Number(this.state.amount)>0;
        let canAddExact = this.props.orderInfo.cashPayment.cashReceived>=this.props.orderInfo.remainingAmountDue;
        let payOptionMenuItem=this.state.paymentMethods.map(pm=>(<MenuItem value={pm.id}>{pm.label}</MenuItem>))
        return(
            <div className={'add-payment ' + isMobile?'window-fullscreen':'window'}>
                <div className='gwrap' style={{textAlign:'left'}}>
                    <h3 style={{textAlign:'center'}}>Add Payment</h3>
                    <br/>
                    <Box sx={{ minWidth: 80}}>
                        <p className='c-in2'>Payment Method</p>
                        <FormControl fullWidth>
                            <Select
                                id="paymethod-select"
                                value={this.state.selectedMethod}
                                // error={true}
                                onChange={(event)=>{
                                    this.setState({selectedMethod:event.target.value,errorMsg:''})
                                }}
                                >
                            {payOptionMenuItem}
                            </Select>
                        </FormControl>
                    </Box>

                    <p className='c-in2'  style={{marginTop:'12px'}}>Amount</p>
                    <div style={{display:'flex'}}>
                        <div style={{width:'100%'}}>
                            <TextField
                                fullWidth
                                required
                                id="amount-input"
                                type="telephone"
                                placeholder={'Amount'}
                                value={this.state.amount}
                                onChange={(event)=>{
                                    if(Number(event.target.value)<=9999999){
                                        this.setState({amount:event.target.value});
                                    }
                                }}/>
                        </div>
                        <button className={'btn1 btn-second ' + (canAddExact?' btn-second-disabled':'') }
                            style={{height:'55px',marginLeft:'8px',fontSize:'0.9rem',padding:'0px 10px',borderWidth:'1px'}}
                         onClick={()=>{
                                this.setState({amount:this.props.orderInfo.remainingAmountDue-this.props.orderInfo.cashPayment.amount});
                        }}>Exact</button>
                    </div>

                    <p class='error-msg'>{this.state.errorMsg}</p>
                    <br/>

                    {this.isExactAmount()&&
                    <p style={{fontSize:'14px',backgroundColor:'rgb(243,241,255)',padding:'4px 10px',borderRadius:'6px',marginBottom:'22px'}}>
                        Payment is exact amount - Order will be complete</p>}
                   
                    <div style={{display:'flex',marginTop:'12px'}}>
                        <button className='btn1 btn-second w100' onClick={this.props.onCancel}>Cancel</button>
                        <div style={{width:'12px'}}></div>
                        <button className={'btn1 w100' + (canAccept?'':' btn-disabled')} onClick={this.addClicked}>
                            {this.isExactAmount()?'Complete':'Add'}</button>
                    </div>
                </div>

            </div>
        )
    }
}

class DiscountPercentageInput extends Component{
   
    constructor(props) {
        super(props);

        this.state={
            selectedPerc:this.props.discount.id===DISCOUNT_TYPE_ID.CUSTOM_PERC?this.props.discount.value:null,
            custom:''
        }
    }
    pesoWithCommas=(x)=>{
        let roundedX= Math.round((x + Number.EPSILON) * 100) / 100;
        return '₱'+roundedX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    render(){
        let DiscountPercBtn = (props)=>{
            let isSelected = this.state.selectedPerc===props.percent;
            return (
            <div className={'btn1 btn-second disc-perc-btn'+ (isSelected?' s-disc-perc-btn':'')} 
                onClick={()=>{
                    this.setState({selectedPerc:props.percent,custom:''})
                }}>
                <div className='centerItem'>{props.label?props.label:props.percent+'%'}</div>
            </div>)
        }
        let percentage = this.state.selectedPerc!=null?
                            this.state.selectedPerc:
                            this.state.custom!==''?Number(this.state.custom):null;
       
        let discountAmt = percentage!=null?
                            (percentage/100)*this.props.orderInfo.subTotalPrice:
                            0;
        return(
            <div className={'disc-perc-in '+(isMobile?'window-fullscreen':'window')}>
                <div className='gwrap' style={{textAlign:'center'}}>
                    <h3>Enter Discount Percentage</h3>
                    <br/>
                    <div className='perc-inputs-232sa'>

                        <div className={'btn1 btn-second disc-perc-btn'+ (this.state.custom!==''?' s-disc-perc-btn':'')}
                            style={{minWidth:'120px'}}>
                            <TextField
                                required
                                id="custom-cash-input"
                                type="telephone"
                                variant="standard"
                                placeholder={'Custom'}
                                value={this.state.custom}
                                onChange={(event)=>{
                                    if(Number(event.target.value)<=100){
                                    this.setState({custom:event.target.value, selectedPerc:null});
                                    }
                                }}
                                sx={{marginTop:'14px', input: { color: 'rgb(66,43,163)',fontSize:'1.1rem', fontWeight:'600',textAlign:'center' } }}
                            />
                        </div>

                        <DiscountPercBtn percent={5} />
                        <DiscountPercBtn percent={10} />
                        <DiscountPercBtn percent={15} />
                        <DiscountPercBtn percent={20} />
                        <DiscountPercBtn percent={25} />
                        <DiscountPercBtn percent={50} />
                        <DiscountPercBtn percent={75} />
                        <DiscountPercBtn percent={100} />
                        <DiscountPercBtn percent={0} label='None' /> 
                    </div>
                </div>
                <div className='tot-show' style={{paddingTop:'10px'}}>
                    <div className='tot-show-in' style={{borderTop:'solid 1px gainsboro',textAlign:'right',padding:'12px 2px 0px 2px'}}>

                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <div>{'Subtotal:'}</div>
                            <div>{this.pesoWithCommas(this.props.orderInfo.subTotalPrice)}</div>
                        </div>

                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <div>{'Discount:'}</div>
                            <div>{percentage!=null?
                                this.pesoWithCommas(discountAmt)+' ('+percentage + '%)'
                                :'-'}</div>
                        </div>

                        <div style={{display:'flex',justifyContent:'space-between',fontWeight:'800',fontSize:'1.15rem'}}>
                            <div>{'Total:'}</div>
                            <div>{this.pesoWithCommas(this.props.orderInfo.subTotalPrice-discountAmt)}</div>
                        </div>

                        <br/>
                        <div className='action-btns-enzoewi' style={{display:'flex'}}>
                            <button className='btn1 btn-second w100' style={{marginRight:'3px'}} tabIndex={-1}
                                onClick={this.props.onCancel}>
                                Cancel
                            </button>
                            <button className='btn1 w100' style={{marginLeft:'3px'}} tabIndex={-1}
                                onClick={()=>this.props.setDiscountPercentage(percentage)}>
                                Okay
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckoutPage;