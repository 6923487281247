import React, { Component } from 'react';
import moment from "moment";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Modal from 'react-modal'; 
import {isMobileOnly} from 'react-device-detect';
const MODAL_STYLE = {
    content : {
        transform:'translate(-100%, 0%)', top:'140px', left:'calc(100% - 55px)', right:'auto', bottom:'auto',backgroundColor:'transparent'
       ,boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px', padding:'0px', border:'none',borderRadius:'10px'
    },
     overlay: { 
        transition:'0.4s',zIndex:'100' ,backgroundColor:'transparent'}
}


class RangeSelectControlPanel extends Component {

    async componentDidMount(){}

    setRange=(label, dateRangeLabel, startDate, endDate)=>{
        this.props.setRange(label, dateRangeLabel, startDate, endDate);
        this.props.closePanel();
    }

    setRangeToday=()=>{
        let todayBegin = new Date().setHours(0,0,0,0);
        let todayEnd = new Date(todayBegin);
        todayEnd.setDate(todayEnd.getDate() + 1);
        this.setRange("Today", moment(todayBegin).format('MMM D, YYYY'), todayBegin.valueOf(),todayEnd.valueOf()-1);
    }

    setRangeThisWeek=()=>{

        let todayEnd = new Date(new Date().setHours(0,0,0,0));
        todayEnd.setDate(todayEnd.getDate() + 1);
        let today = new Date();
        let dayOfWeekToday=(today).getDay();
        dayOfWeekToday-=1; // begin on monday instead of sunday;

        let weekStart = new Date(today);
        weekStart.setHours(0,0,0,0);
        weekStart.setDate(today.getDate() - dayOfWeekToday); 

        let dateRangeLabel = 
            moment(weekStart).format('MMM D') + ' - ' + 
            moment(todayEnd.valueOf()-1).format('MMM D, YYYY');
        this.setRange("This week", dateRangeLabel, weekStart.valueOf(),todayEnd.valueOf()-1);
    }

    setRangeLastWeek=()=>{
        let mondayThisWeek = moment().subtract(((new Date()).getDay()-1),'days').toDate().setHours(0,0,0,0);
        let sundayLastWeek = moment(mondayThisWeek).subtract(7,'days');

        let dateRangeLabel = 
            moment(sundayLastWeek).format('MMM D') + ' - ' + 
            moment(mondayThisWeek-1).format('MMM D, YYYY');
        this.setRange("Last week",dateRangeLabel, sundayLastWeek,mondayThisWeek-1);
    }
    
    setRangeLastDays=(backDays)=>{
        let atrasDays = moment().subtract(backDays,'days').toDate().setHours(0,0,0,0);
        let dateRangeLabel = 
            moment(atrasDays).format('MMM D') + ' - ' + 
            moment(new Date().valueOf()).format('MMM D, YYYY');

        this.setRange("Last " + backDays + " days", dateRangeLabel, atrasDays, new Date().valueOf());
    }
    

    render() {
       
        return( 
            <div classNmae='rangedate-panel'>
                <div classNmae='rangedate-body' style={{display:'flex',width:'100%'}}>
                    <div className='range-selection'>
                        <button className='btn2 range-slctn' onClick={this.setRangeToday}>Today</button>
                        <button className='btn2 range-slctn' onClick={this.setRangeThisWeek}>This week</button>
                        <button className='btn2 range-slctn' onClick={this.setRangeLastWeek}>Last week</button>
                        <button className='btn2 range-slctn' onClick={()=>this.setRangeLastDays(7)}>Last 7 days</button>
                        <button className='btn2 range-slctn' onClick={()=>this.setRangeLastDays(15)}>Last 15 days</button>
                    </div>
                    <div className='calendar-date-selection'>

                    </div>
                </div>

            </div>
        );
    }
}//
class RangeDateSelector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            panelVisible:false

        }
    }
    
    render() {
        return (
            <div class='range-date-slctr ibtn' style={{display:'flex',height:'fit-content',borderRadius:'6px'}}>
                <div class='range-desc' style={{display:'flex',cursor:'pointer',padding:'8px 8px'}}  onClick={()=>{this.setState({panelVisible:true});}} >
                    {this.props.currentRange!=null?
                    <div style={{display:'flex'}}>
                        <div  style={{marginRight:'8px',backgroundColor:'rgba(0,0,0,0.05)',borderRadius:'4px',padding:'2px 10px',display:'flex',justifyContent:'center',alignItems:'center'}}> 
                            <p style={{fontSize:isMobileOnly?'10px':'11px'}}>{this.props.currentRange.label}</p>
                        </div>
                       <h3 style={{fontSize:isMobileOnly?'12px':'14px',fontWeight:'600'}}>{this.props.currentRange.dateRangeLabel}</h3>
                        <ArrowDropDownIcon sx={{padding:'0px',width:'20px',height:isMobileOnly?'12px':'18px',color:'rgb(120,120,120)'}}/>
                    </div>:
                    <div></div>}
                   
                </div>

                <Modal id="rangedate-selector-panel-modal" 
                    shouldCloseOnOverlayClick={true} 
                    isOpen={this.state.panelVisible}
                    shouldCloseOnEsc={true}
                    style={MODAL_STYLE}
                    onRequestClose={()=>{this.setState({panelVisible:false});}}
                    >
                    <RangeSelectControlPanel 
                        user={this.props.exchange.storedSession.user} 
                        setRange={this.props.setDateRange}
                        closePanel={()=>{this.setState({panelVisible:false});}}
                        />
                </Modal>

            </div>
        );
    }
}

export default RangeDateSelector;