import React, { Component } from 'react';
import TextField from '@mui/material/TextField';

class InfoDiv extends Component{
    render() {
        return(
            <div class='info-div'>
                <p class='c-in2' style={this.props.error?{color:'red',marginTop:'22px'}:{marginTop:'22px'}}>{this.props.title}</p>
                <TextField
                    id={this.props.id}
                    value={this.props.value}
                    sx={{width:'100%'}}
                    variant="outlined" 
                    multiline
                    rows={this.props.rows!=null?this.props.rows:1}
                    onChange={this.props.onChange}
                    error={this.props.error}
                    /> 
            </div>
        );
    }
}

export default InfoDiv;