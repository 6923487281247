import React, { Component } from 'react';
import './Sidebar.css';
import {SECTION,BOTTOM_BAR_HEIGHT} from '../constants';
import { Link } from 'react-router-dom';
import AuthService from '../service/AuthService';
import InboxIcon from '@mui/icons-material/Inbox';
// import LogoutIcon from '@mui/icons-material/Logout';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

class SectionButton extends Component {
 
    render(){
        return (
            <Link class='section-btn' to={this.props.section.path} onClick={this.props.onClick}  
                style={{padding:'7px 10px',width:'100%', height:'100%', textAlign:'center',display:'inline-block'}} >
                <div className='centeritem' style={{display:'flex',justifyContent:'center'}}>
                   {this.props.icon}
                </div>
                <p style={{color:'white',fontSize:'10px'}}>{this.props.title?this.props.title:this.props.section.label}</p>
            </Link>
        )
    }
}
class BottomBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            section: SECTION.REGISTER
        }

        switch(window.location.pathname){
            case SECTION.REGISTER.path:     this.state.section=SECTION.REGISTER;break;
            case SECTION.TRANSACTIONS.path: this.state.section=SECTION.TRANSACTIONS;break;
            case SECTION.CASH_DRAWER.path:  this.state.section=SECTION.CASH_DRAWER;break;
            case SECTION.ITEMS.path:        this.state.section=SECTION.ITEMS;break;
            case SECTION.SETTINGS.path:     this.state.section=SECTION.SETTINGS;break;
            default:break;
        }
    }

    logoutClicked=()=>{
        AuthService.logout();
    }
    
    render() {

        let sectionProp = {
           currentSection:this.state.section
       }
        return (
            <div class='bottom-bar' style={{height:BOTTOM_BAR_HEIGHT}}>
                
                <div class='bar-flex' style={{height:'100%'}}>

                <SectionButton section={SECTION.REGISTER} sectionProp={sectionProp} onClick={()=>{this.setState({section:SECTION.REGISTER})}}
                        icon=<PointOfSaleIcon sx={{color:this.state.section===SECTION.REGISTER?'rgb(218, 207, 254)':'white'}} fontSize="small" /> />
                
                <SectionButton section={SECTION.CASH_DRAWER} title={'Drawer'} sectionProp={sectionProp} onClick={()=>{this.setState({section:SECTION.CASH_DRAWER})}}
                        icon=<InboxIcon sx={{color:this.state.section===SECTION.CASH_DRAWER?'rgb(218, 207, 254)':'white'}} fontSize="small" /> />
                
                <SectionButton section={SECTION.TRANSACTIONS}  sectionProp={sectionProp} onClick={()=>{this.setState({section:SECTION.TRANSACTIONS})}}
                        icon=<ReceiptIcon sx={{color:this.state.section===SECTION.TRANSACTIONS?'rgb(218, 207, 254)':'white'}} fontSize="small" /> />
              
                <SectionButton section={SECTION.ITEMS}  sectionProp={sectionProp} onClick={()=>{this.setState({section:SECTION.ITEMS})}}
                        icon=<FormatListBulletedIcon sx={{color:this.state.section===SECTION.ITEMS?'rgb(218, 207, 254)':'white'}} fontSize="small" /> />

                <SectionButton section={SECTION.SETTINGS}  sectionProp={sectionProp} onClick={()=>{this.setState({section:SECTION.SETTINGS})}}
                        icon=<SettingsIcon sx={{color:this.state.section===SECTION.SETTINGS?'rgb(218, 207, 254)':'white'}} fontSize="small" /> />
                
                   {/* <SectionButton section={{label:'Log-out',path:null}} onClick={this.logoutClicked} sectionProp={sectionProp} 
                        icon=<LogoutIcon  sx={{color:'white'}} fontSize="small" /> /> */}
              
                </div>
                
            </div>
        );
    }
}

export default BottomBar;