import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PageNotFound extends Component {
    render() {
        return (
            <div class='page-not-found' style={{height:'100%'}}>
                <div style={{textAlign:'center',justifyContent:'center', position:'relative',height:'100%'}}>
                    <div style={{position:'absolute',top:'40%',left:'50%',transform: 'translate(-50%,-50%)'}}>
                        <h1 class='c-h1'>Page not found</h1>
                        <p class='c-p1'>Sorry, the page you're looking for doesn't exist</p>
                        <br/>
                        <Link class='btn1' to={'/'}>Go to Home Page</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default PageNotFound;