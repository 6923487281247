import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import UserPanel from '../../components/UserPanel';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

class PosHeader extends Component {
    render() { 
        return (
            <div class='pos-header' ref={this.props.elementRef} style={this.props.title?{}:{ backgroundColor:'transparent',boxShadow:'none'}}>
                 <div className={isMobile?'page-header-1-wrpr-m':'page-header-1-wrpr'} style={this.props.title?{}:{borderBottom:'none'}}>
                    <div style={{display:'flex'}}>
                        {this.props.backLink&&
                        <Link className='ibtn' to={this.props.backLink.path} ref={this.props.backLink.ref} tabIndex={-1}
                        style={{marginLeft:'8px',padding:'4px 10px'}}><ArrowBackIosNewIcon sx={{fontSize:'14px', color:'black'}}/></Link>}
                        <h1 class={(isMobile?'hdr-title-m':'hdr-title')} style={{height:isMobile?'22px':'28px'}}>{this.props.title}</h1>
                    </div>
                    {this.props.content!=null?
                        this.props.content
                        :<div style={isMobile?{height:'10px'}:{height:'12px'}}></div>}
                </div>
                <UserPanel exchange={this.props.exchange}/>
            </div>
        );
    }
}

export default PosHeader;