import React, { Component } from 'react';
import moment from "moment"
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SaleComplete from './pages/pos/SaleComplete';
import {CheckoutPageWrapper} from './pages/pos/checkout/CheckoutPageWrapper';
import CashDrawer from './pages/pos/cashdrawer/CashDrawer';
import SettingsPage from './pages/settings/SettingsPage';
import InventoryPage from './pages/pos/inventory/InventoryPage';
import TransactionsPage from './pages/pos/transactions/TransactionsPage';
import {RegisterPageWrapper} from './pages/pos/register/RegisterPageWrapper';
import {ItemsPageComponentWrapper} from './pages/pos/items/ItemsPageComponentWrapper';
import PosHome from './pages/pos/PosHome';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import AuthService from './service/AuthService';
import CacheService from './service/CacheService';
import { SUBSCRIPTION_STATUS } from './service/SubscriptionService'

class AuthRouter extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showNotif:true
        }
    }

    render() {

        let PrivateRoute = ({ children }: { children: JSX.Element })=> {
            const authenticated = AuthService.canAccessPos(this.props.exchange.firebaseUser,this.props.exchange.storedSession);
            let location = useLocation();
            if (!authenticated) {
              return <Navigate to="/login" state={{ from: location }} />;
            }
            return children;
        }
          
        let exchange = this.props.exchange;
        return (
            <div className='auth-router-wrapper' >
            {/* style={{height:'100%'}}> */}
                {this.state.showNotif&&
                    <SubscriptionNotice subscription={exchange.subscription} close={()=>{this.setState({showNotif:false})}}/>}

            <Routes>
                {exchange.onPos&&
                <Route path='/' element={<PrivateRoute><PosHome exchange={exchange}/></PrivateRoute>}/>}
                <Route path='/register/:par1?/:par2?' element={<PrivateRoute><RegisterPageWrapper exchange={exchange}/></PrivateRoute>} /> 
                <Route path='/drawer' element={<PrivateRoute><CashDrawer exchange={exchange}/></PrivateRoute>} />
                <Route path='/transactions' element={<PrivateRoute><TransactionsPage exchange={exchange}/></PrivateRoute>} /> 
                <Route path='/items/:viewType?/:itemId?/:itemAction?' element={<PrivateRoute><ItemsPageComponentWrapper exchange={exchange} /></PrivateRoute>} />
                <Route path='/inventory'    element={<PrivateRoute><InventoryPage exchange={exchange}/></PrivateRoute>} />
                <Route path='/checkout/:par1?' element={<PrivateRoute><CheckoutPageWrapper exchange={exchange}/></PrivateRoute>} />
                <Route path='/sale-complete/:par?' element={<PrivateRoute><SaleComplete {...this.props}/></PrivateRoute>} /> 
                <Route path='/settings/:par?' element={<PrivateRoute><SettingsPage {...this.props}/></PrivateRoute>} /> 
             </Routes>
            </div>
        );
    }
}

class SubscriptionNotice extends Component {
    render(){
        if(this.props.subscription==null || this.props.subscription.notice==null || this.props.subscription.notice.show===false){
            return null
        }
        let message = null
        let submessage = null
        let actionBtn = null
        let backgroundColor = 'rgb(247,47,119)'
        switch(this.props.subscription.status){
            case SUBSCRIPTION_STATUS.ACTIVE: break;
            case SUBSCRIPTION_STATUS.EXPIRING_SOON:
                backgroundColor = 'rgb(5,176,89)'
                message='Your subscription expires soon'
                submessage='Current subscription will expire on ' + moment(this.props.subscription.period.end).format('LL')
                actionBtn = (
                    <Link className='btn1 btn-white' to='/settings'
                        onClick={()=>{
                            CacheService.setPageCacheItem('SETTINGS','_LAST_SETTINGS_VIEWED','SUBSCRIPTION')
                            this.props.close()
                        }}
                        style={{height:'36px',color:backgroundColor,fontSize:'14px',padding:'4px 10px'}}>
                        Renew Subscription
                    </Link>
                )
                break;
            case SUBSCRIPTION_STATUS.EXPIRED:
                message='Your subscription has expired'
                break;
            default:break;
        }
        return (
            <div className='subscription-notice' style={{backgroundColor:backgroundColor,width:'100%',padding:'4px 12px',display:'flex'}}>
                    <div style={{paddingRight:'20px',minWidth:'fit-content'}}>
                        <h3 style={{color:'white'}}>{message}</h3>
                        {submessage&&<p style={{color:'white',fontSize:'13px'}}>{submessage}</p>}
                    </div>
                    <div style={{display:'flex',alignItems:'center',minWidth:'fit-content'}}>
                        {actionBtn}
                    </div>
                    <div style={{display:'flex',width:'100%',justifyContent:'flex-end'}}>
                        <div className='ibtn' onClick={this.props.close} style={{backgroundColor:'transparent',padding:'10px'}}>
                            <CloseIcon/>
                        </div>
                    </div>
                    {/* <p style={{color:'white'}}>{'Notice Start: ' + this.props.subscription.notice.start}</p> */}
            </div>
        )
    }
}

export default AuthRouter;