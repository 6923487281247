import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Util from '../../../util/Util';
import { Tooltip } from 'react-tooltip';
import { Link } from 'react-router-dom';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import VariantCreate from './VariantCreate';
import ItemImage from '../../pos/ItemImage';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ItemImageSelect from './ItemImageSelect';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import {isMobileOnly,isMobile} from 'react-device-detect';
import LinearProgress from '@mui/material/LinearProgress';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import NotificationUtil from '../../../util/NotificationUtil';
import FormControlLabel from '@mui/material/FormControlLabel';
import FirebaseService from '../../../service/FirebaseService';
import CloseableModal from '../../../components/CloseableModal';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {ITEM_NAME_LIMIT, VARIANT_LIMIT, ALLOW_DUPLICATE_VARIANT_NAME} from '../../../constants';
const UNIQUE_ON_ALL_ITEMS=false;

class AddEditItem extends Component {
    
    constructor(props) {
        super(props);
        this.firstInputRef = React.createRef();
        this.closeButtonRef = React.createRef();
        let categoryOptions=[]
        if(this.props.exchange.storedSession.business.menu!=null && this.props.exchange.storedSession.business.menu.category!=null){
            Object.keys(this.props.exchange.storedSession.business.menu.category).forEach(catId=>{
                let categoryName = this.props.exchange.storedSession.business.menu.category[catId]
                categoryOptions.push(<MenuItem value={catId}>{categoryName}</MenuItem>)
            })
        }
        this.state = {
            item:{
                 name: ''
                ,price:''
                ,cost:''
                ,categoryId:''
                ,variants:[]
                ,barcode:''
                ,sku:''
                ,image:null
                ,trackStock:false
                ,hasVariant:false
                ,sameCostPrice:true
                ,sameBarcode:true
            }

            ,categoryOptions:categoryOptions
            ,tempVariant:null
            ,message:{val:'',color:''} 

            ,showMore:false
            ,showImageSelect:false
        }

        if(this.props.targetCategoryId!=null){
            this.state.item.categoryId = this.props.targetCategoryId;
        }

        if(this.props.itemEdit!=null){
            this.state.item=JSON.parse(JSON.stringify(this.props.itemEdit));
        }
        
    }

    async componentDidMount(){
        if(this.props.itemEdit==null){
            this.firstInputRef.current.focus();
        }
    }

    checkIfVariantHasDuplicate=()=>{
        let variantNames = Object.values(this.state.item.variants).map(v => v.name.toLowerCase());
        return (new Set(variantNames)).size !== variantNames.length;
    }

    onSaveItemClick=()=>{
        let onEdit = this.props.itemEdit!=null;
        let items = this.props.business.menu.items!=null?
                        this.props.business.menu.items:[];
       
        if(items.length>0){
            items = UNIQUE_ON_ALL_ITEMS?
                          this.props.business.menu.items // kapag gusto mong gawing unique sa lahat ng items
                        : this.props.business.menu.items.filter(ii=>ii.categoryId===this.state.item.categoryId);  // kapag gusto mo lang gawin yung check sa mga item sa same category
        }
        
        if(onEdit){
            items = items.filter(ii=>ii.id !== this.props.itemEdit.id); // prevent checking on same item na ineedit
        }
        let namesLowercase = Object.values(items).map(v => v.name.toLowerCase());
        let nameExist = namesLowercase.includes(this.state.item.name.trim().toLowerCase());
        let hasVariant = this.state.item.hasVariant && Object.values(this.state.item.variants).length>0;
        if(nameExist){
            this.setState({message:{val:this.state.item.name+' Already Exist',color:'red'}});
            NotificationUtil.makeToast('Name already exist!','rgb(55,66,71)','white',2500,'top-right'); 
        } else if(this.state.item.hasVariant && ALLOW_DUPLICATE_VARIANT_NAME===false && this.checkIfVariantHasDuplicate()) {
            this.setState({message:{val:'Duplicate variant name',color:'red'}});
        }
        else if(this.props.itemEdit!=null){
            this.setState({onLoading:true});
            FirebaseService.updateMenuItem(
                 this.props.business.id
                ,this.props.itemEdit.id
                ,this.state.item.name
                ,Number(this.state.item.cost)
                ,Number(this.state.item.price)
                ,this.state.item.categoryId
                ,this.state.item.variants
                ,this.state.item.barcode
                ,this.state.item.sku
                ,this.state.item.image
                ,this.state.item.trackStock
                ,hasVariant
                ,this.state.item.sameCostPrice
                ,this.state.item.sameBarcode
                ,()=>{
                    this.closeButtonRef.current.click()
                    this.props.exchange.refreshCache()
                    NotificationUtil.makeToast('Item update successful!','rgb(55,66,71)','white',3000,'top-right')
                }
                ,()=>{ console.log('AddItem.jsx failed to update item ')}
            );
        }else {
            this.setState({onLoading:true});
            let businessId = this.props.business.id;
            FirebaseService.addMenuItem(
                 businessId
                ,this.state.item.name
                ,Number(this.state.item.cost)
                ,Number(this.state.item.price)
                ,this.state.item.categoryId // categoryId
                ,this.state.item.variants
                ,this.state.item.barcode
                ,this.state.item.sku
                ,this.state.item.image!=null?this.state.item.image:{type:'icon',val:{group:'Others',id:'BARCODE'}}
                ,this.state.item.trackStock
                ,hasVariant
                ,this.state.item.sameCostPrice
                ,this.state.item.sameBarcode
    
                ,()=>{
                    NotificationUtil.makeToast('New item added!','rgb(92,72,176)','white',3000,'top-right','700');
                    this.closeButtonRef.current.click()
                    this.props.exchange.refreshCache();
                }
                ,()=>{ console.log('failed to save callback')}
            );
        }
    }

    numberInput=(event)=>{
        let newVal = '';
        if(event.target.value!=='') {
            newVal = Number(event.target.value);
        }
        return newVal;
    }

    variantChangeInputHandler=(event,variant)=>{
 
        let item = this.state.item;
        switch(event.target.id){
            case 'v-name-input':
                if(event.target.value.length>ITEM_NAME_LIMIT) return null;
                item.variants[variant.id].name= event.target.value;
            break;
            case 'v-cost-input':
                if(Number(event.target.value)>10000000) return null;
                item.variants[variant.id].cost = this.numberInput(event);
            break;
            case 'v-price-input':
                if(Number(event.target.value)>10000000) return null;
                item.variants[variant.id].price = this.numberInput(event);
            break;
            case 'v-barcode-input':
                if(event.target.value.length>100) return null;
                item.variants[variant.id].barcode= event.target.value;
            break;
            default:break;
        }
        this.setState({item:item,message:{val:'',color:''}});
    }

    variantCreateChangeInputHandler=(event)=>{

        let tempVariant=this.state.tempVariant;
        switch(event.target.id){
            case 'variant-name-input':
                if(event.target.value.length>ITEM_NAME_LIMIT) return null;
                tempVariant.name = event.target.value;
            break;
            case 'variant-cost-input':
                if(this.state.item.sameCostPrice===false){
                    tempVariant.cost = this.numberInput(event);
                }
            break;
            case 'variant-price-input':
                if(this.state.item.sameCostPrice===false){
                    tempVariant.price = this.numberInput(event);
                }
            break;
            case 'variant-barcode-input':
                if(this.state.item.sameBarcode===false){
                    if(event.target.value.length>100) return null;
                    tempVariant.barcode = event.target.value; 
                }
            break;
            default:break;
        }

        this.setState({tempVariant:tempVariant});
    }

    changeInputHandler=(event)=>{

        let item = this.state.item
        let variants = [];
        if(item.variants!=null){
            variants=Object.values(item.variants);
        }
        switch(event.target.id){
            case 'name-input':
                if(event.target.value.length>ITEM_NAME_LIMIT) return null;
                item.name = event.target.value;
            break;
            case 'cost-input':
                item.cost = this.numberInput(event);
                if(this.state.item.sameCostPrice)
                    variants.forEach(v=>item.variants[v.id].cost = item.cost)
            break;
            case 'price-input':
                item.price = this.numberInput(event);
                if(this.state.item.sameCostPrice)
                    variants.forEach(v=>item.variants[v.id].price = item.price)
            break;
            case 'barcode-input': 
                if(event.target.value.length>100) return null;
                item.barcode = event.target.value;
                if(this.state.item.sameBarcode)
                    variants.forEach(v=>item.variants[v.id].barcode = item.barcode)
            break;
            case 'sku-input':
                if(event.target.value.length>100) return null;
                item.sku = event.target.value;
            break;
        default:  break;
        }

        this.setState({item:item,message:{val:'',color:''}});
    }

    openNewVariant=()=>{
        let reachedVariantLimit = Object.values(this.state.item.variants).length>=VARIANT_LIMIT;
        if(reachedVariantLimit) {return null;}

       let tempVariant = {};
       tempVariant.name='';
       if(this.state.item.sameBarcode && this.state.item.barcode!=null){
            tempVariant.barcode=this.state.item.barcode;
       }
       if(this.state.item.sameCostPrice && this.state.item.cost!=null){
            tempVariant.cost=this.state.item.cost;
       }
       if(this.state.item.sameCostPrice && this.state.item.price!=null){
            tempVariant.price=this.state.item.price;
       }
       this.setState({tempVariant:tempVariant});
    }

    checkVariantNameExist=(name,ignoreItemId)=>{
        let variantsCopy = this.state.variants;
        if(ignoreItemId!=null){
            let ignoreItem=(ii) =>{
                return ii.id !== ignoreItemId;
            }
            variantsCopy = variantsCopy.filter(ignoreItem);
        }
        let variantsNameLC = Object.values(variantsCopy).map(v => v.name.toLowerCase()); 
       
        let variantNameExist = variantsNameLC.includes(name.toLowerCase());
        return variantNameExist;
    }

    saveNewVariant=()=>{
       
        let newVariant = this.state.tempVariant;
        newVariant.id = Util.uuidv4();
        newVariant.cost = Number(newVariant.cost);
        newVariant.price = Number(newVariant.price);
        let item = this.state.item;
        item.variants[newVariant.id] = newVariant
        this.setState({item:item,tempVariant:null});
        
    }

    renderVariants=()=>{
        let variantsArr = Object.values(this.state.item.variants);
        if(variantsArr===0) return null;
        let variantsDiv=[]; 
        variantsArr.forEach((variant,idx)=>{
            variantsDiv.push(
                <div>
                    <div class='variant-div' style={{display:'flex'}}>
                        <TextField id={"v-name-input"} required variant="standard" placeholder={"e.g. Small"} sx={{ marginRight:'8px',backgroundColor:'transparent'}}
                            value={variant.name} onChange={(e)=>this.variantChangeInputHandler(e,variant)}/>
                        <TextField id={"v-cost-input"} required variant="standard"  type="number" 
                            InputProps={{readOnly: this.state.item.sameCostPrice }} sx={{marginRight:'8px',backgroundColor:'transparent'}}
                            value={variant.cost} onChange={(e)=>this.variantChangeInputHandler(e,variant)}/>
                        <TextField id={"v-price-input"} required variant="standard"  type="number"  sx={{marginRight:'8px',backgroundColor:'transparent'}}
                            value={variant.price} InputProps={{readOnly: this.state.item.sameCostPrice }}
                             onChange={(e)=>this.variantChangeInputHandler(e,variant)}/>
                        <TextField id={"v-barcode-input"} required variant="standard" sx={{backgroundColor:'transparent'}}
                        InputProps={{readOnly: this.state.item.sameBarcode }}
                            value={variant.barcode} onChange={(e)=>this.variantChangeInputHandler(e,variant)}/>
                    </div> 
                </div>);
        });
        return (
            <div className='e-variants' style={{padding:'10px'}}>
                 <div className='variant-div-hdr' style={{display:'flex',justifyContent:'space-evenly',textAlign:'left',marginTop:'4px'}}>
                    <p className='c-in2' style={{width:'100%'}}>Name</p>
                    <p className='c-in2' style={{width:'100%'}}>Cost</p>
                    <p className='c-in2' style={{width:'100%'}}>Price</p>
                    <p className='c-in2' style={{width:'100%'}} >Barcode</p>
                </div>
                <div className='e-variants-div' style={{marginBottom:'20px',marginTop:'12px',maxHeight:'200px',overflowY:'scroll'}}>
                    {variantsDiv}
                </div>
            </div>
        )
    }

    addImageClicked=()=>{
        this.setState({showImageSelect:true});
    }

    onCancelImageSelect=()=>{
        this.setState({showImageSelect:false});

    }

    toggleTrackStock=(event)=>{
        let item = this.state.item;
        item.trackStock = !item.trackStock;
        this.setState({item:item});
    }
    renderMoreeOptions=()=>{
        let show = this.state.showMore;
        return(
            <div className='more-options-div' style={
                isMobileOnly?
                {transition:'0.3s', width:'100%'}:
                {transition:'0.3s', width:'170px',
                 opacity:show?'1':'0', 
                 maxWidth:show?'240px':'0px',paddingLeft:show?'8px':''}}>
                 
            </div>
        )
    }

    imageSelectedCallback=(imgObj)=>{
        let item = this.state.item;
        item.image = imgObj;
        this.setState({showImageSelect:false, item:item});
    }

    toggleHasVariant=(e)=>{
        let item = this.state.item;
        if(e.target.type==='checkbox'){
            item.hasVariant = e.target.checked;
        }else{
            item.hasVariant= !item.hasVariant;
        }
        if(item.hasVariant){
            item.variants={};
            item.sameCostPrice=true;
            item.sameBarcode=true;
        }
        this.setState({item:item}, 
            item.hasVariant? // open new variant
                this.openNewVariant:null);
    }

    toggleSameCostPrice=(e)=>{
        let item = this.state.item;
        if(e.target.type==='checkbox'){
            item.sameCostPrice = e.target.checked;
        }else{
            item.sameCostPrice= !item.sameCostPrice;
        }
        this.setState({item:item});
    }

    toggleSameBarcode=(e)=>{
        let item = this.state.item;
        if(e.target.type==='checkbox'){
            item.sameBarcode = e.target.checked;
        }else{
            item.sameBarcode= !item.sameBarcode;
        }
        this.setState({item:item});
    }

    validateVariants=()=>{
        if(this.state.item.sameCostPrice){
            return this.state.item.cost!=='' && this.state.item.price!=='';
        }else{
            let valid = true
            Object.values(this.state.item.variants).forEach(v=>{
                if(v.cost==='' || v.price===''){
                    valid = false;
                }
            }) 
           
            return valid
        }
    }
 
    render() {
        let onEdit = this.props.itemEdit!=null;
        let canSave = onEdit?
                        JSON.stringify(this.state.item) !== JSON.stringify(this.props.itemEdit)
                      : this.state.item.name!==''
                      && (this.state.item.hasVariant ? this.validateVariants() : (this.state.item.cost!=='' && this.state.item.price!==''))
                      && this.state.item.categoryId!=='';

        let canChangeCategory = this.props.targetCategoryId==null;
        let headerTxt=
             this.props.itemEdit==null?
             "Add item"
            :(onEdit?
                 this.props.itemEdit.name:
                 "Add New "+this.state.item.categoryId)
        let currentCategory = '';
        if(this.props.exchange.storedSession.business.menu!=null && this.props.exchange.storedSession.business.menu.category!=null){
            currentCategory = this.props.exchange.storedSession.business.menu.category[this.state.item.categoryId]
        }
        return (
            <div className={'add-item-component '  + (!isMobile?'window':'window-fullscreen')}>
                <Tooltip id="item-tooltip" place="bottom"/>
                <div className='additem-hdr' style={{display:'flex',justifyContent:'space-between'}}>
                    <div>
                        <h1 class='c-p2' style={{margin:'12px 0px 0px 0px',color:'rgb(100,100,100)'}}>
                        {currentCategory}
                        </h1>
                        <h2 class='c-h3'>
                            {headerTxt}
                        </h2>
                    </div>

                    <div className='item-img-view' style={{display:'flex',alignItems:'center'}}>
                        <div className='img-sel-d' style={{margin:'10px 10px 0px 0px'}}  onClick={this.addImageClicked}>
                            {this.state.item.image==null?
                            <p style={{fontSize:'0.8rem',fontWeight:'500',color:'rgb(150,150,150)'}}>Select image</p>
                            :<ItemImage image={this.state.item.image} size={'small'}/>}
                        </div>
                    </div>
                </div>
               
                <LinearProgress sx={{marginBottom:'22px',opacity:this.state.onLoading?'1':'0'}}/>
                <div class='content' style={this.state.onLoading?{opacity:'0.5',pointerEvents:'none'}:{}}>
                    
                    <div className='inputs-div' style={{marginTop:'6px', display:isMobileOnly?'':'flex'}}>
                        <div className='left-side' style={{width:'100%'}}>
                            <p class='c-in2'>Item name</p>
                            <TextField id="name-input" 
                                inputRef={this.firstInputRef} required sx={{width:'100%'}}
                                value={this.state.item.name} onChange={this.changeInputHandler}/>
                            <br/>

                            <div style={{display:'flex',marginTop:'8px',backgroundColor:this.state.item.hasVariant&&!this.state.item.sameCostPrice?'rgba(0,0,0,0.07)':''}}>
                                <div style={{width:'100%',marginRight:'5px'}}>
                                    <p class='c-in2'>Cost</p>
                                    <TextField id="cost-input" className='w100' required  type="number"  sx={this.state.item.sameCostPrice||this.state.item.hasVariant===false?{}:{opacity:'0.6',pointerEvents:'none',textDecoration: 'line-through'}}
                                        inputProps={this.state.item.sameCostPrice||this.state.item.hasVariant===false?{inputMode:'numeric'}:{readOnly:true,tabIndex:-1}}
                                        value={this.state.item.cost} onChange={this.changeInputHandler}/>
                                </div>
                                <div style={{width:'100%',marginLeft:'5px'}}>
                                    <p class='c-in2'>Price</p>
                                    <TextField id="price-input" className='w100' required  type="number"  sx={this.state.item.sameCostPrice||this.state.item.hasVariant===false?{}:{opacity:'0.6',pointerEvents:'none',textDecoration: 'line-through'}}
                                        inputProps={this.state.item.sameCostPrice||this.state.item.hasVariant===false?{inputMode:'numeric'}:{readOnly:true,tabIndex:-1}}
                                        value={this.state.item.price} onChange={this.changeInputHandler}/>
                                </div>
                            </div>
                            
                            <div style={{width:'100%',marginTop:'8px',display:'flex'}}>
                                <div style={{width:'100%',marginRight:'5px'}}>
                                    <p class='c-in2'>Barcode</p>
                                    <TextField id="barcode-input" className='barcode-input w100' sx={this.state.item.sameBarcode||this.state.item.hasVariant===false?{}:{opacity:'0.6',pointerEvents:'none',textDecoration: 'line-through'}}
                                            inputProps={this.state.item.sameBarcode||this.state.item.hasVariant===false?{}:{readOnly:true,tabIndex:-1}}
                                            value={this.state.item.barcode} onChange={this.changeInputHandler}/>
                                </div>

                                <div style={{width:'100%',marginLeft:'5px'}}>
                                    <p class='c-in2'>SKU</p>
                                    <TextField id="sku-input" className='sku-input' 
                                            sx={{width:'100%'}}
                                            value={this.state.item.sku} onChange={this.changeInputHandler}/>
                                </div>
                            </div>

                            <div className='category-input' style={{width:'100%',marginTop:'8px',pointerEvents:canChangeCategory?'':'none',opacity:canChangeCategory?'':'0.5'}}>
                                <p class='c-in2'>Category</p>
                                <Box>
                                    <FormControl fullWidth>
                                        <Select 
                                            id="demo-simple-select"
                                            inputProps={canChangeCategory?{}:{ tabIndex:-1 }}
                                            value={this.state.item.categoryId}
                                            onChange={(event)=>{
                                                let item = this.state.item;
                                                item.categoryId = event.target.value
                                                this.setState({item:item})
                                            }}>
                                           
                                            {this.state.categoryOptions}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>

                            <br/>

                            <div style={{display:'flex',paddingLeft:'12px',paddingBottom:'12px',marginBottom:'10px',borderBottom:'solid 1px gainsboro'}}>

                                <FormGroup >
                                    <FormControlLabel label={<span style={{ fontSize: '0.92rem',fontWeight:'500', color:'rgb(96,96,96)',fontFamily:"Poppins', sans-serif"}}>{'Has Variant'}</span>} sx={{backgroundColor:'rgb(248,249,252)',borderRadius:'4px',padding:'0px 10px 0px 0px'}} control={
                                        <Switch color="secondary" 
                                            checked={this.state.item.hasVariant}
                                            onChange={this.toggleHasVariant}/>}
                                            />
                                </FormGroup>
                                <FormGroup >
                                    <FormControlLabel  label={<span style={{ fontSize: '0.92rem',fontWeight:'500',color:'rgb(96,96,96)',fontFamily:"Poppins', sans-serif"}}>{'Track Stock'}</span>} sx={{backgroundColor:'rgb(248,249,252)',fontSize:'0.5rem', marginLeft:'-6px',borderRadius:'4px',padding:'0px 10px 0px 0px'}}  control={
                                        <Switch color="secondary" 
                                            checked={this.state.item.trackStock}
                                            onChange={this.toggleTrackStock}/>}
                                            />
                                </FormGroup>

                            </div>
                            
                            {this.state.item.hasVariant&&
                            <div className='v-div' style={{backgroundColor:'rgb(250,250,249)', borderRadius:'10px',opacity:this.state.item.hasVariant?'':'0.4',pointerEvents:this.state.item.hasVariant?'':'none'}}>
                                
                                <p class='c-p1' style={{marginTop:'12px',paddingTop:'8px',paddingLeft:'12px'}}>Variants</p>
                                <div style={{display:'flex',width:'100%',fontSize:'0.85rem'}}>
                                    <div>
                                        <Checkbox  iconStyle={{fill: 'rgb(66,43,163)'}}  color="secondary" checked={this.state.item.sameCostPrice} onChange={this.toggleSameCostPrice} sx={{fontSize:'0.5rem'}}/>
                                        <span onClick={this.toggleSameCostPrice} style={{cursor:'pointer'}}>Variant has same cost/price</span>
                                    </div>
                                    <div>
                                        <Checkbox checked={this.state.item.sameBarcode}  color="secondary"  onChange={this.toggleSameBarcode}/>
                                        <span onClick={this.toggleSameBarcode} style={{cursor:'pointer'}}>Variant has same barcode</span>
                                    </div>
                                </div>

                                {this.state.item.variants&&this.renderVariants()}

                                <div style={{width:'100%',padding:'0px 20px'}}>
                                    <button className='btn1 btn-third b100' data-tooltip-id="item-tooltip" data-tooltip-content={"You can only add up to "+VARIANT_LIMIT + " variant"}
                                            onClick={this.openNewVariant}>
                                           <AddCircleOutlineIcon sx={{marginRight:'4px'}}/> {'Add Variant'}
                                    </button>
                                </div>

                                <br/>
                            </div>}
                             
                        </div>
                        
                        <div className='right-side' style={{}}>
                           {(this.state.showMore||isMobileOnly===false)&&this.renderMoreeOptions()}
                        </div>
                    </div>

                    <div className='other-div' style={{display:'flex',justifyContent:'right',paddingTop:'4px'}}>
                        <button className='btn1 btn-third' onClick={()=>this.setState({showMore:!this.state.showMore})}
                            style={{width:'140px',height:'35px',border:'none'}}>
                           {this.state.showMore?<ArrowRightIcon/>:<ArrowLeftIcon/>}
                           <p style={{fontSize:'0.9rem',minWidth:'fit-content'}}>{this.state.showMore?'Show less':'More options'}</p>
                        </button>
                    </div>

                    <br/>
                    <p style={{textAlign:'center',color:this.state.message.color}}>{this.state.message.val}</p>
                    <br/> 

                    <div style={{display:'flex'}}>
                        <Link class='btn1 btn-second link-2' to={this.props.onCloseLink} 
                            ref={this.closeButtonRef}
                            style={{ color:'rgb(66,43,163)', minWidth:'48%',marginRight:'4px'}}>
                            Cancel
                        </Link>

                        <button className={'btn1 b100 ' + (canSave?'':' -btn1-disabled')} tabIndex={-1}
                            style={{marginLeft:'2px'}}
                            onClick={canSave?this.onSaveItemClick:null}>
                            <p>{onEdit?'Save Changes':'Save item'}</p> 
                        </button>
                    </div>
                </div>
                {this.state.showImageSelect&& 
                 <CloseableModal 
                    content={
                        <ItemImageSelect
                            setImage={this.imageSelectedCallback}
                            selectedImage={this.state.image}
                        />
                        }
                        onCancel={this.onCancelImageSelect}
                    />}

                  {this.state.tempVariant!=null&&
                    <CloseableModal 
                         content={
                            <VariantCreate
                                tempVariant={this.state.tempVariant}
                                changeInputHandler={this.variantCreateChangeInputHandler}
                                variants={this.state.item.variants}
                                saveNewVariant={this.saveNewVariant}
                                sameCostPriceAsParent={this.state.item.sameCostPrice}
                                sameBarcodeAsParent={this.state.item.sameBarcode}
                                parentBarcode={this.state.item.barcode}
                                parentPrice={this.state.item.price}
                                parentCost={this.state.item.cost}
                                onCancel={()=>this.setState({tempVariant:null})}
                            />
                        }
                        onCancel={()=>this.setState({tempVariant:null})}
                    />}
            </div>
        );
    }
}


export default AddEditItem;