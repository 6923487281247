import React, { Component } from 'react';
import './HomePage.css';
import FAST_TOME from '../../assets/homepage/fast-time.png';
import ROCKET from '../../assets/homepage/rocket.png';
import GRAPH from '../../assets/homepage/graph.png';
import INVENTORY from '../../assets/homepage/inventory.png';
import SECURITY from '../../assets/homepage/security.png';
import CHAT from '../../assets/homepage/chat.png';

class FeaturesKungBakit extends Component {

    render() {
        let isSmall = this.props.isSmall;
        let bakitItemStyle = isSmall?{width:'98%',margin:'6px 0px'}:{width:'38%',height:'fit-content'};
        let BakitItem = (props) =>{
                return (
                    <div class='bakit-item' style={bakitItemStyle}>
                        <div style={{display:'flex',height:'100%',justifyContents:'center',alignItems:'center'}}>  
                            <img src={props.img} alt='reason-logo' style={{height:isSmall?'80px':'100px',marginRight:'12px'}}/>
                            <div>
                                <h1 class='sub-txt' style={isSmall?{fontSize:'22px'}:{}}>{props.title}</h1>
                                <p class='sub-txt2' style={isSmall?{fontSize:'16px'}:{}}>{props.sub}</p>
                             </div>
                        </div>
                    </div>);
        }
        return (
            <div class='bakit' style={{width:'100vw',backgroundColor:'rgb(251, 250, 255)'}}>
                <div>
                    <div style={{textAlign:'center',padding:isSmall?'15vh 10px 5vw 10px':'15vh 20px 5vh 20px'}}>
                        <h1 class='main-txt' style={{fontSize:isSmall?'28px':'40px'}}>Say goodbye to manual 🤯</h1>
                        <h1 class='sub-txt'  style={{fontSize:isSmall?'18px':'25px',marginTop:'10px',color:'rgb(82, 76, 106)',fontWeight:'300'}}>Cashr POS ensures swift and accurate transactions, leaving your customers impressed and your staff stress-free.</h1>
                    </div>

                    <div style={{padding:isSmall?'20px 2vw':'20px 1vw',textAlign:'center'}}>
                        <BakitItem 
                            title={'Seamless Transactions'}
                            sub={'Say goodbye to long wait times and manual calculations.'}
                            img={FAST_TOME}/>
                        <BakitItem 
                            title={'Real-time Insights'}
                            sub={'Monitor sales trends, peak hours, and top-selling products.'}
                            img={GRAPH}/>
                        <BakitItem 
                            title={'Inventory Management'}
                            sub={'Prevent stockouts and overstock situations with real-time inventory tracking.'}
                            img={INVENTORY}/>
                        <BakitItem 
                            title={'Business Growth'}
                            sub={'Scale your business with confidence. Cashr POS can adapt to your needs.'}
                            img={ROCKET}/>
                        <BakitItem 
                            title={'Security & Compliance'}
                            sub={'Protect your business and customers with our advanced security features.'}
                            img={SECURITY}/>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
        );
    }
}
class PricingOptions extends Component {
    render(){

        let isSmall = this.props.isSmall;

        let PackageItem = (props) =>{
            return(
                <div class='package' style={{display:'inline-block', width:isSmall?'100%':'340px',maxWidth:'340px',padding:'8px'}}>
                    <div style={{textAlign:'center',padding:'14px 20px', color:'white',backgroundColor:props.color,border:'solid 5px white'}}>
                        <h1>{props.title}</h1>
                        <h3>{props.sub}</h3>
                    </div>
                    <div style={{padding:isSmall?'20px 10px':'20px 20px',textAlign:'left', backgroundColor:'white',margin:'0px 28px',borderBottomRightRadius:'30px',borderBottomLeftRadius:'10px',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}>
                        {props.featuresDiv}
                        <br/>
                    </div>
                </div>
            );
        }

        return (
            <div class='pricing-show' style={{padding:isSmall?'8vh 20px':'8vh 40px',backgroundColor:'rgb(66,43,163)'}}>
            <div> 
                <h2  class='main-txt' style={{color:'white',fontSize:isSmall?'30px':'40px'}} >Tailored Pricing for Every Business Need 🎯</h2>
                <p style={{color:'white'}} >We offer flexible pricing options that can be customized to suit your specific need for as low as ₱500 per month.</p>
            </div>
            <br/> <br/>

            <div style={{textAlign:'center', width:'100%',overflow:'hidden'}}>

                <PackageItem 
                    title={'Free'}
                    sub={'(Limited features)'}
                    color={'rgb(240,107,78)'}
                    featuresDiv={
                        <div>
                            <p class='pckg-txt-y'>✅ Cashr POS Free Version</p>
                            <p class='pckg-txt-n'>❌ Business website</p>
                            <p class='pckg-txt-n'>❌ Printer integration</p>
                        </div>
                    }/>

                    <PackageItem 
                        title={'Basic'}
                        sub={'₱1,200 per month'}
                        color={'rgb(223,32,39)'}
                        featuresDiv={
                        <div>
                            <p class='pckg-txt-y'>{'✅ Cashr POS Basic'}</p>
                            <p class='pckg-txt-y'>✅ Business website</p>
                            <p class='pckg-txt-y'>✅ Printer integration</p>
                        </div>
                    }/>

            </div>
           <br/><br/><br/>

            <div style={{display:isSmall?'':'flex',padding:'40px 5vw',backgroundColor:'white',boxShadow: '-0.5rem 0.5rem rgba(35, 19, 100, 0.7)'}}>
          
                <div style={{width:'100%'}}>
                    <h2>Want a customized package for your business?</h2>
                    <p>We can create custom pricing package for your specific business needs</p>
                    <br/><br/>
                    <div style={{display:'flex',backgroundColor:'rgba(66,43,163,0.1)',borderRadius:'20px',padding:'4px 12px',width:'fit-content'}}>
                        <img src={CHAT} alt='chat-logo' style={{height:isSmall?'80px':'80px',marginRight:'12px'}}/>
                        <p class='main-txt' style={{padding:'28px 10px',color:'rgb(66,43,163)'}}>Send us a message</p>
                    </div>
                    <br/>
                </div>
                <div style={{width:'100%',padding:'30px',textAlign:'center'}}>
                    <div style={{display:'inline-block',height:'fit-content',width:isSmall?'100%':'260px', maxWidth:'280px', borderRadius:'10px', padding:'28px 20px',backgroundColor:'rgb(66,43,163)',margin:'4px'}}>
                        <h3 style={{color:'white'}}>⚡️ Flexible Payment Terms</h3>
                    </div>
                    <div style={{display:'inline-block',height:'fit-content',width:isSmall?'100%':'260px',maxWidth:'280px',borderRadius:'10px', padding:'28px 20px',backgroundColor:'rgb(66,43,163)',margin:'4px'}}>
                        <h3 style={{color:'white'}}>🎁 Exclusive Merchant Discounts</h3>
                    </div>

                    <div style={{display:'inline-block',height:'fit-content',width:isSmall?'100%':'260px',maxWidth:'280px',borderRadius:'10px', padding:'28px 20px',backgroundColor:'rgb(66,43,163)',margin:'4px'}}>
                        <h3 style={{color:'white'}}>🎯 Tailored Solutions</h3>
                    </div>
                    <div style={{display:'inline-block',height:'fit-content',width:isSmall?'100%':'260px',maxWidth:'280px',borderRadius:'10px', padding:'28px 20px',backgroundColor:'rgb(66,43,163)',margin:'4px'}}>
                        <h3 style={{color:'white'}}>🔒 Exclusive Rate Lock</h3>
                    </div>
                   
                </div>
            </div>

           <br/>
        </div>
        );
    }
}

class UpgradeTheWay extends Component {
    render(){
        let isSmall = this.props.isSmall;
        return (
            <div style={{padding:'10vh 0px',backgroundColor:'white'}}>
                <div style={{textAlign:'center',padding:'10px 20px'}}>
                    <h1 class='main-txt' style={{fontSize:isSmall?'40px':'55px',color:'rgb(66,43,163)'}}>Upgrade the way you do business</h1>
                    <h3 class='sub-txt' style={{fontSize:isSmall?'24px':'34px',color:'rgb(60,60,60)'}}>Say goodbye to long queues and frustrated customers</h3>
                </div><br/><br/>
                
                <div style={{marginTop:'20px',padding:'5vh 0vw'}}>
                    <div style={{ }}>
                        <div style={{display:'inline-block',width:isSmall?'100%':'50%'}}>
                            <img alt='pos-action-img' style={{width:'100%',objectFit:'cover',maxHeight:'300px',paddingTop:'14px'}} src={'https://images.unsplash.com/photo-1647427017067-8f33ccbae493?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80'}/>
                        </div>
                        <div style={{display:'inline-block',marginLeft:isSmall?'5vw':'', width:isSmall?'98%':'50%',boxShadow: '-0.5rem 0.5rem rgb(66,43,163)', 
                        backgroundColor:'white',padding:'24px 7vw 24px 24px'}}>
                            <br/>
                            <h1 class='main-txt' style={{fontSize:isSmall?'24px':'28px',color:'rgb(60,60,60)'}}>Do business your way</h1>
                            <br/>
                           
                            <h1 class='sub-txt2' style={{fontSize:isSmall?'18px':'18px',color:'rgb(60,60,60)',paddingLeft:'10px'}}>
                             ✅  Do everything in seconds with Cashr's straightforward controls, designed with simplicity in mind.</h1><br/>
                            <h1 class='sub-txt2' style={{fontSize:isSmall?'18px':'18px',color:'rgb(60,60,60)',paddingLeft:'10px'}}>
                             ✅ With Cashr POS's portable design, you can do business anytime, anywhere!</h1><br/>
                            <h1 class='sub-txt2' style={{fontSize:isSmall?'18px':'18px',color:'rgb(60,60,60)',paddingLeft:'10px'}}>
                             ✅ Every business is unique, which is why we offer a range of pricing options to cater to your specific requirements. </h1>
                            <br/><br/>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
            </div>
        );
    }
}
class HomePage extends Component {

    constructor(props) {
        super(props); 
        this.state ={
            width:window.innerWidth
        }
    }

    componentDidMount=()=>{
        window.addEventListener('resize', this.updateDimensions);
    } 
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }


    updateDimensions = () => {
        this.setState({ width:window.innerWidth});
    };

    render() {
        let isSmall = this.state.width<920;
        return (
            <div className='home-page'>
                <div class='bungad'>
                    <div style={{display:isSmall?'':'flex',paddingTop:'60px',height:'fit-content'}}>
                        <div class='part-1' style={{width:isSmall?'100%':'60%',padding:isSmall?'12vh 5vw 10vh 5vw':'12vw 1vw 12vw 6vw'}}>
                            <div>
                                <h1 class='main-txt' style={{color:'white',fontSize:isSmall?'50px':'80px',lineHeight: isSmall?'50px':'80px'}}>
                                    <span >
                                        It's better 
                                    </span>
                                    <br/>
                                    <span >
                                        with 
                                    </span>
                                    <span style={{color:'rgb(165, 224, 128)'}}>
                                        {' CASHR'} 
                                    </span>
                                </h1>
                                <h3 class='sub-txt' style={{color:'white',fontSize:isSmall?'26px':'45px',fontWeight:'200'}}>⚡ Seamless transactions, all at your fingertips. </h3>
                            </div>
                            <div style={{marginTop:'38px'}}>
                                <button class='btn1 bungad-btn' style={{width:isSmall?'100%':'',height:'60px'}}>Discover how</button>
                            </div>
                        </div>
                        <div class='part-2' style={{height:'100%',width:isSmall?'100%':'40%',display:'flex',alignItems:'center',padding:isSmall?'0px 10vw':'10vw 2vw'}}>
                            <img alt='pos-brick' style={{width:'100%',padding:'10px 4vw 10px 0px'}} src={'https://loyverse.com/sites/all/themes/loyversecom/images/home/loyverse-pos.webp'}/>
                        </div>
                    </div>
                </div>
                <FeaturesKungBakit isSmall={isSmall}/>
                <UpgradeTheWay isSmall={isSmall} width={this.state.width}/>

               <PricingOptions isSmall={isSmall}/>
                <br/><br/><br/><br/><br/><br/>
            </div>
        );
    }
}

export default HomePage;