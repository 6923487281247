import React, { Component } from 'react';
import AuthService from '../service/AuthService';
import {LOGIN_STATUS} from '../constants.js';
import './HeaderComp.css';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

class HeaderComp extends Component {

    constructor(props) {
        super(props); 
        this.state ={
            width:window.innerWidth
        }
    }

    componentDidMount=()=>{
        window.addEventListener('resize', this.updateDimensions);
    } 
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    updateDimensions = () => {
        this.setState({ width:window.innerWidth});
    };


    logoutClicked=()=>{
		AuthService.logout();
    }
    render() {
        let showMenuOptions = this.state.width>880;
        let isLoggedIn=this.props.exchange.firebaseUser!=null;
        let isSmall = isMobile || this.state.width <700;
        let btnClassName = isSmall ? 'btn1 hdr-btn-m': 'btn1 hdr-btn'
        return (
            <div className={isSmall?'header-comp-m':'header-comp'}>
                <div class='hdr-app-title'>
                    <h2 class='logo'>CASHR</h2>
                </div>
                <div style={{display:'flex',width:'100%'}}>
                    <div style={{width:'100%'}}>
                    {showMenuOptions&&
                        <div class='header-menu'>
                            <Link class='menu-option' to={'/'}>Home</Link>
                            <button class='menu-option'>Product</button>
                            <button class='menu-option'>Business Types</button>
                            <button class='menu-option'>Pricing</button>
                            <button class='menu-option'>Learn</button>
                        </div>}
                    </div>
                    
                    {!isLoggedIn&&
                    <div class='btns-div'>
                        <button class={btnClassName + ' hdr-login-btn '} onClick={()=>this.props.setLoginStatus(LOGIN_STATUS.LOGIN_SELECT)}>Log in </button>
                        <button class={btnClassName + ' hdr-signup-btn'} onClick={()=>this.props.setLoginStatus(LOGIN_STATUS.SIGNUP_SELECT)}>Sign up</button>
                    </div>}
                </div>
            </div>
        );
    }
}
export default HeaderComp;