import React, { Component } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { GENERIC_MODAL_STYLE } from '../css/ModalProperties';
import {isMobile} from 'react-device-detect';

class CloseableModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width:window.innerWidth
        } 
    }
    componentDidMount=()=>{
        document.body.style.overflow = "hidden";
        window.addEventListener('resize', this.updateDimensions);
    } 
    componentWillUnmount() {
        document.body.style.overflow = "scroll";
        window.removeEventListener('resize', this.updateDimensions);
    }
    updateDimensions = () => {
        this.setState({ width:window.innerWidth});
    }
    render() { 
        return (
            <Modal id="closeable-modal-container" isOpen={true} style={GENERIC_MODAL_STYLE}>   
                <div className='closeable-modal' style={{maxHeight:'100vh'}}>
                    <div className='control-wrpr' style={{display:'flex',justifyContent:'right'}}>
                        {(this.props.onCancel!=null || this.props.onCancelLink!=null)&&
                        <Link to={this.props.onCancelLink} style={{padding:'10px',position:'absolute',zIndex:'10'}} tabIndex={-1}>
                            <IconButton 
                                tabIndex={-1}
                                aria-label="close" 
                                onClick={this.props.onCancel}>
                                    <CancelIcon/>
                            </IconButton>
                        </Link>}
                    </div>
                    <div class='content-wrpr'>
                        {this.props.content}
                    </div>
                </div>
            </Modal>
        );
    }
}

export default CloseableModal;