import React, { Component } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

class WaitPage extends Component {
    render() {
        return (
            <div className='auth-wait-page' style={{transition:'1s', width:'100vw',height:'100vh',backgroundColor:'white'}}>
                <div style={{display: 'flex', justifyContent:'center', alignItems:'center', height: '100vh',paddingBottom:'100px'}}>
                    <CircularProgress />
                </div>
            </div>
        );
    }
}

export default WaitPage;