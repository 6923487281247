import React, { Component } from 'react';
import './ItemPage.css';
import ReactDOM from 'react-dom';
import Modal from 'react-modal'; 
import MenuItem from './MenuItem';
import { Link } from 'react-router-dom';
import AddEditItem from './AddEditItem';
import AddIcon from '@mui/icons-material/Add';
import { isMobile, isMobileOnly } from 'react-device-detect';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmNotif from '../../windows/ConfirmNotif';
import AddCategory from '../../windows/AddCategory';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FirebaseService from '../../../service/FirebaseService';
import CloseableModal from '../../../components/CloseableModal';
import NotificationUtil from '../../../util/NotificationUtil';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {CATEGORY_NAME_LIMIT} from '../../../constants';
import PosHeader from '../PosHeader';
import ItemsTable from './ItemsTable'
const ADD_URI = 'add';
const ADD_CATEGORY_URI = 'add-category';
const ITEM_ACTION_DELETE_URI = 'delete';
export const VIEW_TYPE= Object.freeze({
    CATEGORY:{id:'category',label:'Category View'},
    TABLE:{id:'table',label:'Table View'}
});

class ItemViewSelector extends Component {

    render(){
        return(
            <div className='item-view-selectr' style={{width:'200px',backgroundColor:'white'}}>
                <Link class='link-2' to='/items/category'>
                    <div className='viw-typ'>Category view</div>
                </Link>

                <Link class='link-2' to='/items/table'>
                    <div className='viw-typ'>Table view </div>
                </Link>
            </div>
        )
    }
}
class ItemsPage extends Component {

    constructor(props) {
        super(props);
        this.viewTypeSelectorRef = React.createRef();
        let currentView = VIEW_TYPE.CATEGORY;
        if(this.props.view!=null){
            currentView = this.props.view
        }

        let itemEdit = null;
        let onAddItem = false;
        if(this.props.itemId!=null){
            if(this.props.itemId===ADD_URI){
                this.setState({onAddItem:true})
                onAddItem = true;
            } 
            else if(this.props.itemId!==ADD_CATEGORY_URI){
                itemEdit = this.getItemById(this.props.itemId)
            }
        }

        this.state = {
            onAddItem:onAddItem,
            groupedItems:[],

            categoryOnEdit:{
                index:-1,
                inputText:''
            },
            itemEdit:itemEdit,
            width:window.innerWidth,
            viewType:currentView,
            showViewSelectorModal:false
        }
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        window.addEventListener('resize', this.updateDimensions);
        this.setState({groupedItems:this.buildGroupedItems()});
    }

     componentWillUnmount() {
         window.removeEventListener('resize', this.updateDimensions);
     }
     updateDimensions = () => {
         this.setState({ width:window.innerWidth});
     }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.exchange!=null){
            this.setState({groupedItems:this.buildGroupedItems(nextProps.exchange)});
        }
        if(nextProps.view!=null){
            this.setViewType(nextProps.view);
        }

        if(nextProps.itemId!=null){
            if(nextProps.itemId===ADD_URI){
                this.setState({onAddItem:true})
            } 
            else if(nextProps.itemId!==ADD_CATEGORY_URI){
                this.setState({itemEdit:this.getItemById(nextProps.itemId)})
            }
        }else if(typeof nextProps.itemId ==='undefined'){
            if(this.state.itemEdit!=null){
                this.setState({itemEdit:null})
            } else if(this.state.onAddItem){
                this.setState({onAddItem:false})
            } 
        }
       
     }

     getItemById=(itemId)=>{
        return this.props.exchange.storedSession.business.menu.items.filter(it=>it.id===itemId)[0]
     }
     buildGroupedItems=(exchangeParam)=>{
        let exchange = this.props.exchange;
        if(exchangeParam!=null){
            exchange=exchangeParam;
        }
        if(exchange==null 
             || exchange.storedSession==null
             || exchange.storedSession.business.menu==null 
             || exchange.storedSession.business.menu.category==null){ return null;}
        let categoryName  = Object.values(exchange.storedSession.business.menu.category);
        let categoryIds = Object.keys(exchange.storedSession.business.menu.category);
        let items = exchange.storedSession.business.menu.items==null?[]:
                        Object.values(exchange.storedSession.business.menu.items)
        let groupedItems = [];
        categoryName.forEach((name,idx)=>{  
            let grpItem = {
                  categoryName:name
                , categoryId:categoryIds[idx]
                , items:items.filter(it=>it.categoryId===categoryIds[idx])
            };
            groupedItems.push(grpItem);
        });
        return groupedItems;
    }

    onCancelAddItem=()=>{
        this.setState({onAddItem:false});
    }

    renderItems=(groupItems)=>{
        let items = groupItems.items;
        if(this.props.exchange.storedSession==null)return;

        let itemDivs=[];
        items.forEach((menuItem,idx)=>{
            itemDivs.push(
                <Link className='link-2' to={'/items/' + VIEW_TYPE.CATEGORY.id + '/' + menuItem.id} tabIndex={-1}>
                    <MenuItem 
                        item={menuItem}
                        deleteLink={'/items/' + VIEW_TYPE.CATEGORY.id + '/' + menuItem.id +'/delete'}
                    />
                </Link>
            );
        });

        return (
                <div className='items-div'>
                    {itemDivs}
                </div>
        )
    }


    changeInputHandler=(event)=>{
        switch(event.target.id){
            case 'category-edit-input':
                if(event.target.value.length<CATEGORY_NAME_LIMIT){
                    let categoryOnEdit = this.state.categoryOnEdit;
                    categoryOnEdit.inputText = event.target.value;
                    this.setState({categoryOnEdit:categoryOnEdit}); 
                }
            break;
            
        default:
                break;
        }
    }

    editCategoryName=(grp,categoryIdx)=>{
        this.setState({
            categoryOnEdit:{
                index:categoryIdx,
                inputText:grp.categoryName
            }});
    }

    cancelCategoryEdit=()=>{
        this.setState({categoryOnEdit:{index:-1,inputText:''}});
    }

    saveCategoryNameEdit=(group)=>{
        FirebaseService.editMenuCategory(
            this.props.exchange.storedSession.business.id, group.categoryId, this.state.categoryOnEdit.inputText).then(()=>{
                this.setState({categoryOnEdit:{index:-1,inputText:''}},this.props.exchange.refreshCache);
            }); 
       
    }

    deleteCategory=(category)=>{
        FirebaseService.deleteMenuCategory(this.props.exchange.storedSession.business.id,category.categoryId).then(()=>{
            NotificationUtil.makeToast('Category deleted','rgb(55,66,71)','white',2000,'top-right');
            this.props.exchange.refreshCache();
        }).catch((error)=>{
            console.log('deleteCategory error',JSON.stringify(error));
        });
    }

    renderGroupedItems=()=>{
        if(this.emptyCategory()) {
            return (
                <div style={{textAlign:'center',width:'100%',padding:'10vh 0px'}}>
                    <h2>No items yet 🤷</h2>
                    <p style={{color:'gray',marginTop:'6px'}}>Create your first category to start adding your inventory </p>
                    <br/> <br/>
                    <div className='centerItem'>
                        <Link className={isMobile?'btn1-m btn-dkblue':'btn1 btn-dkblue'}
                            to={'/items/' +this.state.viewType.id+'/'+ADD_CATEGORY_URI}
                            style={{minWidth:'180px',width:'180px'}}>
                            Add Category
                        </Link>
                    </div>
                </div>
            );
        };

        let categoryDivs = [];
        this.state.groupedItems.forEach((grp,categoryIdx)=>{
            let onEditName = this.state.categoryOnEdit.index === categoryIdx;
            let categoryNameVal = 
                onEditName?
                     this.state.categoryOnEdit.inputText
                    :grp.categoryName;
            categoryDivs.push(
                <div className={'category-div' + (isMobileOnly?' category-div-mobile':'')} >
                    <div class='hdr' style={{display:'flex'}}>
                        {(grp.items.length===0 && !onEditName)&&
                        <IconButton  onClick={()=>{this.deleteCategory(grp);}}
                            sx={{ "&:hover": { color: "rgb(233, 89, 89)", backgroundColor:'transparent'},padding:'0px', color:'white' }} aria-label="close">
                            <DeleteIcon fontSize="medium" /></IconButton>}

                        <TextField
                            id='category-edit-input'
                            variant="standard"
                            value={categoryNameVal}
                            tabIndex={-1}
                            onChange={this.changeInputHandler} 
                            // onBlur={this.cancelCategoryEdit}
                            onFocus={()=>this.editCategoryName(grp,categoryIdx)}
                            sx={{color:'white',width:'100%',input: { color: 'white', fontSize:'1rem', textAlign:'center',  fontFamily: "'Poppins', sans-serif" ,fontWeight:'800'} }}/>
                        
                        {onEditName&&
                        <IconButton onClick={this.cancelCategoryEdit} tabIndex={-1}
                            sx={{ "&:hover": { color: "rgb(110,194,60)", backgroundColor:'transparent'},transition:'0.2s', color:'white', padding:'0px 0px 0px 6px' }} aria-label="close">
                         <CloseIcon fontSize="medium" /></IconButton>}
                         
                        {onEditName&&
                        <IconButton onClick={()=>{this.saveCategoryNameEdit(grp);}} tabIndex={-1}
                            sx={{ "&:hover": { color: "rgb(110,194,60)", backgroundColor:'transparent'},transition:'0.2s', color:'white', padding:'0px 0px 0px 6px' }} aria-label="close">
                         <SaveIcon fontSize="medium" /></IconButton>}
                        
                        {!onEditName&&
                        <Link to={'/items/' +this.state.viewType.id+'/'+ADD_URI+'/'+grp.categoryId} tabIndex={-1}>
                            <IconButton tabIndex={-1}
                                sx={{ "&:hover": { color: "rgb(110,194,60)", backgroundColor:'transparent'},transition:'0.2s', color:'white', padding:'0px' }} aria-label="close">
                            <AddCircleIcon fontSize="medium" /></IconButton>
                        </Link>}
                         
                         
                    </div>
                    {this.renderItems(grp)}
                </div>
            )
        });

        return (
            <div className='category-items-div' style={isMobile?{margin:'0px'}:{}}>
                {categoryDivs}
            </div>
        )
    }

    onDeleteMenuItemSuccess=()=>{
        NotificationUtil.makeToast('Item deleted','rgb(55,66,71)','white',2000,'top-right');
        this.props.exchange.refreshCache();
        window.history.replaceState({}, document.title, '/items/'+this.state.viewType.id);
    }

    emptyCategory=()=>{
       return this.state.groupedItems==null || this.state.groupedItems.length===0;
    }

    getItemViewSelectorModalStyle=()=>{
        const viewTypeSelectorDom = ReactDOM.findDOMNode(this.viewTypeSelectorRef.current);
        let contentTop=140;
        let contentLeft =55;
        if(viewTypeSelectorDom!=null){
            let divClientRect = viewTypeSelectorDom.getBoundingClientRect();
            contentTop=divClientRect.bottom + 3;
            contentLeft=divClientRect.left;
        }
        return {
            content : {
                 transform:'translate(0%, 0%)', top:contentTop, left:contentLeft, right:'auto', bottom:'auto',backgroundColor:'transparent'
                ,boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px', padding:'0px', border:'none',borderRadius:'4px'
            },
            overlay: { 
                transition:'0.4s',zIndex:'100' ,backgroundColor:'transparent'
            }
        };
    }

    renderMainContent=()=>{
        switch(this.state.viewType.id){
            case VIEW_TYPE.CATEGORY.id: return this.renderGroupedItems();
            case VIEW_TYPE.TABLE.id: return this.renderItemsTable();
            default:break;
        }
       
    }

    renderItemsTable=()=>{
        let renderNoCategory = ()=>{
            return(
                <div className='box-div' style={{textAlign:'left',width:'100%',padding: '1.5vh 12px',backgroundColor:'white'}}>
                    <h3>No items yet 🤷</h3>
                    <p style={{color:'gray'}}>Create your first category to start adding your inventory </p>
                    <div style={{marginTop:'6px'}}>
                        <Link className={isMobile?'btn1-m btn-dkblue':'btn1 btn-dkblue'}
                            to={'/items/' +this.state.viewType.id+'/'+ADD_CATEGORY_URI}
                            style={{minWidth:'180px',width:'180px'}}>
                            Add Category
                        </Link>
                    </div>
                </div>
            );
        };
        return(
            <div className='items-table-view' style={{width:'100%',overflowX:'scroll'}}>
                {this.emptyCategory()&& renderNoCategory()}
                <ItemsTable exchange={this.props.exchange}/>
            </div>
        )
    }

    renderItemControlPanel=()=>{
        return (
            <div className='item-cpanel'>
                <div className='view-type-selector' ref={this.viewTypeSelectorRef} onClick={()=>this.setState({showViewSelectorModal:true})}>
                    <h3 className={isMobile?'c-p2':'c-h6'}>{this.state.viewType.label}</h3>
                    <ArrowDropDownIcon sx={{fontSize:isMobile?'19px':'22px'}}/>
                </div> 
                <div className='ac-btns' style={{display:'flex',width:'100%',justifyContent: 'flex-end'}}>
                    {this.emptyCategory()===false &&
                    <Link to={'/items/' +this.state.viewType.id+'/'+ADD_CATEGORY_URI} tabIndex={-1}
                        className={isMobile?'btn1-m btn-dkblue':'btn1 btn-dkblue'}
                        style={{padding:'0px 10px'}}>
                        <AddIcon style={{marginRight:'2px',fontSize:'18px'}}/>
                    <span>{isMobile?'Category':'Add Category'}</span>
                    </Link>}

                    {this.emptyCategory()===false &&
                    <Link to={'/items/' +this.state.viewType.id+'/'+ADD_URI} tabIndex={-1}
                        className={isMobile?'btn1-m btn-dkblue':'btn1 btn-dkblue'}
                        style={{marginLeft:'5px',padding:'0px 10px'}}>
                    <AddIcon style={{marginRight:'2px',fontSize:'18px'}}/> {isMobile?'Item':'Add Item'}
                    </Link>}
                </div>
            </div>
        )
    }

    cancelItemEdit=()=>{
        this.setState({itemEdit:null})
    }

    setViewType=(vType)=>{
        this.setState({showViewSelectorModal:false,viewType:vType});
    }

    render() {
        if(this.props.exchange.storedSession==null) return null;
        return (
            <div className='items-page page' style={{overflowX:'hidden'}}>
                <PosHeader 
                    title={'Items'}
                    exchange={this.props.exchange}
                    />
                <div className='page-body' style={{width:'100%',overflowX:'hidden'}}>
                        {this.renderItemControlPanel()}
                        {this.renderMainContent()}
                </div>
                {this.props.itemAction===ITEM_ACTION_DELETE_URI&&
                <ConfirmNotif
                    mainText={'Are you sure you want to delete this item?'}
                    seondaryText={'This action cannot be undone.'}
                    acceptLabel={'Delete'}
                    onAccept={()=>{
                        FirebaseService.deleteMenuItem(this.props.exchange.storedSession.business.id, this.props.itemId)
                            .then(this.onDeleteMenuItemSuccess)
                            .catch((error)=>{
                                console.log('FirebaseService.deleteMenuItem error',JSON.stringify(error));
                            });
                    }}
                    onCloseLink={'/items/'+this.state.viewType.id}
                />}

                {this.state.onAddItem&&
                    <CloseableModal 
                        content={
                            <AddEditItem
                                targetCategoryId={this.props.itemAction}
                                exchange={this.props.exchange}
                                business={this.props.exchange.storedSession.business}
                                onCloseLink={'/items/'+this.state.viewType.id}
                                />}
                        onCancelLink={'/items/'+this.state.viewType.id}
                    />}

                {(this.state.itemEdit!=null&&this.props.itemAction==null)&&
                    <CloseableModal 
                        content={
                            <AddEditItem
                                exchange={this.props.exchange}
                                business={this.props.exchange.storedSession.business}
                                onCloseLink={'/items/'+this.state.viewType.id}
                                itemEdit={this.state.itemEdit}
                                />}
                        onCancelLink={'/items/'+this.state.viewType.id}
                    />}

                {this.props.itemId===ADD_CATEGORY_URI&&
                    <CloseableModal 
                        content={
                            <AddCategory
                                exchange={this.props.exchange}
                                business={this.props.exchange.storedSession.business}
                                onCloseLink={'/items/'+this.state.viewType.id}
                            />}
                        onCancelLink={'/items/'+this.state.viewType.id}
                    />}
 
                <Modal id="item-view-modal" 
                    shouldCloseOnOverlayClick={true} 
                    isOpen={this.state.showViewSelectorModal}
                    shouldCloseOnEsc={true}
                    style={this.getItemViewSelectorModalStyle()}
                    onRequestClose={()=>this.setState({showViewSelectorModal:false})}
                    >
                    <ItemViewSelector
                        currentViewType={this.state.viewType}
                    />
                           
                </Modal>    
            </div>
        );
    }
}

export default ItemsPage;