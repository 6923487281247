class ItemUtil {
    
    getVariants=(item)=>{
        
        return item.variants==null?[]:Object.values(item.variants);
    }

    hasVariants=(item)=>{
        return item!=null && this.getVariants(item).length>0;
    }
}

export default new ItemUtil();