import React, { Component } from 'react';
import Util from '../../util/Util';
import {isMobile} from 'react-device-detect';
import {PRODUCT_META, DEFAULT_PRODUCT_META} from './config.js';
import CheckIcon from '@mui/icons-material/Check';
import PaymentService from '../../service/PaymentService';
import SubscriptionService from '../../service/SubscriptionService';
class SubscriptionOption extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
             price:null
            ,discount:null
        }
        this.getPrice()   
    }

    async componentDidMount(){

    }

    getPrice=async()=>{
        let price = await SubscriptionService.getPrice(this.props.product.id, this.props.product.defaultPriceId)
        let discount = await SubscriptionService.getDiscount(this.props.product.id, this.props.product.discountId)
       
        this.setState({price:price, discount:discount})
    }

    subscribeClicked=async()=>{
        let checkoutSuccess=(payLink)=>{
            Util.openInNewTab(payLink);
        }
        let checkoutFail=()=>{

        }
        PaymentService.checkout(
              this.props.product
            , this.props.product.defaultPriceId
            , this.props.product.discountId
            , this.props.storedSession
            , checkoutSuccess
            , checkoutFail);
    }

    getIntervalUnit=(interval)=>{
        let intervalTxt = {
            MONTHLY:{
                unit:'month'
            },
            YEARLY:{
                unit:'year'
            }
        }

        return intervalTxt[interval].unit
    }

    numberWithCommas=(x)=>{
        let roundedX= Math.round((x + Number.EPSILON) * 100) / 100;
        return roundedX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render(){
        let productMeta = PRODUCT_META[this.props.product.id]==null?
                            PRODUCT_META[DEFAULT_PRODUCT_META] : PRODUCT_META[this.props.product.id];
        let featuresTxt=[];
        this.props.product.featureList.forEach((ftr,idx)=>{
            featuresTxt.push(
                <div style={{marginBottom:'8px'}}>
                    <p><CheckIcon sx={{fontSize:'16px',marginRight:'6px'}}/>
                        {ftr}
                    </p>
                </div>
            );
        })

        let priceAmount = null
        if(this.state.price!=null){
            priceAmount = this.state.price.amount * this.props.product.plan.intervalCount  
        }
        let originalPrice = null
        if(this.state.discount!=null){
            originalPrice = priceAmount
            switch(this.state.discount.type){
                case 'FIXED':
                    priceAmount = this.state.discount.price.amount
                break;
            }
        }
        return(
            <div className={'subsc-typ' +(isMobile?' subsc-typ-mobile':'')} style={{textAlign:'left'}}>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <h1 style={{marginLeft:'16px'}}>{this.props.product.shortname}</h1>
                    <div style={{backgroundColor:productMeta.priceBgColor,transition:'0.2s', color:productMeta.priceColor,padding:'2px 28px 2px 14px',borderTopLeftRadius:'6px',borderBottomLeftRadius:'6px'}}>
                        {originalPrice&&
                            <p style={{fontSize:'1rem',fontWeight:'200'}}>
                                <span style={{fontSize:'0.85rem',fontWeight:'600',backgroundColor:'rgb(245,81,29)',borderRadius:'4px',padding:'0px 4px',color:'white',margin:'0px 4px 10px 0px'}}>{this.state.discount.label}</span>
                                <span style={{textDecoration: 'line-through',marginTop:'4px'}}>{'\u00a0₱'+originalPrice+'\u00a0'}</span>
                            </p>}

                        {priceAmount&&
                        <p>
                            <span style={{fontSize:'2rem',fontWeight:'200',marginRight:'4px'}}>₱</span>
                            <span style={{fontSize:'2rem',fontWeight:'600'}}>{this.numberWithCommas(priceAmount)}</span>
                            {/* <span>{'/ '+ this.getIntervalUnit(this.props.product.plan.interval)}</span> */}
                        </p>}
                    </div>
                </div>
                <br/>
                <div className='features-list' style={{padding:'10px 28px'}}> 
                    {featuresTxt}
                </div>
                <div className='btn-wrapper-shsgkj' style={{padding:'20px'}}>
                    <button className='btn1 subscribe-btn' onClick={this.subscribeClicked}>
                        Subscribe
                    </button>
                </div>
            </div>
        )
    }
}

export default SubscriptionOption;