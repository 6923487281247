import {  ref, child, push, update} from "firebase/database";
import {db} from '../../integrations/firebase';
class ItemService {
    
    add = async (businessId, name, cost, price, categoryId, variants,barcode, sku,image,trackStock,hasVariant,sameCostPrice, sameBarcode, onSuccessCallback, onErrorCallback) => {  
        let path = 'business/'+businessId+'/menu/items';
        // Get a key for a new menu item.
        const newItemKey = push(child(ref(db), path)).key;
        let newItem={
            id:newItemKey,
            name:name,
            categoryId:categoryId,
            businessId:businessId,
            barcode:barcode,
            sku:sku,
            image:image,
            trackStock:trackStock,
            sameCostPrice:hasVariant?sameCostPrice:null,
            sameBarcode:hasVariant?sameBarcode:null,
            cost:cost,
            price:price,
            hasVariant:hasVariant,
            variants:variants
        }
        const updates = {};
        updates[path + '/' + newItemKey] = newItem;
     
        update(ref(db), updates).then(()=>{
            onSuccessCallback(newItem);
        })
        .catch((error) => {
            console.log('saved failed',error);
        });
    }


    update = async (businessId, itemId, name, cost, price, categoryId, variants, barcode, sku,image, trackStock,hasVariant,sameCostPrice, sameBarcode, onSuccessCallback, onErrorCallback) => {  
        let path = 'business/'+businessId+'/menu/items/'+itemId;

        const updates = {};
        if(name!=null){updates[path + '/name'] = name;}
        if(cost!=null){updates[path + '/cost'] = Number(cost)}
        if(price!=null){updates[path + '/price'] = Number(price)}
        if(categoryId!=null){updates[path + '/categoryId'] = categoryId;}
        if(barcode!=null){updates[path + '/barcode'] = barcode;}
        if(sku!=null){updates[path + '/sku'] = sku;}
        if(image!=null){updates[path + '/image'] = image;}
        if(trackStock!=null){updates[path + '/trackStock'] = trackStock;}
        if(hasVariant!=null){updates[path + '/hasVariant'] = hasVariant;}
        if(sameCostPrice!=null){updates[path + '/sameCostPrice'] = hasVariant?sameCostPrice:null;}
        if(sameBarcode!=null){updates[path + '/sameBarcode'] = hasVariant?sameBarcode:null;}
        
        if(hasVariant && variants!=null){
            updates[path + '/variants'] = variants;
        }else if(hasVariant===false){
            updates[path + '/variants'] = null;
        }
      
        update(ref(db), updates).then(()=>{
            if(onSuccessCallback!=null){
                onSuccessCallback();
            }
        })
        .catch((error) => {
            console.log('saved failed',error);
        });
    }

    updateV2 = async (item) => {  
        let path = 'business/'+item.businessId+'/menu/items/'+item.id;

        const updates = {};
        if(item.name!=null){updates[path + '/name'] = item.name;}
        if(item.cost!=null){updates[path + '/cost'] = Number(item.cost)}
        if(item.price!=null){updates[path + '/price'] = Number(item.price)}
        if(item.categoryId!=null){updates[path + '/categoryId'] = item.categoryId;}
        if(item.barcode!=null){updates[path + '/barcode'] = item.barcode;}
        if(item.sku!=null){updates[path + '/sku'] = item.sku;}
        if(item.image!=null){updates[path + '/image'] = item.image;}
        if(item.trackStock!=null){updates[path + '/trackStock'] = item.trackStock;}
        if(item.hasVariant!=null){updates[path + '/hasVariant'] = item.hasVariant;}
        if(item.sameCostPrice!=null){updates[path + '/sameCostPrice'] = item.hasVariant?item.sameCostPrice:null;}
        if(item.sameBarcode!=null){updates[path + '/sameBarcode'] = item.hasVariant?item.sameBarcode:null;}
        
        if(item.hasVariant && item.variants!=null){
            updates[path + '/variants'] = item.variants;
        }else if(item.hasVariant===false){
            updates[path + '/variants'] = null;
        }
      
        return update(ref(db), updates)
    }

    delete = async (businessId, itemId) => { 
        let path = 'business/'+businessId+'/menu/items/'+itemId;
        const updates = {};
        updates[path] = null;
      
        return update(ref(db), updates);
    }

    removeVariant = async (businessId, itemId, variantId, onSuccessCallback, onErrorCallback) => {  
        let path = 'business/'+businessId+'/menu/items/'+itemId+'/variants/'+variantId;

        const updates = {};
        updates[path] = null;
      
        update(ref(db), updates).then(()=>{
            onSuccessCallback();
        })
        .catch((error) => {
            onErrorCallback();
        });
    }

    
}


export default new ItemService()