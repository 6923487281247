const ODB = '_ODB';
const LAST_VIEWED_ORDER = 'LAST_VIEWED_ORDER';
class OfflineDatabaseService {
    getOdb=()=>{
        let odb = localStorage.getItem(ODB);
        if(odb==null){
            odb={};
        }else{
            odb = JSON.parse(odb);
        }
        return odb;
    }

    updateOdb=(odb)=>{
        localStorage.setItem(ODB,JSON.stringify(odb));
    }

    saveOrder=(businessId, orderInfo, callback)=>{
        let odb = this.getOdb();

        if(odb['orders']==null){ odb['orders']={}; }
        if(odb.orders[businessId]==null){ odb.orders[businessId]={}; }

        odb.orders[businessId][orderInfo.orderNumber]=orderInfo;

        this.updateOdb(odb);
        localStorage.setItem(LAST_VIEWED_ORDER,
            JSON.stringify({
                 orderNumber:orderInfo.orderNumber
                ,orderInfo:orderInfo
            }));
        callback(orderInfo);
    }

    getOrderByOrderNumber=(businessId,orderNum,callback)=> {
        let odb = localStorage.getItem('_ODB');
        if(odb==null){
            callback(null);
        }
        else{
            odb = JSON.parse(odb);
            let orderInfo = odb['orders'][businessId][orderNum];
            console.log('getOrderByOrderNumber orderInfo',orderInfo );
            callback(orderInfo);

        }
        

    }

}
export default new OfflineDatabaseService()