import { getDatabase, ref, child, get, update} from "firebase/database";
import {db} from '../../../integrations/firebase';
const dbRef = ref(getDatabase());
const INVENTORY_PATH='inventory';
class InventoryService {

    getItemStock = async (businessId) => {
        return get(child(dbRef, INVENTORY_PATH+'/'+businessId+'/stock/items'));
    }

    updateItemStock = async (businessId, itemStock) => {  
        let path = INVENTORY_PATH+'/'+businessId+'/stock/items';
        const updates = {};
        updates[path] =  itemStock;
        return update(ref(db), updates);
    }
    

}
export default new InventoryService()