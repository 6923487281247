import React, { Component } from 'react';
import PosHeader from '../PosHeader';
import './TransactionsPage.css';
import moment from "moment";
import OrderList from './OrderList';
import SellIcon from '@mui/icons-material/Sell';
import PaidIcon from '@mui/icons-material/Paid';
import 'react-loading-skeleton/dist/skeleton.css';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RefreshIcon from '@mui/icons-material/Refresh';
import MessageDiv from '../../../components/misc/MessageDiv';
import FirebaseService from '../../../service/FirebaseService';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import RangeDateSelector from '../../../components/RangeDateSelector';
import {isMobileOnly,BrowserView,MobileView,isMobile} from 'react-device-detect';

class ReportWidget extends Component {

    render() {
        return (
            this.props.onLoad?
            <Skeleton inline={true} width={'22%'} height={'115px'}  baseColor="rgb(236, 238, 241)"  highlightColor="rgb(240, 243, 246)" style={{marginRight:'6px',marginBottom:'20px'}} borderRadius={10}/>   
           :<div class='report-widget' style={{backgroundColor:this.props.color,width:isMobileOnly?'45vw':'',minWidth:isMobileOnly?'unset':'',minHeight:isMobile?'130px':'', margin:isMobileOnly?'0px 1.2vw 1.5vh 1.2vw':''}}>
                <div style={{overflow:'absolute',width:'100%',backgroundColor:'blue',height:'0px',position:'relative'}}>
                    <div style={{padding:'0px 8px 0px 120px',opacity:'0.4',position:'absolute'}}>
                        {this.props.img}
                    </div>
                </div>
                <div style={{opacity:'0.97',color:'white'}}>
                    <h1 style={{fontWeight:'900',fontSize:'16px'}}>{this.props.title}</h1>
                    <p style={isMobile?{fontSize:'2rem',fontWeight:'900'}:{fontSize:'2.2rem',fontWeight:'900'}}>{this.props.sub}</p>
                </div>
            </div>
        )
    }
}

class TransactionsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            onLoad:false,
            noAccess:false,
            currentRange:null,
            stats:{
                 totalTransactions:'-'
                ,itemsSold:'-'
                ,totalSales:'-'
                ,netAmount:'-'
            },
            orders:[]
            }
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        this.setRangeToday();
    }

    setRangeToday=()=>{
        let todayBegin = new Date().setHours(0,0,0,0);
        let todayEnd = new Date(todayBegin);
        todayEnd.setDate(todayEnd.getDate() + 1);
        this.setDateRange("Today",moment(todayBegin).format('MMM D, YYYY'),todayBegin.valueOf(),todayEnd.valueOf()-1); 
    }

    reloadTransactions=async()=>{
        this.setState({onLoad:true},this.findOrders);
    }

    findOrders=async()=>{
        FirebaseService.findOrders(
            this.props.exchange.storedSession.business.id
            ,this.state.currentRange.startDate
            ,this.state.currentRange.endDate
            ,(orders)=>{
                let ordersArray=Object.values(orders);
                let allItems=ordersArray.flatMap(or=>or.items);
                let itemsSold= allItems.map(it=>it.qty).reduce((partialSum, a) => partialSum + a, 0);
                let totalSales= allItems.map(it=>it.amount).reduce((partialSum, a) => partialSum + a, 0);
                let netAmount= allItems.map(it=>it.amount-(it.qty*it.cost)).reduce((partialSum, a) => partialSum + a, 0);
                this.setState({
                    onLoad:false,
                    orders:ordersArray,
                    stats:{
                        totalTransactions:ordersArray.length
                        ,itemsSold:itemsSold
                        ,totalSales:totalSales
                        ,netAmount:netAmount
                    }
                });
            },(err)=>{
                this.setState({noAccess:true});
                console.log('TP0001',err);
            });
    }
    
    setDateRange=(label,dateRangeLabel, startDate, endDate)=>{

        this.setState({onLoad:true,currentRange:{label:label,dateRangeLabel:dateRangeLabel,startDate:startDate,endDate:endDate}},this.findOrders);
    }

    pesoWithCommas=(x)=>{
        x=Number(x);
        let roundedX= Math.round((x + Number.EPSILON) * 100) / 100;
        return '₱'+roundedX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    numberWithCommas=(x)=>{
        x=Number(x);
        let roundedX= Math.round((x + Number.EPSILON) * 100) / 100;
        return roundedX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    renderPageBody=()=>{
        return (
            <div className='transactions-main-content' style={{}}>
                <div class='report-header-div' style={{display:isMobile?'':'flex',marginTop:'1vh',marginBottom:'2vh'}}>
                    <div className='centeritem' style={{paddingLeft:'0.5em',width:'80%'}}>
                        <BrowserView><h2 >Total Summary</h2></BrowserView>
                        <MobileView><h3>Total Summary</h3></MobileView>
                    </div>
                    <div class='date-slectr-wrapper' style={{width:'100%',display:'flex',justifyContent:isMobile?'':'right',alignItems:'center'}}>
                        <RangeDateSelector {...this.props} setDateRange={this.setDateRange} currentRange={this.state.currentRange} />
                        
                        <div className='ibtn' style={{padding:'4px',marginLeft:'4px'}} onClick={this.reloadTransactions }>
                            <RefreshIcon/>
                        </div>
                    </div>
                </div>
                {/*  */}
                 <div class='report-widgets-div' style={{display:'inline-block',textAlign: 'center' ,justifyContent:'center',alignItems:'center', alignContent:'center', width:'100%'}}>
                    <ReportWidget title={'Total Transaction'} onLoad={this.state.onLoad} sub={this.numberWithCommas(this.state.stats.totalTransactions)}  color={'rgb(51,181,121)'} img={<ReceiptIcon sx={{color:'white',fontSize:'80px'}}/>}/>
                    <ReportWidget title={'Items Sold'}         onLoad={this.state.onLoad} sub={this.numberWithCommas(this.state.stats.itemsSold)}  color={'rgb(71,112,254)'} img={<CardGiftcardIcon sx={{color:'white',fontSize:'80px'}}/>}/>
                    <ReportWidget title={'Total Sales'}        onLoad={this.state.onLoad} sub={this.pesoWithCommas(this.state.stats.totalSales)} color={'rgb(255,97,73)'} img={<PaidIcon sx={{color:'white',fontSize:'80px'}}/>}/>
                    <ReportWidget title={'Total Net Amount'}   onLoad={this.state.onLoad} sub={this.pesoWithCommas(this.state.stats.netAmount)} color={'rgb(255, 195, 91)'} img={<SellIcon sx={{color:'white',fontSize:'80px'}}/>}/>        
                </div>

                <div className='order-list-wrapper' style={{borderTop:'solid 1px lightgray',display:'grid',justifyContent:'space-around',marginBottom:'20px'}}>
                    <br/>
                    <BrowserView><h2>Order List</h2></BrowserView>
                    <MobileView><h3>Order List</h3></MobileView>
                    <br/>
                    {this.state.onLoad?
                        <SkeletonTheme baseColor="rgb(236, 238, 241)" highlightColor="rgb(240, 243, 246)"  borderRadius={'14px'} >
                            <Skeleton inline={true} width={'100%%'} height={'100px'}/>
                        </SkeletonTheme>:
                        <OrderList orders={this.state.orders}/>}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div class='page transactions-page'>
                <PosHeader 
                    title={'Transactions'}
                    exchange={this.props.exchange}/>

                <div class='page-body' style={{padding:'0px 1.3vw'}}>
                    {this.state.noAccess? <MessageDiv message="🔒 You dont have permission to view this section"/> 
                    :this.renderPageBody()}
                </div>

            </div>
        );
    }
}

export default TransactionsPage;