import React, { Component } from 'react';

class MessageBoard extends Component {
    render() {
        return (
            <div class='msg-board-wrap' style={{width:'100%',padding:'8px',height:'fit-content' }}>
                <div class='msg-board' style={{border:'solid 1px #4771f0',backgroundColor:'#F6F8FE',padding:'10px',borderRadius:'4px'}}>
                    {this.props.contents}
                </div>
            </div>
        );
    }
}

export default MessageBoard;