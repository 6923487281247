import React, { Component } from 'react';
import './SubscriptionPage.css';
import SubscriptionService from '../../service/SubscriptionService';
import SubscriptionOption from './SubscriptionOption'

import CloseIcon from '@mui/icons-material/Close';

class Header extends Component {
    render(){
        return(
            <div className='subs-header' style={{backgroundColor:'white',display:'flex', width:'100%',height:'80px',borderBottom:'solid 1px rgb(230,230,230)',justifyContent:'space-between'}}>
                <div style={{padding:'20px', width:'fit-content', display:'flex',alignItems:'center'}}>
                    <p className='logo-sb' style={{fontFamily: "'Lato', sans-serif",color:'rgb(59,38,153)',fontSize:'30px',fontWeight:'900'}}>CASHR</p>
                </div>
                <div className='close-btn'>
                    <CloseIcon sx={{fontSize:'40px'}}/>
                </div>
            </div>
        )
    }
}

class SubscriptionPage extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            onLoading:false,
            products:[]
        }
    }

    async componentDidMount(){
        let products = await SubscriptionService.getProducts()
        this.setState({products:products})
    }

    render() { 
        let storedSession = this.props.exchange.storedSession;
        let subscriptionOptions = []
        Object.values(this.state.products).forEach(product=>{
            subscriptionOptions.push(
                <SubscriptionOption 
                    product={product}
                    storedSession={storedSession}
                />
            )
        })
        return (
            <div className='subscription-page' style={{width:'100vw',height:'100vh',position:'absolute',left:'0', backgroundColor:'rgb(245,247,249)'}}>
                <Header/>
                <div>
                    {<p>{'Business ID: ' + storedSession.business.id}</p>}
                    <div className='sub-opt-wrapper' style={{display:'block',textAlign:'center'}}>
                        {subscriptionOptions}
                    </div>
                </div>
                
            </div>
        );
    }
}

export default SubscriptionPage;