import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase,connectDatabaseEmulator } from "firebase/database";
import { getAuth } from "firebase/auth";
const ENV = process.env.REACT_APP_BUILD_ENV==='production'?'prod':'dev';

const firebaseConfigDev = {
    apiKey: "AIzaSyD-xiljytJ76oN5yiK3whEev2k38IKuj_4",
    authDomain: "cashr-pos-dev.firebaseapp.com",
    projectId: "cashr-pos-dev",
    databaseURL: "https://cashr-pos-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
    storageBucket: "cashr-pos-dev.appspot.com",
    messagingSenderId: "907911664890",
    appId: "1:907911664890:web:abd267c6ae6d41d77a2543",
    measurementId: "G-2K3W6L7TGZ"
};

const firebaseConfigProd = {
    apiKey: "AIzaSyCFHL3x0e5Ql_zUDBEo6FiO9Sar2I1sjUI",
    authDomain: "cashr-pos-prod.firebaseapp.com",
    databaseURL: "https://cashr-pos-prod-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "cashr-pos-prod",
    storageBucket: "cashr-pos-prod.appspot.com",
    messagingSenderId: "162934130058",
    appId: "1:162934130058:web:d166727cff305bf9e17473",
    measurementId: "G-RHS42CVTP6"
};

const app = initializeApp(ENV==='prod'?firebaseConfigProd:firebaseConfigDev);
export const db = getDatabase(app);
// connectDatabaseEmulator(db, "127.0.0.1", 8888);// if running database locally

export const firestoreDb = getFirestore(app);
export const auth = getAuth();
