import { getAuth } from "firebase/auth"
import moment from "moment"
import { collection, doc, query, where, getDocs, getDoc } from "firebase/firestore";
import { firestoreDb } from '../integrations/firebase';
const auth = getAuth()
const USER_COLLECTION = 'users'
const USER_SUBSCRIPTION_COLLECTION = 'subscriptions'
const DAYS_NOTICE = 12
export const TRIAL_PRODUCT = 'trial-1'
export const SUBSCRIPTION_STATUS=Object.freeze({NO_SUBSCRIPTION:'NO_SUBSCRIPTION',ACTIVE:'ACTIVE',EXPIRING_SOON:'EXPIRING_SOON',EXPIRED:'EXPIRED'})
class SubscriptionService {
    getUserSubscription=async()=>{ 
        if(auth.currentUser==null) return null;
        const q = query(collection(firestoreDb, USER_COLLECTION+'/'+auth.currentUser.uid+'/'+USER_SUBSCRIPTION_COLLECTION)
        // , where("periodEnd", ">", new Date().valueOf())
        );
        const querySnapshot = await getDocs(q); 
        let subs = {}
        let period ={}
        let noticeStart = null
        let notice = null 
        let periodText = null
        await querySnapshot.forEach(async(doc) => {
            let subscription ={id: doc.id, ...doc.data()}
            subscription.timestamp = moment(doc.data().timestamp).format('lll')
            subscription.periodStart = moment(doc.data().periodStart).format('lll')
            subscription.periodEnd = moment(doc.data().periodEnd).format('lll')

            if(period.start==null || doc.data().periodStart<period.start){
                period.start=doc.data().periodStart
            }
            if(period.end==null || doc.data().periodEnd>period.end){
                period.end=doc.data().periodEnd
            }
            subs[subscription.id] = subscription
        })

        let status = SUBSCRIPTION_STATUS.NO_SUBSCRIPTION
        
        if(period.start!=null && period.end!=null){
            noticeStart = moment(period.end).subtract(DAYS_NOTICE,'days')
            periodText = moment(period.start).format('LL') + ' to ' + moment(period.end).format('LL')

            if(noticeStart.valueOf()<(new Date()).valueOf()){ // if expiring soon
                status = SUBSCRIPTION_STATUS.EXPIRING_SOON
                notice={
                     start:noticeStart.format('LL')
                    ,show:noticeStart.valueOf()<(new Date()).valueOf()
                }
            } else if(period.end>(new Date()).valueOf()){
                status = SUBSCRIPTION_STATUS.ACTIVE
            }

            let subscription = {
                 subs:subs
                ,period:period
                ,status:status
                ,notice:notice
                ,periodText:periodText
            }
            return subscription
        } else{
            return null // NO SUBSCRIPTION!
        }

    }


    getProducts=async()=>{
        const q = query(collection(firestoreDb, "products"), where("active", "==", true), where("visible", "==", true));
        const querySnapshot = await getDocs(q); 
        
        let products = []
        await querySnapshot.forEach(async(doc) => {
            let product ={id: doc.id, ...doc.data()}
            products[product.id] = product
        })
        return products
    }
    
    getPrice=async(productId, priceId)=>{
        let price = null
        await getDoc(doc(collection(firestoreDb, 'products'), productId+'/prices/'+priceId)).then(snap => {
            price = snap.data(); 
        });

        return price
    }

    getDiscount=async(productId, discountId)=>{
        let discount = null
        if(discountId!=null){
            await getDoc(doc(collection(firestoreDb, 'products'), productId+'/discounts/'+discountId)).then(snap => {
                discount = snap.data(); 
            })
        }

        return discount
    }
    
    getUser=async(userId)=>{
        let user = null
        await getDoc(doc(collection(firestoreDb, USER_COLLECTION), userId)).then(async(snap) => {
            user=snap.data(); 
        })
        return user;
    }
}
export default new SubscriptionService()