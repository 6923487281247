

import { getDatabase, ref, child, get , push, update,query,startAt,endAt ,orderByKey,increment} from "firebase/database";
import {db} from '../integrations/firebase';
import {BUSINESS_ACCESS,BUSINESS_TABLE,USERS_TABLE} from './Firebase/constants';
import Util from '../util/Util';
import ItemService from './Firebase/ItemService';
const dbRef = ref(getDatabase());
class FirebaseService {//

    addMenuItem = async (businessId, name, cost, price, categoryId, variants,barcode, sku, image, trackStock, hasVariant, sameCostPrice, sameBarcode, onSuceesCallback, onErrorCallback) => { 
        ItemService.add(businessId, name, cost, price, categoryId, variants,barcode, sku, image, trackStock, hasVariant, sameCostPrice, sameBarcode, onSuceesCallback, onErrorCallback);
    }

    updateMenuItem = async (businessId, itemId, name, cost, price, categoryId, variants, barcode, sku,image, trackStock, sameCostPrice, sameBarcode, onSuceesCallback, onErrorCallback) => {  
        ItemService.update(businessId, itemId, name, cost, price, categoryId, variants,barcode, sku, image, trackStock, sameCostPrice, sameBarcode, onSuceesCallback, onErrorCallback);
    }
    // convert variant array to key-value pair object
    transformVariantArray=(variants)=>{
        let vObject = {};
        variants.forEach((v,idx)=>{
            vObject[v.id] = v;
        });

        return vObject;
    }

    deleteMenuItem = async (businessId, itemId) => { 
        return ItemService.delete(businessId, itemId);  
    }

    getUser = async (userId) => {
        return get(child(dbRef, `users/${userId}`));
    }

    buildDefaultBusiness=()=>{
        let business = {};
        business.id = Util.uuidv4();
        business.name = '-';
        business.phone = '-';
        business.email = '-';
        business.lastUpdated = '-';
        business.paymentMethods={
            cash:{id:'cash',enabled:true},
            bank:{id:'bank',enabled:false},
            card:{id:'card',enabled:false},
            ewallet:{id:'ewallet',enabled:false}
        }
        return business;
    }

    buildBusinessAccessList=(business)=>{
        let uuid = business.id;
        let businesAccess={
            id:uuid,
            role:BUSINESS_ACCESS.ADMIN
        };
        let businessListObject={};
        businessListObject[uuid] = businesAccess;
        return businessListObject;
    }

    signUpFirebaseUser=async(firebaseUser)=>{
        let uuid = firebaseUser.uid;
        let defaultBusiness = this.buildDefaultBusiness(firebaseUser.uid);
        let businessList = this.buildBusinessAccessList(defaultBusiness);
        var user={
             username:      firebaseUser.uid
            ,email:         firebaseUser.email
            ,uuid:          uuid
            ,providerName:  "password"
            ,business:      businessList
        }; 
        defaultBusiness.email = user.email;
       
        let userPath = USERS_TABLE+'/'+uuid;
        let businessPath = BUSINESS_TABLE+'/'+defaultBusiness.id;
        const updates = {};
        updates[userPath] = user;
        updates[businessPath] = defaultBusiness;


        return update(ref(db), updates);
    }

    getBusiness = async (businessId, callback) => {
        get(child(dbRef, `business/${businessId}`)).then((snapshot) => {
            if (snapshot.exists()) {
                let business = snapshot.val();
                business.id = businessId; 

                if(business.menu!=null && business.menu.items!=null &&  business.menu.category!= null){
                    business.menu.items = Object.values(business.menu.items);
                    //enrich category name
                    business.menu.items.forEach((item,idx)=>{
                        business.menu.items[idx].categoryName = business.menu.category[item.category];
                    });
                }

                callback(business);
            } else {
                console.log("No business found for id:" + businessId);
                callback(null);
            }
            }).catch((error) => {
                console.log(error);
            }); 
    }

    findOrders=async(businessId,startDate,endDate,successCallback,errorCallback)=> { 
        const ordersRef = ref(getDatabase(), '/orders/' + businessId);
        const orderQuery = query(ordersRef, orderByKey(''), startAt(startDate+""),endAt(endDate+""));
        get(orderQuery).then((snapshot) => {
            if(snapshot.exists()){
                successCallback(snapshot.val());
            }else{
                successCallback([]);
            }
        }).catch((errorista)=>{
            errorCallback(errorista);
        });
    }

    getOrderByOrderNumber=async(businessId,orderNum,callback)=> {
        get(child(dbRef, `orders/${businessId}/${orderNum}`)).then((snapshot) => {
            if (snapshot.exists()) {
                callback(snapshot.val())
            } else {
                callback(null);
            }
            }).catch((error) => {
                console.error(error);
                callback(null);
        });

    }

    trimMenuItem=(items)=>{
        let trimmedItems = [];
        items=items.filter(it=>it.qty>0);
        items.forEach(item=>trimmedItems.push(this.trim(item)));
        return trimmedItems;
    }

    trim=(item)=>{
        return {
            name:item.item.name
           ,price:item.item.price
           ,cost:item.item.cost
           ,qty:item.qty
           ,id:item.item.id
           ,variantId:item.item.variantId!=null?item.item.variantId:null
           ,amount:item.qty * item.item.price
        }
    }
   
    saveOrder = async (businessId, orderInfo, onSucessCallback) => {  
        let path = 'orders/'+businessId;
        const newOrderKey = orderInfo.orderNumber;
        orderInfo.uuid = Util.uuidv4();
        const updates = {};


        //cleanup - start
        if(orderInfo.discount.type==null){
            orderInfo.discount=null;
        }
        orderInfo.remainingAmountDue=null;

        if(orderInfo.cashPayment.cashReceived===0){
            orderInfo.cashPayment=null;
        }else{
            orderInfo.payments.push(orderInfo.cashPayment)
        }
        //cleanup - end



        //UPDATE INVENTORY STOCK ---- start
        let itemsWithTrackStock= orderInfo.items.filter(it=>it.item.trackStock && it.qty>0);

        itemsWithTrackStock.forEach((itemOrder)=>{
            let itemId = itemOrder.item.id;
            if(itemOrder.item.variantId!=null){// use variant as itemId if its a variant
                itemId=itemOrder.item.variantId;
            }
            let invetoryStockPath = 'inventory/'+businessId+'/stock/items/'+itemId;
            update(child(ref(db), invetoryStockPath),
            {
                inStock: increment(-itemOrder.qty),
                deducted: increment(itemOrder.qty)
            });
        });
        //UPDATE INVENTORY STOCK ---- end


        //UPDATE DRAWER  ---- start
        if(orderInfo.cashPayment!=null){
            let today = new Date(new Date().setHours(0,0,0,0));
            let drawerPath = 'drawer/'+businessId+'/'+today.valueOf();
            update(child(ref(db), drawerPath),
            {
                sales: increment(orderInfo.cashPayment.amount)
            });

            updates[drawerPath + '/payments/' +newOrderKey] = { 
                orderUuid:orderInfo.uuid,
                orderId:newOrderKey,
                amount:orderInfo.cashPayment.amount,
                date:orderInfo.date,
                cashReceived:orderInfo.cashPayment.cashReceived
            }; 
        }
        //UPDATE END  ---- start


        orderInfo.items=this.trimMenuItem(orderInfo.items);
        updates[path + '/' + newOrderKey] = orderInfo;

        return update(ref(db), updates);
    }

    addMenuCategory = async (businessId,  categoryName, onSuceesCallback, onErrorCallback) => {  
        let path = 'business/'+businessId+'/menu/category';
        const newCategoryKey = push(child(ref(db), path)).key;

        const updates = {};
        updates[path + '/' + newCategoryKey] = categoryName;
      
        update(ref(db), updates).then(()=>{
            onSuceesCallback(categoryName,newCategoryKey);
        })
        .catch((error) => {
            console.log('saved failed',error);
            onErrorCallback();
        });
    }

    editMenuCategory = async (businessId, categoryId, newCategoryName) => {  
        let path = 'business/'+businessId+'/menu/category/'+categoryId; 

        const updates = {};
        updates[path] = newCategoryName;
        return update(ref(db), updates);
    }

    deleteMenuCategory = async (businessId, categoryId) => {  
        let path = 'business/'+businessId+'/menu/category/'+categoryId; 

        const updates = {};
        updates[path] = null;
        return update(ref(db), updates);
    }

/**
  * @param businessId 
  * @param name 
  * @param inventoryType 
  * @param phone 
  * @param email
  */
    updateBusiness = async (
            businessId
            , name
            , inventoryType
            , phone
            , email
            , address
        ) => {  
        let path = 'business/'+businessId; 

        const updates = {};
        if(name!=null){ updates[path+'/name'] = name; }
        if(inventoryType!=null){ updates[path+'/inventoryType'] = inventoryType; }
        if(phone!=null){ updates[path+'/phone'] = phone; }
        if(email!=null){ updates[path+'/email'] = email; }
        if(address!=null){ updates[path+'/address'] = address; }

        updates[path+'/lastUpdated'] = (new Date()).toISOString();
        return update(ref(db), updates);
    }


    updateReceiptDetails = async (businessId, receipt) => {  
        if(receipt==null || businessId==null) return null;
        let path = 'business/'+businessId+'/receipt'; 

        const updates = {};
        if(receipt.ownerName!=null){ updates[path+'/ownerName'] = receipt.ownerName; }
        if(receipt.message!=null){ updates[path+'/message'] = receipt.message; }
        if(receipt.permit!=null){ updates[path+'/permit'] = receipt.permit; }
        if(receipt.nonVatTin!=null){ updates[path+'/nonVatTin'] = receipt.nonVatTin; }
        if(receipt.vatTin!=null){ updates[path+'/vatTin'] = receipt.vatTin; }
        if(receipt.min!=null){ updates[path+'/min'] = receipt.min; }
        if(receipt.sn!=null){ updates[path+'/sn'] = receipt.sn; }

        updates[path+'/lastUpdated'] = (new Date()).toISOString();
        return update(ref(db), updates);
    }

    saveUserSettings = async (userId, businessId, settings) => {  
        let path = 'users/'+userId+'/business/'+businessId+'/settings'; 
        console.log(path,settings);
        const updates = {};
        updates[path] = settings;
        return update(ref(db), updates);
    }
    
    getUserSettings = async (userId, businessId) => {  
        let path = 'users/'+userId+'/business/'+businessId+'/settings'; 
        return get(child(dbRef,path));
    }


}


export default new FirebaseService()