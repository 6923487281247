import React, { Component } from 'react';
import moment from "moment";
import InfoDiv from './InfoDiv';
import Util from '../../util/Util';
import {isMobile} from 'react-device-detect';
import Checkbox from '@mui/material/Checkbox';
import SettingsService from './SettingsService';
import FormGroup from '@mui/material/FormGroup';
import ProductSettings from './ProductSettings'; 
import NotificationUtil from '../../util/NotificationUtil';
import FirebaseService from '../../service/FirebaseService';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import {INVENTORY_TYPE,PAYMENT_METHODS} from '../../constants';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {BUSINESS_NAME_LIMIT,BUSINESS_ADDRESS_LIMIT,EMAIL_LIMIT} from '../../constants';

class GeneralSettings extends Component {
    render(){
        return(
            <div className='main-settings-page general-settings' style={{overflow:'hidden'}}>
                <BusinessSettings exchange={this.props.exchange}/>
                <CheckoutSettings exchange={this.props.exchange}/>
                <ProductSettings exchange={this.props.exchange}/>
            </div>
        )
    }
}

class CheckoutSettings extends Component{

    constructor(props) {
        super(props);
        let businessPaymentMethods = this.props.exchange.storedSession.business.paymentMethods!=null?
                                        this.props.exchange.storedSession.business.paymentMethods:{}

        Object.values(PAYMENT_METHODS).forEach(pm=>{
            businessPaymentMethods[pm.id] = {
                id:pm.id,
                label:pm.label,
                enabled: businessPaymentMethods[pm.id]!=null? businessPaymentMethods[pm.id].enabled:false,
                index:pm.index
            }
        })
        SettingsService.getPaymentMethods().then((snapshot) => {
            if (snapshot.exists()) {
                let globalPaymentMethods = snapshot.val() 
                let transformedPayMethod = {};
                Object.values(globalPaymentMethods).forEach(pm=>{
                    let bPm = businessPaymentMethods!=null?businessPaymentMethods[pm.id]:null;
                    transformedPayMethod[pm.id] = {
                        id:pm.id,
                        label:pm.label,
                        available:pm.enabled,
                        index:PAYMENT_METHODS[pm.id].index,
                        enabled:(bPm!=null&&bPm.enabled!=null)?bPm.enabled:false
                    }
                });
                this.setState({onLoad:false,paymentMethods:transformedPayMethod})
            }
        }).catch((error) => {
            console.log(error)
        })

        this.state = {
            onLoad:true,
            paymentMethods:businessPaymentMethods
        }
    }

    savePayMethods=async()=>{
        let businessPaymentMethods = this.state.paymentMethods;//reduce global variables
        let transformedPayMethod = businessPaymentMethods;
        Object.values(businessPaymentMethods).forEach(pm=>{
            transformedPayMethod[pm.id] = {
                id:pm.id,
                enabled:pm.enabled
            }
        })


        SettingsService.updatePaymentMethods(this.props.exchange.storedSession.business.id, transformedPayMethod)
            .then(()=>{
                this.props.exchange.refreshCache();
            }).catch(err=>{
                console.error('GS0001',err);
            })
    }

    renderPayMethods=()=>{
        let methodCheckbox=[]; 
        let paymentMethods =Object.values(this.state.paymentMethods);
            paymentMethods = paymentMethods.sort(function(o1,o2){return o1.index<o2.index ? -1 :  o1.index>o2.index ? 1 : 0;});
        
        paymentMethods.forEach((payMethod,idx)=>{
            let isAvailable = payMethod.available|true;
            methodCheckbox.push(
                <div style={{display:'flex',alignItems:'center'}}>
                <FormControlLabel  sx={{}}
                    control={
                    <Checkbox 
                        color="secondary"
                        checked={payMethod.enabled}
                        inputProps={{ tabIndex:-1 }}
                        onChange={()=>{
                            let paymentMethods=this.state.paymentMethods
                            paymentMethods[payMethod.id].enabled=!payMethod.enabled
                            this.setState({paymentMethods:paymentMethods},this.savePayMethods)
                        }}/>} 
                        label={payMethod.label} />
                {!isAvailable&&
                    <p style={{fontSize:'10px',backgroundColor:'rgb(157,39,176)',color:'white',padding:'4px',borderRadius:'4px'}}>
                        Not available</p>}
                    
                </div>
                    
            )
        });
        return (
            <div className='pay-methods sblock'>
                <p className='c-in1'>Payment methods</p>
                <div>
                    <FormGroup>
                    {methodCheckbox}
                    </FormGroup>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className={'checkout-settings subsettings '+(isMobile?' subsettings-mobile':'')}>
                <p class='c-h4'>Checkout Settings</p>
                {/* <p class='c-p10' style={{textAlign:'left'}}>Last updated: {moment(lastUpdate).format('lll')}</p> */}
                <div>
                {this.renderPayMethods()}

                </div>
            </div>
        )
    }
}
class BusinessSettings extends Component{

    constructor(props) {
        super(props);
        let business = this.props.exchange.storedSession.business;

        this.state = {
            business:{
                name:business.name
                ,address:business.address
                ,email:business.email
                ,phone:null
                ,inventoryType:business.inventoryType
            },
 
            errInputs:'',
            onLoad:false
        }
        this.initial = {
            business: Object.assign({}, this.state.business)
        }
    }

    onSaveClicked=()=>{
        this.setState({onLoad:true},()=>{
            let errorMsg = '';
            let errInputs ='';
            if(Util.checkIfEmailValid(this.state.business.email)===false){
                errorMsg='Invalid email address';
                errInputs+=' email';
            }
            if(errorMsg!=='' || errInputs!==''){
                this.setState({onLoad:false,errorMsg:errorMsg, errInputs:errInputs})
            }else{
                this.updateBusinessInfo();
            }
        });
    }

    updateBusinessInfo=()=>{

        let businessId = this.props.exchange.storedSession.business.id;
        let name = this.state.business.name;
        let inventoryType = this.state.business.inventoryType;
        let phone = this.state.business.phone;
        let email = this.state.business.email;
        let address = this.state.business.address;
        try{ 
        FirebaseService.updateBusiness(
              businessId
            , name
            , inventoryType
            , phone
            , email
            , address
            )
            .then(()=>{
                NotificationUtil.makeToast('Business info updated','rgb(77,85,87)','white',3000,'top-right');
                this.initial ={business: Object.assign({}, this.state.business)};
                this.props.exchange.refreshCache();
            }).catch((err)=>{
                console.error('SPE:0001',err);
                this.setState({onLoad:false,errorMsg:'Something went wrong. Please try again later.'});
            });

        } catch(error){

            console.log('error',error);
        }
    }

    changeInputHandler=(event)=>{
        let business = this.state.business;
        let errInputs = this.state.errInputs;
        switch(event.target.id){
            case 'name-input': 
                if(event.target.value.length<BUSINESS_NAME_LIMIT){
                    business.name = event.target.value;
                }
            break;
            case 'address-input': 
                if(event.target.value.length<BUSINESS_ADDRESS_LIMIT){
                    business.address = event.target.value;
                }
            break;
            case 'email-input':     
                if(event.target.value.length<EMAIL_LIMIT){
                    business.email = event.target.value;
                    errInputs=errInputs.replace('email','');
                }
            break;
            
        default:
                break;
        }
        this.setState({business:business,errInputs:errInputs,errorMsg:''});
    }

    setInventoryType=(newType)=>{
        let business = this.state.business;
        business.inventoryType = newType;
        this.setState({business:business});
    }

    inputValid=()=>{ 
        return this.state.business.name!==''
            && this.state.business.address!=='' 
            && this.state.business.email!=='';

    }

    render() {
        let lastUpdate = new Date(this.props.exchange.storedSession.business.lastUpdated);
        let canSave = JSON.stringify(this.initial.business)!==JSON.stringify(this.state.business) && this.inputValid();
        return (
                    <div class={'business-settings subsettings '+(isMobile?' subsettings-mobile':'')} style={{pointerEvents:this.state.onLoad?'none':''}}>
                        <p class='c-h4'>Business Information</p>
                        <p class='c-p10' style={{textAlign:'left'}}>Last updated: {moment(lastUpdate).format('lll')}</p>
                        <div class='partitions'>
                            <div class='part1'>
                                <InfoDiv id='name-input' title='Business name:' value={this.state.business.name}      onChange={this.changeInputHandler}/>
                                <InfoDiv id='address-input' title='Address'      value={this.state.business.address} rows={3}  onChange={this.changeInputHandler}/>
                                <InfoDiv id='email-input' title='Business Email'  error={this.state.errInputs.includes('email')} value={this.state.business.email}  onChange={this.changeInputHandler}/>
                                <div class='inventory-type-select'>
                                    <p class='c-in1'>Inventory type</p>
                                    <InventoryTypeButton 
                                        label={"Retail"}
                                        description={"For Mini Grocery, Pharmacy, or any kind of store"}
                                        onClick={()=>{this.setInventoryType(INVENTORY_TYPE.RETAIL)}}
                                        highlighted={this.state.business.inventoryType===INVENTORY_TYPE.RETAIL}/>
                                    <InventoryTypeButton
                                        label={"Ingredients Based"} 
                                        description={"For Restaurant, Cafe, Milk Tea Shop (or any Food Business!)"}
                                        onClick={()=>{this.setInventoryType(INVENTORY_TYPE.INGREDIENTS_BASED)}}
                                        highlighted={this.state.business.inventoryType===INVENTORY_TYPE.INGREDIENTS_BASED}/>
                                    <InventoryTypeButton
                                        label={"Service Based"} 
                                        description={"For Salons, Barbershops, Clinics, or any other Services"}
                                        onClick={()=>{this.setInventoryType(INVENTORY_TYPE.SERVICE_BASED)}}
                                        highlighted={this.state.business.inventoryType===INVENTORY_TYPE.SERVICE_BASED}/>
                                </div>
                            </div>
                            <div class='part2'>
                            </div>
                        </div>
                        <br/>
                         <p style={{color:'red',textAlign:'center'}}>{this.state.errorMsg}</p>
                         <br/>
                        <div class='control-btns'>
                            <button class={'btn1 b100 ' + (canSave?'':' -btn1-disabled')} onClick={this.onSaveClicked}>
                                {this.state.onLoad?<CircularProgress sx={{}}/>:"Save"}
                            </button>
                        </div>
                    </div>
        );
    }
}

class InventoryTypeButton extends Component {

    render() {
        return (
            <div class='inv-type-btn' 
                onClick={this.props.onClick}
                style={this.props.highlighted?{backgroundColor:'rgb(242, 238, 255)' ,color:'rgb(66,43,163)'}:{}}>
                <div style={{display:'flex'}}>
                    <div style={{marginRight:'10px',marginTop:'8px'}} >
                        {this.props.highlighted?<RadioButtonCheckedIcon/>:<RadioButtonUncheckedIcon/>}
                    </div>
                    <div>
                        <p class='c-h5'>{this.props.label}</p>
                        <p class='c-p2'>{this.props.description}</p>
                    </div>
                </div>
            </div>
        );
    }
}


export default GeneralSettings;