
export const DISCOUNT_TYPE=Object.freeze({PERCENTAGE:'PERCENTAGE',FIXED:'FIXED'});
export const DISCOUNT_TYPE_ID=Object.freeze({SENIOR:'SENIOR',PWD:'PWD',CUSTOM_PERC:'CUSTOM'});

export const SENIOR_DISCOUNT_PERC = 20;
export const PWD_DISCOUNT_PERC = 20;

class CartService {

    buildOrderInfo=(payments, discountState, cashReceived)=>{
        
        let cartItems =     this.getCartItems();
        let subTotalPrice = this.getTotalPrice(cartItems); // total price for all items
        let discount =      this.getDiscount(discountState, subTotalPrice);// get discount objecta 
        let totalPrice = subTotalPrice;
        if(discount.type!=null){
            totalPrice-=discount.amount;
        }

        let totalPaymentAmount = payments.map(pay=>pay.amount).reduce((partialSum, a) => partialSum + a, 0);
        let remainingAmountDue =  totalPrice-totalPaymentAmount

        let change = cashReceived-remainingAmountDue;
       
        let cashPayment = {
            type:'cash',
            cashReceived:cashReceived,
            amount:cashReceived<remainingAmountDue?cashReceived:remainingAmountDue,
            change:change
        }   
        
        return {
            date:new Date().toISOString(),
            orderNumber:new Date().valueOf(),
            subTotalPrice:subTotalPrice,
            discount:discount,
            totalPrice:totalPrice,
            remainingAmountDue:remainingAmountDue,
            items:cartItems,
            payments:payments,
            cashPayment:cashPayment
        };
    }

    getDiscount=(discount, totalPrice)=>{
        let amount = 0;
        let label=null;
        let roundedAmount=0;
        if(discount!=null){
            switch(discount.type){
                case DISCOUNT_TYPE.PERCENTAGE: 
                    amount=totalPrice*(discount.value/100); 
                    roundedAmount= Math.round((amount + Number.EPSILON) * 100) / 100;
                    label="₱"+this.numberWithCommas(roundedAmount)+' ('+discount.value+'%)' 
                     + (discount.id!==''?'('+ discount.id+')':'');
                    break;
                case DISCOUNT_TYPE.FIXED:      
                    amount=discount.value; 
                    roundedAmount= Math.round((amount + Number.EPSILON) * 100) / 100;
                    label="₱"+this.numberWithCommas(roundedAmount)
                    + (discount.id!==''?'('+ discount.id+')':'');
                    break;
                default:break;
            }
        }

        return {
            id:discount.id,
            amount:amount,
            discount:discount.value,
            type:discount.type,
            label:label
        };
    }

    numberWithCommas=(x)=>{
        x = Math.round((x + Number.EPSILON) * 100) / 100;
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    getTotalPrice=(cart)=>{
        let total = 0;
        cart.forEach((cartEntry)=>{
            total+=cartEntry.item.price * cartEntry.qty;
        });
        return total;
    }

    getOrderInfo=()=>{
        let orderInfo;
        let orderInfoRaw=localStorage.getItem("ORDER_INFO");
        if(orderInfoRaw!=null){
            orderInfo = JSON.parse(orderInfoRaw);
        }
        return orderInfo;
    }

    getCartItems=()=>{
        let cartItems =[];
        let orderInfoRaw=localStorage.getItem("ORDER_INFO");
        if(orderInfoRaw!=null){
            let orderInfo = JSON.parse(orderInfoRaw);
            cartItems = orderInfo.items;
        }
        return cartItems;
    }

    saveCartItems=(cartItems,callback)=>{
        let orderInfo = {
            items:cartItems
        };
        localStorage.setItem("ORDER_INFO", JSON.stringify(orderInfo));
        if(callback!=null){
            callback();
        }
    }

    deleteOrderItemByIndex=(index, callback)=>{
        let cartItems = this.getCartItems()
        cartItems.splice(index,1)
        this.saveCartItems(cartItems,callback)
    }
  
    clearCartItems=(callback)=>{
        this.saveCartItems([],callback)
    }
  
    saveOrderInfo=(orderInfo)=>{
        localStorage.setItem("ORDER_INFO", JSON.stringify(orderInfo));
    }
  
  
    removeOrderInfo=()=>{
        localStorage.removeItem("ORDER_INFO");
    }
  
}

export default new CartService()