
import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import { isMobile } from 'react-device-detect';
class VirtualCashInput extends Component{

    constructor(props) {
        super(props);
        this.state={
            amount:this.props.amount!=null?this.props.amount:''
        }
    }

    render(){

        let VirtualNumpad = (props)=>{
            let active = props.cond==null?true:props.cond;
            let onClick = ()=>{
                if(this.state.amount<9999999||this.state.amount==='.'){
                    let nIn = this.state.amount+props.val;
                    if(isNaN(nIn)&&nIn.startsWith('.')){
                        nIn='0'+nIn;
                    } else if(nIn.startsWith('00.')){
                        nIn=nIn.replace('00.','0.');
                    } 
                    this.setState({amount:nIn})
                }
            }

            return(
                <button className={'btn1 btn-second virtual-numpad ' +(active?'':'btn-second-disabled')} style={{fontSize:isMobile?'1rem':''}} onClick={onClick}>{props.val}</button>
            )
        }
        return(
            <div className={'virt-cash-in ' + isMobile?'window-fullscreen':'window'}>
                <div className='gwrap' style={{textAlign:'center'}}>
                    <h3>Enter Amount Received</h3>
                    <TextField
                        required
                        id="custom-cash-input"
                        type="telephone"
                        variant="standard"
                        value={this.state.amount}
                        inputProps={{ tabIndex:-1 }}
                        sx={{marginLeft:'14px', input: { color: 'black',fontSize:isMobile?'1.4rem':'2rem',textAlign:'center'},pointerEvents:'none' }}
                    />
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <div style={{marginTop:'6px',width:'fit-content'}}>
                            <div style={{display:'flex',justifyContent:'center'}}> 
                                <VirtualNumpad val={7}/>
                                <VirtualNumpad val={8}/>
                                <VirtualNumpad val={9}/>

                                <button className='btn1 btn-second virtual-numpad' style={{width:'',fontSize:isMobile?'1rem':''}}
                                onClick={()=>this.setState({amount:this.state.amount.slice(0, -1)})}>{'Del'}</button>
                            </div>
                            <div style={{display:'flex',justifyContent:'center'}}> 
                                <VirtualNumpad val={4}/>
                                <VirtualNumpad val={5}/>
                                <VirtualNumpad val={6}/>
                                <button className='btn1 btn-second virtual-numpad' style={{fontSize:isMobile?'1rem':''}}
                                onClick={()=>this.setState({amount:''})}>{'Clear'}</button>
                            </div>
                            <div style={{display:'flex',justifyContent:'center'}}> 
                                <VirtualNumpad val={1}/>
                                <VirtualNumpad val={2}/>
                                <VirtualNumpad val={3}/>
                                <VirtualNumpad val={'.'}/>
                            </div>

                            <div style={{display:'flex',justifyContent:'flex-start'}}> 
                                <VirtualNumpad val={0} cond={this.state.amount!='0'}/>
                                <VirtualNumpad val={'00'} cond={Number(this.state.amount)>0}/>

                                <button className='btn1 btn-second virtual-numpad' style={{width:'100%',fontSize:isMobile?'1rem':''}}
                                
                            onClick={()=>this.props.setCustomAmountInput(this.state.amount)}>{'Okay'}</button>
                                
                            </div>


                            <div style={{display:'flex'}}> 
                            </div>
                        </div>
                    </div>

                    <p className='link-1' style={{color:'gray',marginTop:'10px'}} onClick={this.props.onCancel}>Cancel</p>

                </div>
            </div>
        )
    }

}

export default VirtualCashInput;