import React, { Component } from 'react';
import 'animate.css';
import OrderTotal from './../order/OrderTotal';
import TextField from '@mui/material/TextField';
import {isMobile,isMobileOnly} from 'react-device-detect';
import IconButton from '@mui/material/IconButton';
import {PAYMENT_METHODS} from '../../../constants';
import DeleteIcon from '@mui/icons-material/Delete';
import CartService from '../../../service/CartService';
import './Cart.css';

class Cart extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    pesoWithCommas=(x)=>{
        let roundedX= Math.round((x + Number.EPSILON) * 100) / 100;
        return '₱'+roundedX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    changeInputHandler=(event,inStock)=>{
        if(event.target.id.includes('qty-input-')){
            let itemIndex = Number(event.target.id.replace('qty-input-','')); 
            if(!isNaN(event.target.value)){
                let newVal = Number(event.target.value);
                if(newVal>0 && (inStock==null || newVal<=inStock)){
                    let cartItems = CartService.getCartItems();
                    cartItems[itemIndex].qty = newVal;
                    CartService.saveCartItems(cartItems);
                }

            }
        }
    }

    qtyDecreaseClicked=(idx)=>{
        let cartItems = CartService.getCartItems();
        if(cartItems[idx].qty>1){
            cartItems[idx].qty = cartItems[idx].qty-1;
            CartService.saveCartItems(cartItems, this.props.parentUpdate);
        }
    }
    
    qtyIncreaseClicked=(idx)=>{
        let cartItems = CartService.getCartItems();
        cartItems[idx].qty = cartItems[idx].qty+1;
        CartService.saveCartItems(cartItems, this.props.parentUpdate);
    } 

    render() {
        let orderDivs=[]; 
        let items = CartService.getCartItems();
        items.forEach((cartEntry,idx)=>{
            let itemId = cartEntry.item.variantId==null? cartEntry.item.id : cartEntry.item.variantId;
            let inStock = null;
            if(cartEntry.item.trackStock){
                if(this.props.inventory!=null && this.props.inventory.stock!=null && this.props.inventory.stock.items[itemId]!=null){
                    inStock = this.props.inventory.stock.items[itemId].inStock | 0;
                } else {
                    inStock=0;
                }
            }
            orderDivs.push(
                <div className='reg-order-item' style={cartEntry.qty===0?{backgroundColor:'rgb(248, 248, 248)',opacity:'0.5'}:{}}>
                    <div class='rc-1'>
                        <p>{cartEntry.item.name}</p>
                        <p class={'stock-count-div-'+itemId} style={{fontSize:'0.75rem',fontWeight:'400'}}>{(inStock!=null?'Stock: ' + inStock:'')}</p>
                    
                    </div>
                    <div class='rc-2' style={{display:'flex',alignItems:'center'}}>
                        <div class='qty-setter'>
                            <button class='qty-set-btn' onClick={()=>this.qtyDecreaseClicked(idx)} tabIndex={-1}>-</button>
                            <TextField
                                id={'qty-input-'+idx}
                                value={cartEntry.qty}
                                type="tel"
                                sx={{width:'fit-content',input: {textAlign:'center',fontSize:'0.85rem'}}}
                                variant="standard"
                                inputProps={{ tabIndex:-1 }}
                                onChange={(e)=>this.changeInputHandler(e,inStock)}
                                /> 
                            <button class='qty-set-btn' tabIndex={-1}
                             onClick={()=>{
                                if(inStock==null || cartEntry.qty+1<=inStock){
                                    this.qtyIncreaseClicked(idx);
                                }else{
                                    const stockCountElement = document.querySelector('.stock-count-div-'+itemId);
                                    if(stockCountElement!=null){
                                        stockCountElement.classList.add('animate__animated', 'animate__headShake','animate__faster');
                                        stockCountElement.addEventListener('animationend', () => {
                                            stockCountElement.classList.remove('animate__animated', 'animate__headShake');
                                        });
                                    }
                                }
                                }}>+</button>
                        </div>
                    </div>
                    <div class='rc-3'><p>{this.pesoWithCommas(cartEntry.item.price)}</p></div>
                    <div class='rc-4'><p class='or-1' style={{padding:'0px'}}>{this.pesoWithCommas(cartEntry.item.price * cartEntry.qty)}</p></div>
                    <div class='rc-5'>
                    <IconButton aria-label="delete" sx={{padding:'3px'}}  tabIndex={-1}
                        onClick={()=>CartService.deleteOrderItemByIndex(idx,this.props.parentUpdate)}>
                        <DeleteIcon/>
                    </IconButton>
                    </div>            
                </div>
            );
        });

        let otherPaymentDiv =[];
        if(this.props.orderInfo!=null&&this.props.orderInfo.payments!=null){
            this.props.orderInfo.payments.forEach(pay=>{
                otherPaymentDiv.push(
                    <div className='order-info additional-pay'>
                        <p style={{fontWeight:'600'}}>{PAYMENT_METHODS[pay.type].label}</p><p>{'-'+this.pesoWithCommas(pay.amount)}</p>
                    </div>
                )
            }) 
        }
        return (
            
            <div className='cart-comp' style={this.props.floating?{width:isMobileOnly?'94vw':'60vw', minWidth:isMobileOnly?'240px':'min(500px, 92vw)',maxWidth:isMobileOnly?'':'660px', height:isMobile?'75vh':'85vh'}:{}}> 

                <div className='cart-header'  style={this.props.floating?{padding:'12px 4px 10px 4px'}:{}} >
                    <div class='rc-1'>Item</div>
                    <div class='rc-2'><p style={{width:'100%'}}>Qty</p></div>
                    <div class='rc-3'>Price</div>
                    <div class='rc-4'>Subtotal</div>
                    <div class='rc-5'> </div>
                </div>

                <div class='cart-div-wrapper' style={{height:'100%',overflow:'hidden',overflowY:'scroll'}}>
                    <div class='order-div'>
                        {orderDivs}
                        {/* <div>
                            {otherPaymentDiv}
                        </div> */}
                    </div>

                    {orderDivs.length===0&&
                    <div class='centerItem' style={{fontSize:'0.9rem',textAlign:'center'}}>
                        <div class='ci-wrapper'>
                            <p style={{marginBottom:'8px',opacity:'0.5'}}>No items yet</p>
                        </div>
                    </div>}
                </div>

                <OrderTotal
                    items={items}
                    orderInfo={this.props.orderInfo}
                    onCancel={this.props.onCancel}
                    onCancelLink={this.props.onCancelLink}
                    showCheckout={this.props.showCheckout}
                    actionDiv={this.props.actionDiv}
                    />
                
            </div>
        );
    }
}

export default Cart;