import React, { Component } from 'react';

class MessageDiv extends Component {
    render() {
        return (
            <div className='centerItem' style={{textAlign:'center',padding:'30vh 0 30vh 0'}}>
                <div>
                    <p style={{opacity:'0.5'}}>{this.props.message}</p>
                </div>
             </div>
        );
    }
}

export default MessageDiv;