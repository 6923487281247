import React from 'react';
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue } from "firebase/database";
import './App.css';
import {isTablet, isDesktop} from 'react-device-detect';
import {BOTTOM_BAR_HEIGHT,LOGIN_STATUS} from './constants.js';
import history from './history';
import {Helmet} from "react-helmet";
import { Tooltip } from 'react-tooltip';
import { Routes, Route } from "react-router-dom";
import HeaderComp from './components/HeaderComp';
import SideBar from './components/SideBar';
import BottomBar from './components/BottomBar';
import LoginSignupModal from './pages/LoginSignupModal';
import PrivacyPolicy from './pages/others/PrivacyPolicy';
import CookiePolicy from './pages/others/CookiePolicy';
import WelcomeModal from './pages/windows/WelcomeModal';
import HomePage from './pages/home/HomePage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageNotFound from './PageNotFound';

import AuthService from './service/AuthService';
import FirebaseService from './service/FirebaseService';
import CacheService from './service/CacheService';
import SubscriptionService from './service/SubscriptionService';
import AuthRouter from './AuthRouter';
import SubscriptionPage from './pages/subscription/SubscriptionPage';
import WaitPage from './pages/waiting/WaitPage';

const auth = getAuth();
const db = getDatabase(); 

const ENV = process.env.REACT_APP_BUILD_ENV==='production'?'prod':'dev';
export const MODAL= Object.freeze({
  WELCOME_SETUP:'WELCOME_SETUP'
});
class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        storedSession:CacheService.getSession()
      , subscription:null 
      , firebaseUser:null
      , showLoginModal:false
      , showSignupModal:false
      , loginStatus:LOGIN_STATUS.NONE
      , modal:null
      , onAuth:true
      , noInternet:false
    }
  }

  async componentDidMount(){
    
    window.scrollTo(0, 0);
    this.authenticateUser(); 

    const connectedRef = ref(db, ".info/connected");
    onValue(connectedRef, (snap) => {
      if (snap.val() === true) {
        this.setState({noInternet:false});
      } else {
        this.setState({noInternet:true});
      }
    });
  }

  async authenticateUser(){
    try{ 
      await this.authenticateWithFirebase()
    }catch(error){
        console.log('authenticateUser error',error)
        this.setState({onAuth:false});
    }
  }
  
  getSubscription=async()=>{
      let subscription = await SubscriptionService.getUserSubscription()
      this.setState({subscription:subscription})
  }

  setCashrUser=(firebaseUser,user,business)=>{
    CacheService.storeSession(user, business, ()=>{
        this.setState({firebaseUser:firebaseUser, storedSession:CacheService.getSession()},()=>{
            if(business.lastUpdated==null || business.lastUpdated==='-'){
                this.setState({modal:MODAL.WELCOME_SETUP,onAuth:false});
            }else{
              this.setState({onAuth:false});
            }
        });
    });
  }

  getCashrUserAndBusiness = async (firebaseUser) => {
    let uuid = firebaseUser.uid;
    FirebaseService.getUser(uuid).then(async(snapshot)=>{
      if (snapshot.exists()) {
          let user = snapshot.val();
          let businesses = Object.values(user.business);
          FirebaseService.getBusiness(businesses[0].id,(busineszz)=>{
              this.setCashrUser(firebaseUser,user,busineszz);
          });
      } else {
        this.setState({onAuth:false});  
        console.log('Authenticated user not registered')
      }
    });
  }

  refreshCache=async()=>{
    auth.onAuthStateChanged(async(firebaseUser)=>{
        await this.getCashrUserAndBusiness(firebaseUser);
    });
  }

  authenticateWithFirebase=async()=>{
    auth.onAuthStateChanged(async(firebaseUser)=>{

      if (firebaseUser!=null && firebaseUser.emailVerified) {
          // use stored session when already authenticated
        // if(this.state.storedSession==null || this.state.storedSession.user==null || this.state.storedSession.business==null){

          await this.getSubscription()
          await this.getCashrUserAndBusiness(firebaseUser);
        // } else {
        //   this.setState({firebaseUser:firebaseUser, onAuth:false});
        // }
      }else{
        this.setState({onAuth:false});
      }
    });
  }


  setLoginStatus=(newStatus)=>{
    this.setState({loginStatus:newStatus});
  }

  renderModals=(exchange)=>{
    switch (this.state.modal) {
      case MODAL.WELCOME_SETUP: 
        return <WelcomeModal 
                  exchange={exchange}
                  onClose={()=>{this.setState({modal:null})}}/>;
    
      default: return null; 
    }
  }


  render(){
    let onPos=AuthService.canAccessPos(this.state.firebaseUser,this.state.storedSession);

    let hasSideBar = isDesktop || isTablet;
    let exchange = {
        storedSession:this.state.storedSession
      , subscription:this.state.subscription 
      , firebaseUser:this.state.firebaseUser
      , refreshCache:this.refreshCache
      , onPos:onPos
      , hasSideBar: hasSideBar
      , noInternet: this.state.noInternet
    };

    let onAuth = this.state.onAuth;
    let preventScroll = this.state.modal!==null || this.state.loginStatus!==LOGIN_STATUS.NONE;
    if(preventScroll){
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }

  return(
      <div className="pos-web" id='pos-web' style={{minHeight:'100vh'}}>
            {/* {ENV!=='prod'&&<p style={{color:'white',fontWeight:'600', backgroundColor:'rgb(35, 132, 93)',margin:'0px 0px',padding:'4px',textAlign:'center'}}>{ENV}</p>} */}
            {/* <button onClick={()=>{
             FirebaseService.signUpFirebaseUser(this.state.firebaseUser).then(res=>{
                console.log('Success signUpFirebaseUser')
             })
            }}>
              Test create user
            </button> */}
            <ToastContainer enableMultiContainer containerId={'AppToaster'} /> 
            <Tooltip id="app-tooltip"/>
            <Helmet>
                 <meta charSet="utf-8" />
            </Helmet>

            {onAuth===false &&
            <div className='pos-web-wrapper' style={{display:'flex',minHeight:'100%'}}>
              <div className='main-controller' style={{backgroundColor:'rgb(29,24,89)', width:'100%',height:'100%',minHeight:hasSideBar?'100vh':'calc(100vh - ' + BOTTOM_BAR_HEIGHT +'px)',display:hasSideBar?'flex':'grid',alignContent:'space-between'}}>
              
              {(onPos&&hasSideBar)&&<SideBar exchange={exchange}/>}
                <div className='controller-content' style={{maxWidth:'100vw', width:'100%',backgroundColor:'rgb(241,243,244)',minHeight:hasSideBar?'100vh':'calc(100vh - ' + BOTTOM_BAR_HEIGHT +'px)' }}>
                  {!onPos&& <HeaderComp exchange={exchange} setLoginStatus={this.setLoginStatus}/> }
                  <Routes history={history}>
                  {!onPos&&
                    <Route path='/' element={<HomePage exchange={exchange} />}/>}
                    {/* <Route path='/login' element={<LoginModal  onCancel={()=>{this.setState({showLoginModal:false});}}/>}/> */}
                    <Route element={<PageNotFound  exchange={exchange} showLogin={this.showLogin}/>} />
                    
                    <Route path='/privacy-policy' element={<PrivacyPolicy exchange={exchange} />}/>
                    <Route path='/cookie-policy' element={<CookiePolicy exchange={exchange} />}/>
                    
                    <Route path='/subscribe' element={<SubscriptionPage exchange={exchange} />}/>
                    
                  </Routes>
                  <AuthRouter exchange={exchange} history={history}/>
                 
                { this.state.loginStatus!==LOGIN_STATUS.NONE&&
                  <LoginSignupModal status={this.state.loginStatus} setLoginStatus={this.setLoginStatus}/>}

                {this.renderModals(exchange)}


                </div>
              </div>
            </div>}

            {(onPos&&!hasSideBar)&&<BottomBar exchange={exchange}/>}
            {onAuth&& <WaitPage/>}
            
      </div>
    );
  }
}

export default App;