import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ItemUtil from '../../../service/ItemUtil';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

class MenuItem extends Component {
    

    async componentDidMount(){

        // const params= useParams()
    }
    
    pesoWithCommas=(x)=>{
        let roundedX= Math.round((x + Number.EPSILON) * 100) / 100;
        return '₱'+roundedX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render(){
        let variantsArray = ItemUtil.getVariants(this.props.item);
        let hasVariants = variantsArray.length>0;
        let variantsDiv=[];
        let variantsTxt = '';
        let variantNameLimit=5;
        if(hasVariants){
            variantsArray.forEach((vItem,idx)=>{
                if(idx<variantNameLimit){
                    variantsTxt+=vItem.name +', ';
                }else if(idx===variantNameLimit) {
                    variantsTxt+='...'+ (variantsArray.length - variantNameLimit) + ' more., '
                }

                if(this.props.item.expanded){
                    variantsDiv.push(
                        <div class='item-variant'> 
                            <h3 class='txt'>{vItem.name}</h3>
                            <h3 class='txt'>{"₱ "+vItem.price}</h3>
                        </div>
                    )
                }
            });
            variantsTxt=variantsTxt.substring(0,variantsTxt.length-2);
        }

        return (
            <div className='menu-item-wrapper' onClick={this.props.onClick}>
                
                <div className='menu-item'>
                    <div style={{width:'100%'}}>
                        {this.props.item.trackStock?
                            <p class='c-p4' style={{backgroundColor:'rgb(170,175,177)',color:'white',width:'fit-content',padding:'0px 4px',borderRadius:'3px'}}>{'Stock tracked'}</p>:<p></p>}
                        <h3 class='txt'>{this.props.item.name}</h3>
                        <p style={{fontSize:'0.85rem',opacity:'0.7',maxWidth:'300px'}} className='txt'>{variantsTxt}</p>
                    </div>
                    
                    {!hasVariants&&
                    <h3 class='txt' style={{minWidth:'fit-content',width:'20%',textAlign:'right',paddingRight:'12px'}}>
                        {this.pesoWithCommas(this.props.item.price)}
                    </h3>}
                    <Link to={this.props.deleteLink} tabIndex={-1}>
                        <IconButton  tabIndex={-1}
                            sx={{ "&:hover": { color: "rgb(233, 89, 89)", backgroundColor:'transparent'},padding:'0px' }} aria-label="close">
                        <DeleteIcon fontSize="small" /></IconButton>    
                    </Link>             
                </div>
                {variantsDiv}
            </div>
        );
    }
}

export default MenuItem;