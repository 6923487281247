import React, { Component } from 'react';
import './CashDrawer.css';
import moment from "moment";
import PosHeader from '../PosHeader';
import { Link } from 'react-router-dom';
import DrawerService from './DrawerService';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import {isMobileOnly,isMobile,BrowserView,MobileView} from 'react-device-detect';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmNotif from '../../windows/ConfirmNotif';
import HistoryIcon from '@mui/icons-material/History';
import DownloadIcon from '@mui/icons-material/Download';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import NotificationUtil from '../../../util/NotificationUtil';
import { getDatabase, ref, onValue } from "firebase/database";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseableModal from '../../../components/CloseableModal';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export const MODALS= Object.freeze({
    ADD_STARTING_CASH:'ADD_STARTING_CASH',
    ADD_CASH:'ADD_CASH',
    ADD_EXPENSE:'ADD_EXPENSE'
});
const DEFAULT_DRAWER = {
    sales:0,
    startingCash:{amount:0,remarks:null},
    cashAdded:[],
    expenses:[],
    payments:[]
};
const db = getDatabase(); 
class CashDrawer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            drawer:JSON.parse(JSON.stringify(DEFAULT_DRAWER)),
            drawerRes:null,
            modal:null,
            cashItemToDelete:null,
            showHistory:false
        }
    }

    
    async componentDidMount(){
        window.scrollTo(0, 0);

        let today = new Date(new Date().setHours(0,0,0,0));
        const drawerRef = ref(db, 'drawer/' + this.props.exchange.storedSession.business.id+'/'+today.valueOf());
        onValue(drawerRef, (snapshot) => {
            let drawerRes = snapshot.val();
            // console.log('drawerRes xx',drawerRes);
            if(drawerRes!=null){
                let drawer = this.state.drawer;
                // drawer.startingCash = drawerRes.startingCash!=null? drawerRes.startingCash:{amount:0,remarks:null};
                drawer.startingCash = {amount:0,remarks:null,date:null};
                let startingCashArr = drawerRes.startingCash!=null?Object.values(drawerRes.startingCash):[];
                if(startingCashArr.length>0){
                    let lastStartCashEvent = startingCashArr[startingCashArr.length-1];
                    if(lastStartCashEvent.amount>0){
                        drawer.startingCash = lastStartCashEvent;
                    }
                }
                drawer.cashAdded = drawerRes.cashAdded!=null?Object.values(drawerRes.cashAdded):[];
                drawer.expenses = drawerRes.expenses!=null?Object.values(drawerRes.expenses):[]
                drawer.payments = drawerRes.payments!=null?Object.values(drawerRes.payments):[]
                drawer.sales = drawerRes.sales!=null?drawerRes.sales:0;

                drawer.cashAdded = drawer.cashAdded.filter(ca=>ca.removed==null);
                drawer.expenses = drawer.expenses.filter(ca=>ca.removed==null);

                if(drawerRes.clearEvent!=null){
                    let clearEventsArray = Object.values(drawerRes.clearEvent);
                    if(clearEventsArray.length>0){
                        let lastClearEvent = clearEventsArray[clearEventsArray.length-1];
                        drawer.cashAdded = drawer.cashAdded.filter(ca=>new Date(ca.date).valueOf()>lastClearEvent);
                        drawer.expenses = drawer.expenses.filter(ca=>new Date(ca.date).valueOf()>lastClearEvent);
                        drawer.payments = drawer.payments.filter(ca=>new Date(ca.date).valueOf()>lastClearEvent);
                        if( drawer.startingCash.date!=null && new Date(drawer.startingCash.date).valueOf()<lastClearEvent){
                            drawer.startingCash  = {amount:0,remarks:null,date:null};
                        }
                    }
                }

                this.setState({drawer:drawer,drawerRes:drawerRes});
            }
        });
    }
    
    updateStartingCash=async(addCash)=>{

        let drawer = this.state.drawer;
        drawer.startingCash=addCash;
        this.setState({drawer:drawer,modal:null});
        DrawerService.updateStarting(this.props.exchange.storedSession.business.id, addCash).then(()=>{
            console.log('success');
        }).catch(err=>{
            console.error('CD0002',err);
        })
    }

    addDrawerCash=async(addCash)=>{
        let drawer = this.state.drawer;
        drawer.cashAdded.push(addCash);
        
        this.setState({drawer:drawer,modal:null});
        //separate api call for adding cash, @TODO kasi yung offline saving ng POS events   
        DrawerService.addDrawerCash(this.props.exchange.storedSession.business.id, addCash).then(()=>{
            console.log('success');
        }).catch(err=>{
            console.error('CD0003',err);
        })
    }

    addCashExpense=async(addCash)=>{
        let drawer = this.state.drawer;
        drawer.expenses.push(addCash);
        
        this.setState({drawer:drawer,modal:null});
        //separate api call for adding cash, @TODO kasi yung offline saving ng POS events   
        DrawerService.addCashExpense(this.props.exchange.storedSession.business.id, addCash).then(()=>{
            console.log('success');
        }).catch(err=>{
            console.error('CD0004',err);
        })
    }

    peso=(x)=>{
        x= Math.round((x + Number.EPSILON) * 100) / 100;
        return (isMobileOnly?'₱':'PHP ')+x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    renderModals=()=>{
        if(this.state.modal===null){return null}

        let title ='';
        let titlePlaceholder='';
        let remarksPlaceholder='';
        let onAdd=null;
        let remarksLimit=200;
        switch(this.state.modal){
            case MODALS.ADD_STARTING_CASH: 
                title='Starting Cash';
                titlePlaceholder='eg. 100';
                remarksPlaceholder='eg. Cash from yesterday';
                onAdd=this.updateStartingCash;
                remarksLimit=60;
            break;
            case MODALS.ADD_CASH: 
                title='Cash';
                titlePlaceholder='eg. 100';
                onAdd=this.addDrawerCash
            break;
            case MODALS.ADD_EXPENSE: 
                title='Expense';
                titlePlaceholder='eg. 100';
                onAdd=this.addCashExpense;
                // remarksPlaceholder='eg. ';
               
            break;
            default:return null;
        }
        return (
            <CloseableModal
                content={
                <AddCash
                    title={title}
                    titlePlaceholder={titlePlaceholder}
                    remarksPlaceholder={remarksPlaceholder}
                    onCancel={()=>this.setModal(null)}
                    onAddClick={onAdd}
                    remarksLimit={remarksLimit}
                    />}
                onCancel={()=>this.setModal(null)}
            />);
    }

    

    setModal=(modal)=>{
        this.setState({modal:modal});
    }

    confirmDelete=(cashItem,type)=>{
        cashItem.type = type;
        this.setState({cashItemToDelete:cashItem});
    }

    renderCashFullDetails=()=>{
        let cashAddedDiv=[];
        let CashItem=(props)=>{
            let isPaymentType = props.type==='PAYMENT';
            let isBukasable = props.onClick!=null;
            return(
                <div className={'cadd-item '+(isBukasable?' cadd-bukasable':'')} onClick={props.onClick} style={{}}>
                    <div style={{display:'flex',padding:'8px 8px 8px 8px'}}>
                        <div style={{width:'100%'}}>
                            <p class='add-t'>{moment(props.item.date).fromNow()}</p>
                            <p style={{fontWeight:'700'}}>{this.peso(props.item.amount)}</p>
                        </div>
                        {props.item.remarks&&
                        <div style={{width:'100%',textAlign:'right',justifyContents:'center',marginRight:'8px'}}>
                            <p style={{fontSize:'0.6rem',opacity:'0.6',marginTop:'4px'}}>Remarks:</p>
                            <p style={isMobileOnly?{fontSize:'0.8rem',opacity:'0.9'}:{fontSize:'0.88rem',opacity:'0.9'}}>{props.item.remarks}</p>
                        </div>}

                        {isBukasable&&
                        <div style={{width:'100%',textAlign:'right',justifyContents:'center',marginRight:'8px'}}>
                            {props.isOpened?<ArrowDropUpIcon/>:<ArrowDropDownIcon/>}
                        </div>}
                        
                        {!isPaymentType&&
                        <div style={{minWidth:'30px',display:'flex',justifyContent:'center',alignItems:'center',paddingTop:'2px'}}>
                            <div className='ibtn2' style={{padding:'4px'}} onClick={()=>this.confirmDelete(props.item, props.type)}>
                                <ClearOutlinedIcon sx={{fontSize:'18px',color:'rgb(150,150,150)'}}/>
                            </div>
                        </div>}
                    </div>
                    {isPaymentType&&props.isOpened&&
                    <div style={{fontSize:'0.85rem',padding:'8px 8px 12px 16px',backgroundColor:'rgb(248,248,249)'}}>

                        <p>{'Order ID: '}<Link to={'/sale-complete/'+props.item.orderId}>{props.item.orderId}</Link></p>

                        <div style={{display:'flex',marginTop:'10px'}}>
                            <div style={{width:'100%'}}>
                                <p style={{fontSize:'0.7rem',fontWeight:'500',color:'gray'}}>Date:</p>
                                <p>{moment(props.item.date).format('ll')}</p>
                            </div>

                            <div style={{width:'100%'}}>
                                <p style={{fontSize:'0.7rem',fontWeight:'500',color:'gray'}}>Time:</p>
                                <p>{moment(props.item.date).format('LT')}</p>
                            </div>
                        </div>


                        <div style={{display:'flex',marginTop:'6px'}}>
                            <div style={{width:'100%'}}>
                                <p style={{fontSize:'0.7rem',fontWeight:'500',color:'gray'}}>Cash Received:</p>
                                <p>{this.peso(props.item.cashReceived)}</p>
                            </div>

                            <div style={{width:'100%'}}>
                                <p style={{fontSize:'0.7rem',fontWeight:'500',color:'gray'}}>Change:</p>
                                <p>{(this.peso(props.item.cashReceived-props.item.amount))}</p>
                            </div>
                        </div>

                         
                    </div>}
                </div>);
        }
        this.state.drawer.cashAdded.forEach((cd,idx)=>{
            cashAddedDiv.push(<CashItem item={cd} type={'ADD'} />)
        });

        let expensesDiv=[];
        this.state.drawer.expenses.forEach((exp,idx)=>{
            expensesDiv.push(<CashItem item={exp}  type={'EXPENSE'} />)
        });

        let paymentsDiv=[];
        this.state.drawer.payments.forEach((paymeent,idx)=>{
            let isTheOneOpen = paymeent.orderId===this.state.viewedPaymentId;
            paymentsDiv.push(
            <CashItem item={paymeent}
                       isOpened={isTheOneOpen} 
                       onClick={()=>this.setState({viewedPaymentId:isTheOneOpen?null:paymeent.orderId})}
                       type={'PAYMENT'} />)
        });
        

        let noEntryDiv=<div className='txt1' style={{textAlign:'center',opacity:'0.5',padding:'10px'}}>- No entry -</div>;
        return(
            <div className='cash-full-content' style={{borderRadius:'8px'}}>
                <div className='cash-liseses' style={{display:isMobileOnly?'':'flex'}}>


                    <div className='expenses-list cash-list box-div' style={isMobileOnly?{margin:'0px 0px 10px 0px'}:{}}>
                        <div className={isMobile?'cash-list-hdr-m':'cash-list-hdr'}>Payments</div>
                        <div className={isMobile?'cash-list-wrpr-m':'cash-list-wrpr'}>
                            {paymentsDiv.length===0?noEntryDiv:paymentsDiv}
                        </div>
                    </div>
                    {isMobileOnly&&<div style={{height:'6px'}}/>}

                    <div className='cash-added-list cash-list box-div' style={isMobileOnly?{margin:'0px 0px 10px 0px'}:{}}>
                        <div className={isMobile?'cash-list-hdr-m':'cash-list-hdr'}>Cash Added</div>

                        <div className={isMobile?'cash-list-wrpr-m':'cash-list-wrpr'}>
                            {cashAddedDiv.length===0?noEntryDiv:cashAddedDiv}
                        </div>
                    </div>
                    {isMobileOnly&&<div style={{height:'6px'}}/>}
                    <div className='expenses-list cash-list box-div' style={isMobileOnly?{margin:'0px 0px 10px 0px'}:{}}>
                        <div className={isMobile?'cash-list-hdr-m':'cash-list-hdr'}>Expenses</div>
                        <div className={isMobile?'cash-list-wrpr-m':'cash-list-wrpr'}>
                            {expensesDiv.length===0?noEntryDiv:expensesDiv}
                        </div>
                    </div>

                </div>
            </div>
        );
    }

    clearDrawerClicked=()=>{
        this.setState({onConfirmClearDrawer:true})
    }

    render() {
        let totalExpenses  = this.state.drawer.expenses.map(ca=>ca.amount).reduce((partialSum, a) => partialSum + a, 0);//this.state.drawer.expenses
        let totalCashAdded = this.state.drawer.cashAdded.map(ca=>ca.amount).reduce((partialSum, a) => partialSum + a, 0);
        let cashInDrawer   = (this.state.drawer.sales + this.state.drawer.startingCash.amount + totalCashAdded) - totalExpenses;
 
        return (
            <div className='page cash-drawer-page' style={{backgroundColor:'rgb(241,243,244)'}}>
                <PosHeader 
                    title={'Cash Drawer'}
                    exchange={this.props.exchange}/>

                <div class='page-body' style={isMobileOnly?{padding:'25px 10px'}:{padding:'25px 1.3vw'}}>

                    <div className='cashdrawer-actions'>
                        <BrowserView>
                            <div className='cashdrawer-actions-wrpr' style={{display:'flex',justifyContent: 'flex-end'}}>
                                <button className='btn1 btn-dkblue' onClick={this.clearDrawerClicked} tabIndex={-1}>
                                    <ClearAllIcon/><span style={{marginLeft:'4px'}}>{'Clear Drawer'}</span>
                                </button>
                                <button className='btn1 btn-magenta' style={{width:'140px',marginLeft:'6px'}} tabIndex={-1}>
                                    <DownloadIcon/><span style={{marginLeft:'4px'}}>{'Download'}</span>
                                </button>
                            </div>
                        </BrowserView>

                        <MobileView>
                            <div className='cashdrawer-actions-wrpr' style={{display:'flex',justifyContent: 'flex-end'}}>
                                <button className='btn1-m btn-dkblue' onClick={this.clearDrawerClicked} >
                                    <ClearAllIcon/><span style={{marginLeft:'4px'}}>{'Clear Drawer'}</span>
                                </button>
                                <button className='btn1-m btn-magenta' style={{marginLeft:'6px'}}>
                                    <DownloadIcon/><span style={{marginLeft:'4px'}}>{'Download'}</span>
                                </button>
                            </div>
                        </MobileView>
                    </div>
                    <div className='cashdrawr-main-content' style={{marginBottom:'10px'}}>
                        <div className='main-content box-div' style={{backgroundColor:'white',padding:isMobileOnly?'20px 12px':'20px',borderRadius:'4px'}}>
                            <div style={{borderBottom:'solid 2px rgb(235,235,235)',paddingBottom:'20px',display:'flex',justifyContent:'space-between'}}>
                                <div>
                                    <p className='txt1'>Cash in Drawer</p>
                                    <h1 style={{fontSize:'2.2rem',color:cashInDrawer<0?'rgb(228, 30, 30)':''}}>{this.peso(cashInDrawer)}</h1>
                                </div>
                                <div>
                                <BrowserView>
                                    <button className='btn1 btn-lgray' onClick={()=>this.setState({showHistory:true})} tabIndex={-1} style={{color:'rgb(90,90,90)'}}>
                                        <HistoryIcon sx={{marginRight:'4px'}}/>{'History'}
                                    </button>
                                </BrowserView>

                                <MobileView>
                                    <button className='btn1-m btn-lgray' onClick={()=>this.setState({showHistory:true})} style={{color:'rgb(90,90,90)',height:'32px'}}>
                                        <HistoryIcon fontSize={'12'} sx={{marginRight:'4px'}}/>{'History'}
                                    </button>
                                </MobileView>
                                </div>
                            </div>
                            <div className='dits-containter' style={{display:'block',marginTop:'20px'}}>
                                <CashDetails title={'Sales'}         value={this.state.drawer.sales} />
                                <CashDetails title={'Starting Cash'} value={this.state.drawer.startingCash.amount} 
                                    onDeleteAction={()=>this.setState({onConfirmDeleteStarting:true})}
                                    subtitle={this.state.drawer.startingCash.remarks}
                                    onAddClick={()=>this.setModal(MODALS.ADD_STARTING_CASH)}/>
                                <CashDetails title={'Cash Added'}    value={totalCashAdded} onAddClick={()=>this.setModal(MODALS.ADD_CASH)} />
                                <CashDetails title={'Expenses'}      value={totalExpenses} onAddClick={()=>this.setModal(MODALS.ADD_EXPENSE)} />
                            </div>
                        </div>
                    </div>

                    {this.renderCashFullDetails()}
                </div>

                {this.renderModals()}

                {this.state.cashItemToDelete!=null&&
                <ConfirmNotif
                    mainText={'Are you sure you want to delete this?'}
                    seondaryText={'This action cannot be undone.'}
                    acceptLabel={'Delete'}
                    fullscreen={isMobile}
                    onAccept={async()=>{
                        DrawerService.deleteCashItem(this.props.exchange.storedSession.business.id, this.state.cashItemToDelete.id,this.state.cashItemToDelete.type)
                            .then(()=>{
                                this.setState({cashItemToDelete:null});
                            })
                            .catch((error)=>{
                                console.error('CD0005:',JSON.stringify(error));
                            });
                    }}
                    onCancel={()=>this.setState({cashItemToDelete:null})}
                />}

                {this.state.onConfirmDeleteStarting&&
                <ConfirmNotif
                    mainText={'Are you sure you want remove starting cash?'}
                    seondaryText={'This action cannot be undone.'}
                    acceptLabel={'Delete'}
                    fullscreen={isMobile}
                    onAccept={()=>{
                        DrawerService.deleteStarting(this.props.exchange.storedSession.business.id)
                            .then(()=>{
                                this.setState({onConfirmDeleteStarting:null});
                            })
                            .catch((error)=>{
                                console.error('CD0006:',JSON.stringify(error));
                            });
                    }}
                    onCancel={()=>this.setState({onConfirmDeleteStarting:null})}
                />}


                {this.state.onConfirmClearDrawer&&
                <ConfirmNotif
                    mainText={'Are you sure you want to clear drawer?'}
                    seondaryText={'This action cannot be undone.'}
                    acceptLabel={'Yes'}
                    fullscreen={isMobile}
                    onAccept={async()=>{
                        DrawerService.clearDrawer(this.props.exchange.storedSession.business.id)
                            .then(()=>{
                                NotificationUtil.makeToast('Cash drawer cleared','rgb(55,66,71)','white',3000,'top-center');
                                this.setState({onConfirmClearDrawer:false});
                            })
                            .catch((error)=>{
                                console.error('CD0011:',JSON.stringify(error));
                            });
                    }}
                    onCancel={()=>this.setState({onConfirmClearDrawer:false})}
                />}

                {this.state.showHistory&&
                <CloseableModal 
                    content={
                    <DrawerHistory
                        drawerRes={this.state.drawerRes}
                        drawer={this.state.drawer}
                    />}
                    onCancel={()=>this.setState({showHistory:false})}
                />}

            </div>
        );
    }
}

class DrawerHistory extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    async componentDidMount(){
        // console.log('drawerRes',this.props.drawerRes);
        // console.log('drawer',this.props.drawer);
        // console.log('getAllEvents',this.getAllEvents());
    }

    getAllEvents=()=>{

        let startingArr=[];
        if(this.props.drawerRes!=null && this.props.drawerRes.startingCash!=null){
            startingArr=Object.values(this.props.drawerRes.startingCash);
            startingArr = startingArr.map(ca => ({...ca,date:new Date(ca.date),type:'STARTING'}));
        }

        let cashAddedArr=[];
        let cashAddedPeroRemovedArr=[];
        if(this.props.drawerRes!=null && this.props.drawerRes.cashAdded!=null){
            cashAddedArr=Object.values(Object.values(this.props.drawerRes.cashAdded));
            cashAddedArr = cashAddedArr.map(ca => ({...ca,date:new Date(ca.date),type:'ADD'}));

            cashAddedPeroRemovedArr=Object.values(Object.values(this.props.drawerRes.cashAdded));
            cashAddedPeroRemovedArr = cashAddedPeroRemovedArr.filter(ca=>ca.removed!=null);
            cashAddedPeroRemovedArr = cashAddedPeroRemovedArr.map(ca => ({...ca, amount:(ca.amount*-1), id:ca.id+'-removed', date:new Date(ca.removed),type:'-ADD'}));
        }

        let expensesArr=[];
        let expensesPeroRemovedArr=[];
        if(this.props.drawerRes!=null && this.props.drawerRes.expenses!=null){
            expensesArr=Object.values(Object.values(this.props.drawerRes.expenses));
            expensesArr = expensesArr.map(ca => ({...ca, amount:(ca.amount*-1   ), date:new Date(ca.date),type:'EXPENSE'}));
            
            expensesPeroRemovedArr=Object.values(Object.values(this.props.drawerRes.expenses));
            expensesPeroRemovedArr = expensesPeroRemovedArr.filter(ca=>ca.removed!=null);
            expensesPeroRemovedArr = expensesPeroRemovedArr.map(ca => ({...ca, id:ca.id+'-removed', date:new Date(ca.removed),type:'-EXPENSE'}));
  
        }
        

        let paymentsArr=[];
        if(this.props.drawerRes!=null && this.props.drawerRes.payments!=null){
            paymentsArr=Object.values(Object.values(this.props.drawerRes.payments));
            paymentsArr = paymentsArr.map(ca => ({...ca,date:new Date(ca.date),type:'PAYMENT'}));
        }

        let clearDrawerEvent=[];
        if(this.props.drawerRes!=null && this.props.drawerRes.clearEvent!=null){
            clearDrawerEvent=Object.values(this.props.drawerRes.clearEvent);
            clearDrawerEvent = clearDrawerEvent.map(ce => ({date:new Date(ce),type:'CLEAR'}));
        }

        let allEvent=[].concat(startingArr,cashAddedArr,expensesArr,paymentsArr,clearDrawerEvent,cashAddedPeroRemovedArr,expensesPeroRemovedArr);
        allEvent=allEvent.sort(function(o1,o2){return o1.date.valueOf()<o2.date.valueOf() ? -1 :  o1.date.valueOf()>o2.date.valueOf() ? 1 : 0;});
        
        return allEvent;
    }

    peso=(x)=>{
        x= Math.round((x + Number.EPSILON) * 100) / 100;
         
        return '₱'+Math.abs(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render(){

        let transactionsDiv=[];
        let runningTotal=0;
        transactionsDiv.push(
            <div className='hist-event hist-event-hdr' style={{}}>
                <div className='he-c1'>Time</div>
                <div className='he-c2'>Type</div>
                <div className='he-c3'>Amount</div>
                <div className='he-c4'>Running total</div>
                <div className='he-c5'>Remarks</div>
            </div>);

        let lastStartingAmount = 0;
        this.getAllEvents().forEach((cashEvent,idx)=>{
            let isClearEvent=cashEvent.type==='CLEAR';
            let isExpense = cashEvent.type === 'EXPENSE';
            let isStarting = cashEvent.type === 'STARTING';
            let isRemoveStarting = cashEvent.remarks==='****Removed starting cash****';
            if(isStarting){
                if(isRemoveStarting===false){
                    lastStartingAmount=cashEvent.amount;
                    runningTotal+=lastStartingAmount;
                }else{
                    runningTotal-=lastStartingAmount;
                }
                
            }else{
                runningTotal=isClearEvent?0:runningTotal+cashEvent.amount; 
            }
            transactionsDiv.push(
                <div className={'hist-event he-' + (cashEvent.type).toLowerCase()}>
                    <div className='he-c1'>
                        <p style={{color:'gray'}}>{moment(cashEvent.date).format('LTS')}</p>
                    </div>

                    <div className='he-c2'>
                        <p style={{padding:'4px', width:'fit-content',color:'rgb(50,50,50)',borderRadius:'4px',backgroundColor:'rgb(240,240,240)'}}>{cashEvent.type}</p>
                    </div>
 
                    <div className='he-c3' style={{fontSize:'1rem'}}>
                        {isStarting? <p style={{opacity:'0.35',fontSize:'0.8rem'}}>{isRemoveStarting?'Removed starting cash':cashEvent.amount}</p>:
                        (isClearEvent? 
                        <p style={{opacity:'0.35',fontSize:'0.8rem'}}>Cash drawer cleared</p>:
                        <p style={{color:isExpense?'red':''}}>{this.peso(cashEvent.amount)}</p>)}
                    </div>

                    <div className='he-c4'  style={{fontSize:'1rem'}}>
                        <p style={{}}>{this.peso(runningTotal)}</p>
                    </div>

                    {cashEvent.remarks&&!isRemoveStarting&&
                    <div className='he-c5'>
                        <p style={{}}>{cashEvent.remarks}</p>
                    </div>}
                </div>
            );
        });

        return(
            <div className={'drawer-history ' + (isMobile?'window-fullscreen':'window')}>
                <h3>Drawer History</h3>
                <br/>
                <div style={{}}>
                    {transactionsDiv}
                    
                    {transactionsDiv.length===1&&
                    <div style={{padding:'30px 0px',textAlign:'center'}}>
                        <p style={{color:'rgb(180,180,180)',fontSize:'0.9rem'}}>- No events yet for today -</p>
                    </div>}
                </div>
            </div>
        )
    }
}

class CashDetails extends Component {

    peso=(x)=>{
        x= Math.round((x + Number.EPSILON) * 100) / 100;
        return (isMobileOnly?'₱':'PHP ')+x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render(){
        let canSetValue = this.props.onAddClick!=null;
        let canDelete = this.props.onDeleteAction!=null && this.props.value>0;
        return (
            <div className='cashdits' style={isMobileOnly?{width:'40%'}:{}}>
                <p className='txt1'>{this.props.title}</p>
                <div style={{display:'flex'}}>
                    <h1 style={{fontSize:'1.4rem',fontWeight:'800'}}>{this.peso(this.props.value)}</h1>
                    {canSetValue&&!canDelete&&
                    <div className='ibtn' style={{marginLeft:'6px',padding:'0px 8px'}} onClick={this.props.onAddClick}>
                        <AddIcon sx={{fontSize:'20px',color:'rgb(100,100,100)'}}/>
                    </div>}
                    {canDelete&&
                    <div className='ibtn' style={{marginLeft:'6px',padding:'0px 8px'}} onClick={this.props.onDeleteAction}>
                        <DeleteIcon sx={{fontSize:'20px',color:'rgb(100,100,100)'}}/>
                    </div>}
                    
                </div>
                {this.props.subtitle&&
                <p style={{fontSize:'0.7rem',fontWeight:'400',opacity:'0.7',maxWidth:'140px'}}>{this.props.subtitle}</p>}
            </div>
        );
    }
}

class AddCash extends Component {

    constructor(props) {
        super(props);
        this.amountRef = React.createRef();
        this.state = { 
            amount:'',
            remarks:'',
            errMsg:''
        };
    } 

    async componentDidMount(){
        this.amountRef.current.focus();
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown = (event) => {
        switch(event.keyCode) {
            case 13: this.onAddClick();break; // ENTER KEY
            default: 
                break;
        }
    }

    onAddClick=()=>{
        if(this.state.amount===''){
            this.setState({errMsg:'Amount is required'});
        }else if(isNaN(this.state.amount)){
            this.setState({errMsg:'Please enter correct amount'});
        }
        else{
            this.props.onAddClick({amount:Number(this.state.amount), remarks:this.state.remarks,date:new Date()});
        }
    }
    render() {
        let isSmall = isMobileOnly || this.state.width < 500;
        console.log('isSmallisSmall',isSmall)
        return (
            <div className={(isSmall?'window-fullscreen':'window')}>
                <div>
                    <h2 className='txt2'>{'Add '+ this.props.title}</h2>
                    <TextField 
                        id="amount" 
                        variant="outlined"
                        inputProps={{ inputMode: 'numeric' }}
                        type='telephone'
                        inputRef={this.amountRef}
                        error={this.state.errMsg!==''}
                        placeholder={this.props.titlePlaceholder}
                        label={'Amount'}
                        value={this.state.amount}
                        onChange={(event)=>{
                            if(Number(event.target.value)<1000000)
                            this.setState({amount:event.target.value,errMsg:''});
                        }}
                        color='success'
                        sx={{width:'100%',marginTop:'20px',marginBottom:'8px'}}
                    />

                    <TextField 
                        id="remarks" 
                        variant="outlined"
                        value={this.state.remarks}
                        label={'Remarks'}
                        placeholder={this.props.remarksPlaceholder}
                        onChange={(event)=>{
                            if(event.target.value.length<this.props.remarksLimit){
                                this.setState({remarks:event.target.value});
                            }
                        }}
                        color='success'
                        sx={{width:'100%',marginTop:'8px',marginBottom:'14px'}}
                    />
                    <br/>
                    <p style={{color:'red',textAlign:'center'}}>{this.state.errMsg}</p>
                    <br/>
                    
                    <div style={{display:'flex'}}>
                        <button class={'btn1 btn-second btn-gray'} onClick={this.props.onCancel} style={{minWidth:'40%', marginRight:'8px'}} tabIndex={-1}>
                            Cancel
                        </button>
                        <button className='btn1 b100' onClick={this.onAddClick}  tabIndex={-1}>
                            Add
                        </button>
                    </div>

                </div>
            </div>
        );
    }
}

export default CashDrawer;