import React, { Component } from 'react';
import InfoDiv from './InfoDiv';
import {isMobile} from 'react-device-detect';
import moment from "moment";
import NotificationUtil from '../../util/NotificationUtil';
import FirebaseService from '../../service/FirebaseService';
import CircularProgress from '@mui/material/CircularProgress';
import {NAME_LIMIT,RECEIPT_MESSAGE_LIMIT} from '../../constants';


class ReceiptSettings extends Component {

    constructor(props) {
        super(props);
        let businessReceipt = this.props.exchange.storedSession.business.receipt || {};
        if(businessReceipt.permit==null){
            businessReceipt.permit ={
                 number: ''
                ,issueDate:''
            }
        }
        this.state = {
            onLoad:false
            ,receipt: JSON.parse(JSON.stringify(businessReceipt))
            ,initialReceipt: JSON.parse(JSON.stringify(businessReceipt))
        }
    }

    changeInputHandler=(event)=>{
        let receipt = this.state.receipt;
        switch(event.target.id){
            case 'r-owner-name-input': 
                if(event.target.value.length<NAME_LIMIT){
                    receipt.ownerName = event.target.value;
                }
            break;
            case 'r-message-input': 
                if(event.target.value.length<RECEIPT_MESSAGE_LIMIT){
                    receipt.message = event.target.value;
                }
            break;
            case 'r-permit-no-input':     
                if(event.target.value.length<100){//@Todo update length limit
                    let permit=receipt.permit;
                    permit.number = event.target.value;
                    receipt.permit = permit;
                }
            break;
            case 'r-permit-date-input':     
                if(event.target.value.length<11){//@Todo update length limit
                    let permit=receipt.permit;
                    permit.issueDate = event.target.value;
                    receipt.permit = permit;
                }
            break;
            case 'r-non-vattin-input':     
                if(event.target.value.length<=12){//@Todo update length limit
                    receipt.nonVatTin = event.target.value;
                }
            break;
            case 'r-vattin-input':     
                if(event.target.value.length<=12){//@Todo update length limit
                    receipt.vatTin = event.target.value;
                }
            break;
            case 'r-min-input':     
                if(event.target.value.length<100){//@Todo update length limit
                    receipt.min = event.target.value;
                }
            break;
            case 'r-sn-input':     
                if(event.target.value.length<100){ //@Todo update length limit
                    receipt.sn = event.target.value;
                }
            break;
            
        default:
                break;
        }
        this.setState({receipt:receipt,errorMsg:''});
    }


    updateBusinessInfo=()=>{

        let businessId = this.props.exchange.storedSession.business.id;
        FirebaseService.updateReceiptDetails(businessId , this.state.receipt)
            .then(()=>{
                NotificationUtil.makeToast('Receipt details updated','white','rgb(107,191,57)',3000,'top-right');
                this.props.exchange.refreshCache();
            }).catch((err)=>{
                console.error('RSE:0001',err);
                this.setState({onLoad:false,errorMsg:'Something went wrong. Please try again later.'});
            });
    }

    onSaveClicked=()=>{
        this.setState({onLoad:true},this.updateBusinessInfo);
    }

    inputValid=()=>{ 
        return true;

    }

    render() {
        let canSave = JSON.stringify(this.state.initialReceipt)!==JSON.stringify(this.state.receipt) && this.inputValid();
        let lastUpdate =null;
        if(this.state.receipt.lastUpdated!=null){
            lastUpdate = new Date(this.state.receipt.lastUpdated);
        }
        return (
            <div className='main-settings-page receipt-settings' style={{overflow:'hidden'}}>
                <div class={'receipt-details subsettings '+(isMobile?' subsettings-mobile':'')} style={this.state.onLoad?{pointerEvents:'none'}:{}}>
                    <p class='c-h4'>Receipt Details</p> 
                    {lastUpdate&&<p class='c-p10' style={{textAlign:'left'}}>Last updated: {moment(lastUpdate).format('lll')}</p>}
                    <div class='partitions'>
                        <div class='part1'>
                            <InfoDiv id='r-owner-name-input' title='Owner name:' value={this.state.receipt.ownerName} onChange={this.changeInputHandler}/>   
                            <InfoDiv id='r-message-input' title='Receipt Message:' value={this.state.receipt.message} onChange={this.changeInputHandler}/>
                            <InfoDiv id='r-permit-no-input' title='Permit Number:' value={this.state.receipt.permit.number} onChange={this.changeInputHandler}/>   
                            <InfoDiv id='r-permit-date-input' title='Issue Date:' value={this.state.receipt.permit.issueDate} onChange={this.changeInputHandler}/>  
                            <InfoDiv id='r-non-vattin-input' title='Non-VAT Tin:' value={this.state.receipt.nonVatTin} onChange={this.changeInputHandler}/>
                            <InfoDiv id='r-vattin-input' title='VAT Tin:' value={this.state.receipt.vatTin} onChange={this.changeInputHandler}/> 
                            <InfoDiv id='r-min-input' title='Min:' value={this.state.receipt.min} onChange={this.changeInputHandler}/> 
                            <InfoDiv id='r-sn-input' title='SN:' value={this.state.receipt.sn} onChange={this.changeInputHandler}/> 
                        </div>

                        <br/>
                        <p style={{color:'red',textAlign:'center'}}>{this.state.errorMsg}</p>
                        <br/>
                        <div class='control-btns'>
                            <button class={'btn1 b100 ' + (canSave?'':' -btn1-disabled')} onClick={this.onSaveClicked}>
                                {this.state.onLoad?<CircularProgress sx={{}}/>:"Save"}
                            </button>
                        </div>
                        <div style={{width:'100%'}}></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReceiptSettings;