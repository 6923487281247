import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {isMobileOnly} from 'react-device-detect';
import moment from "moment";

class OrderList extends Component {

    pesoWithCommas=(x)=>{
        let roundedX= Math.round((x + Number.EPSILON) * 100) / 100;
        return '₱'+roundedX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    render() {

        let ordersDiv=[];
        if(this.props.orders!=null){
            this.props.orders.forEach((or,idx)=>{
                let cost= or.items.map(it=>(it.qty*it.cost)).reduce((partialSum, a) => partialSum + a, 0);
                let profit= or.amountDue-cost;
                
                ordersDiv.push(
                    <div className={'order-list-div order-div-'+idx} style={{}}>
                        <div style={{display:'flex',marginBottom:'6px'}}>
                             <div style={{minWidth:'fit-content'}}>
                                <span>Order number: </span><Link to={'/sale-complete/'+or.orderNumber}>{or.orderNumber}</Link>
                             </div>
                            <div style={{width:'100%',textAlign:'right'}}>
                                <h1 style={{fontSize:'1.1rem',fontWeight:'400'}}>{this.pesoWithCommas(or.totalPrice)}</h1>
                            </div>
                        </div>
                        <div style={{display:'flex'}}>
                            <div style={{width:'100%',fontSize:'0.85rem',color:'gray'}}>
                                <p>{moment(or.date).format('MMM D, YYYY')}</p>
                            </div>
                            {/* <div style={{width:'100%'}}>
                                <p>{'Cost: ₱'+cost}</p>
                            </div>
                            <div style={{width:'100%'}}>
                                <p>{'Profit: ₱'+profit}</p>
                            </div> */}
                        </div>
                    </div>
                )
            });
        }

        return (
            <div className='order-list' style={{minWidth:isMobileOnly?'95vw':'420px'}}>
                <div className='order-entries'>
                    {ordersDiv}
                </div>
                {ordersDiv.length===0&&
                <div style={{textAlign:'center',padding:'18px',color:'gray'}}>
                    <p>No order</p>
                </div>}
            </div>
        );
    }
}

export default OrderList;