import React, { Component } from 'react';
import PosHeader from '../PosHeader';
import TextField from '@mui/material/TextField';
import {isMobile,isMobileOnly} from 'react-device-detect';
import InventoryService from './InventoryService';
import LinearProgress from '@mui/material/LinearProgress';
import './Inventory.css';
import { getDatabase, ref, onValue } from "firebase/database";
const db = getDatabase(); 

class ItemStockList extends Component {

    constructor(props) {
        super(props);
        let itemsWithTrackStock  = [];
        
        if(this.props.exchange.storedSession.business!=null && this.props.exchange.storedSession.business.menu!=null 
          && this.props.exchange.storedSession.business.menu.items!=null){
            itemsWithTrackStock = this.props.exchange.storedSession.business.menu.items.filter(it=>it.trackStock);;
        }
       
        this.state = { 
            onLoad:true,
            items:itemsWithTrackStock,
            stock:{},
            baseStock:{}
        }

    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        const stockRef = ref(db, 'inventory/' + this.props.exchange.storedSession.business.id+'/stock');
        onValue(stockRef, (snapshot) => {
            const stock = snapshot.val();
            if(stock!=null){
                this.setState({stock:stock.items, baseStock:JSON.parse(JSON.stringify(stock.items)), onLoad:false});
            }else{
                this.setState({stock:{}, baseStock:{}, onLoad:false});
            }
        });
    }

    inStockInputHandler=(event,itemId)=>{
        if(this.state.onLoad)return;

        let stock = this.state.stock;;
        if(stock[itemId]!=null && stock[itemId].inStock!=null){
            stock[itemId].inStock = Number(event.target.value);
            this.setState({stock:stock});
        }else{
            if(stock[itemId]==null){stock[itemId]={};}
            if(stock[itemId]['inStock']==null){ stock[itemId]['inStock'] = Number(event.target.value);}
        }
    }

    minusStock=(itemId)=>{
        if(this.state.onLoad)return; 
        let stock = this.state.stock;
        if(stock[itemId]!=null && stock[itemId].inStock!=null){
            if(stock[itemId].inStock>0) {
                stock[itemId].inStock = stock[itemId].inStock-1;
                this.setState({stock:stock});
            }
        }

    }
    
    addStock=(itemId)=>{
        if(this.state.onLoad)return; 
        let stock = this.state.stock;
        if(stock[itemId]!=null && stock[itemId].inStock!=null){
            stock[itemId].inStock = stock[itemId].inStock+1;
        }else{
            if(stock[itemId]==null){stock[itemId]={};}
            if(stock[itemId]['inStock']==null){ stock[itemId]['inStock'] = 1;}
           
        }

        this.setState({stock:stock});
    }

    cancelChanges=()=>{
        if(this.state.onLoad)return;
        this.setState({stock:JSON.parse(JSON.stringify(this.state.baseStock))});
    }

    saveChanges=()=>{
        if(this.state.onLoad)return; 
        this.setState({onLoad:true},()=>{
            InventoryService.updateItemStock(this.props.exchange.storedSession.business.id,this.state.stock).then(()=>{
            this.setState({baseStock:JSON.parse(JSON.stringify(this.state.stock)), onLoad:false});
            }).catch(err=>{
                this.setState({onLoad:false});
                console.error('IP000002',err);
            });
        });
        
    }

    render(){

        let ItemStockDiv =(props)=>{
            let variantStockDiv=[]; 
            let hasVariants = props.item.variants!=null;
            if(hasVariants){
                let variantList = Object.values(props.item.variants);
                variantList.forEach((v)=>{

                    let variantDeducted = 0;
                    let variantInStockVal = 0;
                    let variantStockStatus = this.state.stock[v.id];
                    if(variantStockStatus!=null){
                        variantDeducted=variantStockStatus.deducted | 0;
                        variantInStockVal=variantStockStatus.inStock | 0;
                    }

                    variantStockDiv.push(
                    <div className='item-inventory-row' style={{padding:'2px 0px',fontSize:'0.9rem'}}>
                    <div className='irc c1' style={{paddingLeft:'20px'}}><p>{v.name}</p></div>
                       <div className='irc c2'><p>{variantDeducted}</p></div>
                       <div className='irc c3'>
                            <TextField 
                                id={"stock-input"} variant='standard'
                                value={variantInStockVal}
                                onChange={(e)=>this.inStockInputHandler(e,v.id)}
                                color='success'
                                sx={{width:'100%', input: {textAlign:'center'}}}/>
                        </div>

                        <div className='irc c4'>
                            <div className='btn1 stock-adjust-btn' onClick={()=>this.minusStock(v.id)}>-</div>
                            <div className='btn1 stock-adjust-btn' onClick={()=>this.addStock(v.id)}>+</div>
                        </div>
                    </div>)
                })
            }


            let itemDeducted = 0;
            let itemInStockVal = 0;
            let itemStockStatus = this.state.stock[props.item.id];
            if(itemStockStatus!=null){
                itemDeducted=itemStockStatus.deducted | 0;
                itemInStockVal=itemStockStatus.inStock | 0;
            }

            return(
                <div className='item-inv-wrpr' style={hasVariants?{paddingBottom:'10px'}:{}}>
                    <div className='item-inventory-row' style={hasVariants?{paddingBottom:'0px'}:{}}>
                        <div className='irc c1'><p class='c-p1'>{props.item.name}</p></div>
                        {hasVariants===false&&
                        <div className='irc c2'><p>{itemDeducted}</p></div>}
                        
                        {hasVariants===false&&
                        <div className='irc c3'>
                            <TextField 
                                id={"stock-input"} variant='standard'
                                value={itemInStockVal}
                                onChange={(e)=>this.inStockInputHandler(e,props.item.id)}
                                color='success'
                                sx={{width:'100%', input: {textAlign:'center'}}}/>
                        </div>}

                        {hasVariants===false&&
                        <div className='irc c4'>
                            <button className='btn1 stock-adjust-btn' onClick={()=>this.minusStock(props.item.id)}>-</button>
                            <button className='btn1 stock-adjust-btn' onClick={()=>this.addStock(props.item.id)}>+</button>
                        </div>}
                    </div>
                    {variantStockDiv}

                </div>
            )
        }


        let itemDivs =[];
        let hasChanges = JSON.stringify(this.state.baseStock) !== JSON.stringify(this.state.stock);
        itemDivs.push(
            <div className='item-inventory-row item-inventory-hdr'>
                <div className='c1 hdr'>Item name</div>
                <div className='c2 hdr'>Deducted</div>
                <div className='c3 hdr'>In stock</div>
                <div className='c4 hdr'></div>
            </div>
        );

        itemDivs.push(this.state.onLoad?<LinearProgress/>:<div style={{height:'4px',backgroundColor:'rgb(77,85,87)'}}></div>);
        this.state.items.forEach((item,idx)=>{
            itemDivs.push(<ItemStockDiv item={item}/>)
        });


        if(this.state.items.length===0)
            itemDivs.push(<div style={{padding:'30px 8px',backgroundColor:'white'}}><p style={{color:'gray',fontSize:'0.9rem'}}>No item is set for Stock tracking</p></div>);
            
        itemDivs.push(
            <div className='row-action-div' >
                <button className='btn1 btn-cancel' style={hasChanges?{}:{opacity:'0.2',pointerEvents:'none'}}  onClick={hasChanges?this.cancelChanges:null}>Cancel</button>
                <button className='btn1 btn-save' style={hasChanges?{}:{opacity:'0.2',pointerEvents:'none'}}  onClick={hasChanges?this.saveChanges:null}>Save</button>
            </div>
        );
        
      
        
        return (
            <div className={isMobileOnly?'item-stocklist module-mobile':'item-stocklist module'} style={this.state.onLoad?{pointerEvents:'none'}:{}}>
                <div className={isMobile?'module-hdr-mobile':'module-hdr'}>
                    Item Stock
                </div>

                <div className='module-body'>
                    {itemDivs}
                </div>
                    
            </div>
        );
    }
}

class InventoryPage extends Component {
    render() {
        return (
            <div className='inventory-page page'>
                <PosHeader 
                    title={'Inventory'}
                    exchange={this.props.exchange}
                    />
                <div className='page-body'>
                    <div className='page-body-wrper-iv'>
                        <ItemStockList exchange={this.props.exchange}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default InventoryPage;