import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import ReceiptBasic from './receipt/ReceiptBasic';
import CartService from '../../service/CartService';
import FirebaseService from '../../service/FirebaseService';
import PosHeader from './PosHeader';
import PrintIcon from '@mui/icons-material/Print';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import {isMobileOnly} from 'react-device-detect';
import './SaleComplete.css'

const LAST_VIEWED_ORDER = 'LAST_VIEWED_ORDER';
class SaleComplete extends Component {
    constructor(props) {
        super(props);
        let pathOrderNumber = window.location.pathname.replace('/sale-complete/','') + '';
        this.printableDivRef = React.createRef(); 
        this.state = {
             orderInfo:null
            ,onWait:true
        } 
        if(pathOrderNumber!=null && pathOrderNumber!==''){
            let hasCachedOrderd = false;
            let lastViewedOrderRaw = localStorage.getItem(LAST_VIEWED_ORDER);
            if(lastViewedOrderRaw!=null){
                try {
                    let lastViewedOrder = JSON.parse(lastViewedOrderRaw);
                    if(lastViewedOrder.orderNumber===pathOrderNumber && lastViewedOrder.orderInfo!=null){
                        this.state.orderInfo=lastViewedOrder.orderInfo;
                        this.state.onWait=false;
                        hasCachedOrderd=true;
                    }
                } catch (e) {
                    localStorage.removeItem(LAST_VIEWED_ORDER);
                }
            } 
            if(!hasCachedOrderd){
                FirebaseService.getOrderByOrderNumber(this.props.exchange.storedSession.business.id, pathOrderNumber,
                    (orderInfo)=>{
                        localStorage.setItem(LAST_VIEWED_ORDER,JSON.stringify({orderNumber:pathOrderNumber,orderInfo:orderInfo}));
                        this.setState({orderInfo:orderInfo,onWait:false});
                    });
            }
        }else{
            this.state.onWait = false;
        }
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
       
    }


    printReceiptClicked=()=>{
        //TODO Print receipt
    }

    newTransactionBtnClicked=()=>{
        CartService.removeOrderInfo(); 
    }

    pesoWithCommas=(x)=>{
        let roundedX= Math.round((x + Number.EPSILON) * 100) / 100;
        return '₱'+roundedX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render() { 
        
        let hasCashPayment = this.state.orderInfo!=null&&this.state.orderInfo.cashPayment!=null
        return (
            this.state.onWait?null:
            <div className={'page sale-complete-page'}>
                <PosHeader 
                    exchange={this.props.exchange}
                    />

                <div className='page-body' style={{display:'flex',justifyContent:'center'}}>
                    <div class='sale-complete-body'>
                        {this.state.orderInfo!=null?
                        <div>
                            <CheckCircleOutlineIcon style={{fontSize: isMobileOnly?'14vw':'80px'}}/>
                            <h2 class='hdr-ord1'>Order Complete</h2>
                        </div>
                        :
                        <div>
                            <SearchOffIcon style={{fontSize: isMobileOnly?'14vw':'80px'}}/>
                            <h2 class='hdr-ord1'>Order not found</h2>
                        </div>}
                        <div className='main-content' style={{display:'flex'}}>

                            {this.state.orderInfo&&
                            <div class='sale-complete-infos' style={{width:'100%',padding:'10px',textAlign:'center'}}>
                                <p class='hdr-or-info'>{'Order Number: ' + this.state.orderInfo.orderNumber}</p>
                                <p class='hdr-or-info'>{'Date: ' + new Date(this.state.orderInfo.date).toLocaleString()}</p>
                                <br/>
                                <div style={{display:'flex',width:'100%'}}>
                                    <div style={{borderRight:hasCashPayment?'solid 1px gray':'',width:'100%'}}>
                                        <p class='hdr-ord2' style={{paddingLeft:'8px'}}>{'Total'}</p>
                                        <p class='hdr-ord1'>{this.pesoWithCommas(this.state.orderInfo.totalPrice)}</p>
                                    </div>
                                    {hasCashPayment&&
                                    <div style={{width:'100%'}}>
                                        <p class='hdr-ord2' style={{paddingLeft:'8px'}}>{'Change'}</p>
                                        <p class='hdr-ord1'>{this.pesoWithCommas(this.state.orderInfo.cashPayment.change)}</p>
                                    </div>}
                                </div>
                                <br/><br/>
                                <Link className='btn1 new-ord-btn1' to={'/register'} tabIndex={-1} onClick={this.newTransactionBtnClicked}>
                                    {"New Transaction"}
                                </Link>
                                <ReactToPrint trigger={() => {return (
                                    <button className='btn1 b100 print-receipt-btn ' style={{margin:'8px 0px'}} tabIndex={-1} >
                                        <PrintIcon sx={{marginRight:'6px'}}/> {" Print Receipt"}
                                    </button>)}}
                                    content={() => this.printableDivRef}/>
                            </div>}
                            <div class='reciept-div' style={{maxWidth:'300px',width:'1px',height:'0px',overflow:'hidden'}}>
                                <div class='receipt-wrapper'>
                                    <div class='receipt-div' style={{}}>
                                        <div className='printable-div' ref={el => (this.printableDivRef = el)}>
                                            <ReceiptBasic orderInfo={this.state.orderInfo} exchange={this.props.exchange}/>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                     </div>
                </div>
            </div>
        );
    }
}

export default SaleComplete;