import React, { Component } from 'react';
import GeneralSettings from './GeneralSettings';
import ReceiptSettings from './ReceiptSettings';
import SubscriptionSettings from './SubscriptionSettings';
import './SettingsPage.css';
import PosHeader from '../pos/PosHeader';
import CacheService from '../../service/CacheService';

class StaffSettings extends Component{

    render() {
        return (
            <div class='staff-settings'>
                <h1>Staff</h1>
            </div>
        );
    }

}
const LAST_SETTINGS_VIEWED='_LAST_SETTINGS_VIEWED';
const PAGE_NAME='SETTINGS';
const PAGES=Object.freeze({GENERAL:'GENERAL',RECEIPT:'RECEIPT',STAFF:'STAFF',SUBSCRIPTION:'SUBSCRIPTION'});
class SettingsPage extends Component {

    constructor(props) {
        super(props);
        let lastPage=CacheService.getPageCacheItem(PAGE_NAME,LAST_SETTINGS_VIEWED);
        this.state = {
            currentPage:lastPage||PAGES.GENERAL
        }
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
    }

    setPage(page){
        CacheService.setPageCacheItem(PAGE_NAME,LAST_SETTINGS_VIEWED,page);
        this.setState({currentPage:page});
    }

    render() {  
        let content = null;
        switch(this.state.currentPage){
            case PAGES.GENERAL: content = <GeneralSettings {...this.props}/>; break;
            case PAGES.RECEIPT: content = <ReceiptSettings {...this.props}/>; break;
            case PAGES.STAFF:   content = <StaffSettings {...this.props}/>; break;
            case PAGES.SUBSCRIPTION: content = <SubscriptionSettings {...this.props}/>; break;
            default: break;
        }
        return (
            <div class='settings-page page' style={{}}>

                <PosHeader 
                    title={'Settings'}
                    exchange={this.props.exchange}
                    content={
                        <div class='page-section-btns'>
                            <button class={'console-btn1'+(this.state.currentPage===PAGES.GENERAL?' console-btn1-active':'')}
                                 onClick={()=>this.setPage(PAGES.GENERAL)}>
                                General
                            </button>
                            <button class={'console-btn1'+(this.state.currentPage===PAGES.RECEIPT?' console-btn1-active':'')}
                                 onClick={()=>this.setPage(PAGES.RECEIPT)}>
                                Receipt Details
                            </button>
                            {/* <button class={'console-btn1'+(this.state.currentPage===PAGES.STAFF?' console-btn1-active':'')}
                                 onClick={()=>this.setPage(PAGES.STAFF)}>
                                Staff
                            </button> */}
                            <button class={'console-btn1'+(this.state.currentPage===PAGES.SUBSCRIPTION?' console-btn1-active':'')}
                                 onClick={()=>this.setPage(PAGES.SUBSCRIPTION)}>
                                Subscription
                            </button>
                        </div>
                    }
                />
                 
                <div class='page-body' style={{display:'grid'}}>
                    {content}
                </div>
            </div>
        );
    }
}

export default SettingsPage;