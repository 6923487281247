import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import {INVENTORY_TYPE} from '../../constants';
import TextField from '@mui/material/TextField';
import {BUSINESS_NAME_LIMIT} from '../../constants';
import FirebaseService from '../../service/FirebaseService'
import CloseableModal from '../../components/CloseableModal';
import NotificationUtil from '../../util/NotificationUtil'
class InventoryTypeButton extends Component {

    render() {
        return (
            <div class='inv-type-btn' 
                onClick={this.props.onClick}
                style={this.props.highlighted?{border:'solid 2px rgba(0,0,0,0.5)',backgroundColor:'rgb(250, 250, 250)'}:{}}>
                <p class='c-h4'>{this.props.label}</p>
                <p class='c-p1'>{this.props.description}</p>
            </div>
        );
    }
}
class WelcomeModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
             name:''
            ,inventoryType:INVENTORY_TYPE.RETAIL

        }
    }

    changeInputHandler=(event)=>{
        switch(event.target.id){
            case 'name-input':
                if(event.target.value.length<BUSINESS_NAME_LIMIT){   
                    this.setState({name:event.target.value}); 
                } break;
        default:
                break;
        }
    }

    saveClicked=()=>{
        let businessId = this.props.exchange.storedSession.business.id;
        let phone = null;
        let email = null;
        let address = null;
        FirebaseService.updateBusiness(businessId, this.state.name, this.state.inventoryType, phone, email, address)
        .then(()=>{
                this.props.onClose(); 
                this.props.exchange.refreshCache();
                NotificationUtil.makeToast('Welcome to Cashr POS!','rgb(55,66,71)','white',2000,'top-right');
            });
    }

    renderInventoryTypeSelect=()=>{
        return ( <div>
            <p class='c-in1'>Inventory Type</p>
            <div class='inventory-type-select' >
                <InventoryTypeButton 
                    label={"Retail"}
                    description={"For Mini Grocery, Pharmacy, or any kind of store"}
                    onClick={()=>{this.setState({inventoryType:INVENTORY_TYPE.RETAIL})}}
                    highlighted={this.state.inventoryType===INVENTORY_TYPE.RETAIL}/>
                <InventoryTypeButton
                    label={"Ingredients Based"} 
                    description={"For Restaurant, Cafe, Milk Tea Shop (or any Food Business!)"}
                    onClick={()=>{this.setState({inventoryType:INVENTORY_TYPE.INGREDIENTS_BASED})}}
                    highlighted={this.state.inventoryType===INVENTORY_TYPE.INGREDIENTS_BASED}/>
                <InventoryTypeButton
                    label={"Service Based"} 
                    description={"For Salons, Barbershops, Clinics, or any other Services"}
                    onClick={()=>{this.setState({inventoryType:INVENTORY_TYPE.SERVICE_BASED})}}
                    highlighted={this.state.inventoryType===INVENTORY_TYPE.SERVICE_BASED}/>
            </div>
        </div>)
    }

    render() {
        let canSave = this.state.name!=='';
        let isFullScreen = isMobile;
        return (
            <CloseableModal
                content={
                    <div class='welcome-modal-wrapper window' style={isFullScreen?{width:'100%',maxHeight:'100%',borderRadius:'0px'}:{}}>
                        <div class='welcome-modal'>
                            <div class='hdr'>
                                <h1 class='c-h2'>Welcome to Cashr!</h1>
                                <h1 class='c-p1'>Let's setup your POS.</h1>
                            </div>
                            <div class='input-cont'>
                                <br/>
                                <p class='c-in1'>Business Name</p>
                                <TextField id="name-input" required placeholder={"Name of your business"}
                                    sx={{width:'100%'}}
                                    value={this.state.name} onChange={this.changeInputHandler}/>
                                
                                    
                                <br/> <br/>
                               
                                {/* {this.renderInventoryTypeSelect()} */}
                                <br/>
                                <button class={'btn1 b100 ' + (canSave?'':' -btn1-disabled')} onClick={this.saveClicked}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
        );
    }
}

export default WelcomeModal;