import React, { Component } from 'react';
import { isMobile, BrowserView, MobileView } from 'react-device-detect';
import {DRINK_ICONS,MEAL_ICONS,DESSERT_ICONS,OTHER_ICONS,ICON_SIZE} from '../../../assets/item-icons/ItemIcons.js';
class ItemImageSelect extends Component {

    getIconGroup=(grp,grpName,size)=>{
        let divs=[];
        let iconTypeStyle={borderBottom:'solid 1px gainsboro',padding:'10px', color:'rgb(50,50,50)', margin:'10px 60px 10px 60px',fontWeight:'800'};
        divs.push(<p style={iconTypeStyle}>{grpName}</p>);
        Object.keys(grp).forEach((iconKey,idx)=>{
            let src=grp[iconKey][size];
            let isSelected = this.props.selectedImage!=null &&
                             this.props.selectedImage.val.group===grpName && this.props.selectedImage.val.id===iconKey;
            divs.push(
                <div className='img-sel-d' style={isSelected?{backgroundColor:'rgb(244, 241, 255)'}:{}} onClick={()=>this.props.setImage({type:'icon',val:{id:iconKey,group:grpName}})}>
                    <img src={src} alt='item select'/>
                    <p style={{fontSize:'0.55rem',color:'gray'}}>{iconKey}</p>
                </div>
            )
        });
        return divs;
    }

    getGenericImgs=()=>{

        return (
            <div className='generic-imgs'>
                {this.getIconGroup(DRINK_ICONS,'Drinks',ICON_SIZE.SMALL)}
                {this.getIconGroup(MEAL_ICONS,'Meal',ICON_SIZE.SMALL)}
                {this.getIconGroup(DESSERT_ICONS,'Desserts',ICON_SIZE.SMALL)}
                {this.getIconGroup(OTHER_ICONS,'Others',ICON_SIZE.SMALL)}
            </div>
        );
    }
    
    render() {
        let genericImgs = this.getGenericImgs();
        return (
            <div className={'item-image-select ' + (isMobile?'window-fullscreen':'window')}>
                <BrowserView><h2 class='c-h2'>Select Image </h2></BrowserView>
                <MobileView><h2 class='c-h4'>Select Image</h2></MobileView>
                <br/>
                <div className='generic-imgs' style={{textAlign:'center'}}>
                    {genericImgs}
                </div>
            </div>
        );
    }
}

export default ItemImageSelect;