import { getApp } from "firebase/app";

import { getFunctions, httpsCallable, connectFunctionsEmulator} from "firebase/functions";

const app = getApp();
const functions = getFunctions(app, "asia-northeast1");
const createProductPaymentTransaction = httpsCallable(functions, 'createProductPaymentTransaction');
// use this only when trying function via emulator/local
// connectFunctionsEmulator(functions, "127.0.0.1", 5001);
const PAYMENT_REQUEST_TYPES=Object.freeze({SUBSCRIPTION:'SUBSCRIPTION'});
const PAYMENY_GATEWAY=Object.freeze({MAYA:'MAYA'});

class PaymentService {
    
    checkout=async(product, priceId, discountId, storedSession, successCallback, failCallback)=>{

        let request = {
            productId:product.id
           ,priceId:priceId
           ,discountId:discountId
           ,gateway:PAYMENY_GATEWAY.MAYA
           ,businessId:storedSession.business.id
           ,type:PAYMENT_REQUEST_TYPES.SUBSCRIPTION
       }
       await createProductPaymentTransaction(request).then(response=>{
        console.log('function:createProductPaymentTransaction response.data',response.data)
        if(response!=null && response.data!=null && response.data.responseCode===200 && response.data.redirectUrl!=null){
            successCallback(response.data.redirectUrl)
        }
        }).catch(error=>{
            console.log('function:createProductPaymentTransaction error',error)
            failCallback()
        })
    }
}
export default new PaymentService()