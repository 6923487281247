import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import 'animate.css';
import Modal from 'react-modal'; 
import { getDatabase, ref, onValue } from "firebase/database";
import './RegisterPage.css';
import Cart from '../cart/Cart';
import TotalWindow from './TotalWindow';
import history from '../../../history';
import PosHeader from './../PosHeader';
import ItemImage from './../ItemImage';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import ItemUtil from '../../../service/ItemUtil';
import TuneIcon from '@mui/icons-material/Tune';
import CartService from '../../../service/CartService';
import CancelIcon from '@mui/icons-material/Cancel';
import VariantSelect from '../../windows/VariantSelect';
import NotificationUtil from '../../../util/NotificationUtil';
import CloseableModal from '../../../components/CloseableModal';
import {isMobileOnly, isMobile} from 'react-device-detect';

import TextField from '@mui/material/TextField';
const db = getDatabase(); 
const KB_ENTER_KEY = 13;
const TOTAL_WINDOW_URI = 'show-total';
const CART_WINDOW_URI = 'cart';
const VARIANT_SELECT_WINDOW_URI = 'variant';
export const SIDE_ACTION_WIDTH = 86;
export const SIDE_ACTION_WIDTH_M = 70;
class RegisterPage extends Component {
    constructor(props) {
        super(props);
        this.checkoutBtnRef = React.createRef(); 
        this.filterCategoryBtnref = React.createRef();
       
        this.state = {
             itemCategoryFilter:null
            ,inventory:{}
            ,KEY_SHORTCUTS:{CHECKOUT:KB_ENTER_KEY}
            ,width:window.innerWidth
            ,searchItem:''
            ,showFilterModal:false
        } 
        this.state.categoryOptions = [];
        if(this.props.exchange.storedSession.business!=null && this.props.exchange.storedSession.business.menu!=null){
            Object.keys(this.props.exchange.storedSession.business.menu.category).forEach(catId=>{
                this.state.categoryOptions.push({
                     id:catId
                    ,name:this.props.exchange.storedSession.business.menu.category[catId]
                })
            })
        }
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        
        //listener for the back button
        this.unlisten = history.listen((location, action) => {
            if(location.location.pathname==='/register'){
                this.forceUpdate();
            }
          });
        window.addEventListener('resize', this.updateDimensions);

        //listen to database updates
        const invetoryRef = ref(db, 'inventory/' + this.props.exchange.storedSession.business.id);
        onValue(invetoryRef, (snapshot) => {
            const data = snapshot.val();
            this.setState({inventory:data});
        });

        //Listen to keyboard
        document.addEventListener("keydown", this.handleKeyDown);
 
    }

    componentWillUnmount() {
        this.unlisten();
        window.removeEventListener('resize', this.updateDimensions);
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown = (event) => {
        // console.log('keyCode',event.keyCode);
        switch(event.keyCode) {
            case this.state.KEY_SHORTCUTS.CHECKOUT:
                if(this.checkoutBtnRef.current!=null){
                    this.checkoutBtnRef.current.click();
                }
            break;
            default: 
                break;
        }
    }
    
    updateDimensions = () => {
         this.setState({width:window.innerWidth});
    }


    itemClicked=(item,inStock,qty,hasVariant)=>{

        let hasStockAndCanAddToCart = (item.trackStock && inStock-qty-1>=0);
        let canAdd = item.trackStock==null || item.trackStock===false || hasStockAndCanAddToCart;

        if(canAdd){
            if(hasVariant===false){
                let cartItems   = CartService.getCartItems();
                let indexInCart = cartItems.map(cartEntry=>cartEntry.item.id).indexOf(item.id);
                if(indexInCart>=0) {
                    //if existing in cart, just increment qty
                    cartItems[indexInCart].qty = cartItems[indexInCart].qty+1;
                } else {
                    // add new entry to cart
                    let cartEntry = {item:item,qty:1};
                    cartItems.push(cartEntry);
                }
                //Animate order qty bubble
                const qtyBubble = document.querySelector('.item-qty-bubble-'+item.id);
                if(qtyBubble!=null){
                    qtyBubble.classList.add('animate__animated', 'animate__bounceIn','animate__faster');
                    qtyBubble.addEventListener('animationend', () => {
                        qtyBubble.classList.remove('animate__animated', 'animate__bounceIn');
                    });
                }
                CartService.saveCartItems(cartItems,()=>{this.forceUpdate()});
            }
           
        }
        else { 
            const stockCountElement = document.querySelector('.stock-count-elem-'+item.id);
            if(stockCountElement!=null){
                stockCountElement.classList.add('animate__animated', 'animate__headShake','animate__faster');
                stockCountElement.addEventListener('animationend', () => {
                    stockCountElement.classList.remove('animate__animated', 'animate__headShake');
                });
            }
            if(!isMobile){
                NotificationUtil.makeToast('Insufficient stock','rgb(228,34,19)','white',3000,'top-center')
            }
        }
    }
    

    onVariantSelect=(baseItem, variantItem)=>{
        let cartItems = CartService.getCartItems();

        let indexInCart=cartItems.map(cartEntry=>cartEntry.item.variantId).indexOf(variantItem.id);

        if(indexInCart>=0){
            //if existing in cart, just increment qty
            cartItems[indexInCart].qty = cartItems[indexInCart].qty+1;
        } else {
            // add new entry to cart
            let variantEnrichedItem = JSON.parse(JSON.stringify(baseItem));
            variantEnrichedItem.variantName=variantItem.name;
            variantEnrichedItem.cost=variantItem.cost;
            variantEnrichedItem.price=variantItem.price;
            variantEnrichedItem.variantId=variantItem.id;
            variantEnrichedItem.name=baseItem.name + ' (' + variantItem.name + ')';
            let cartEntry = {item:variantEnrichedItem,qty:1};
            cartItems.push(cartEntry);
        }
        
        CartService.saveCartItems(cartItems,()=>this.forceUpdate());
    }

    getStock=(item)=>{
        let inStock = 0;
        if(this.state.inventory!=null && this.state.inventory.stock!=null && 
            this.state.inventory.stock.items!=null && this.state.inventory.stock.items[item.id]!=null) {
             inStock=this.state.inventory.stock.items[item.id].inStock;
        }
        return inStock;
    }

    getTotalVariantStock=(item)=>{

        let inStock = 0;
        if(this.state.inventory!=null && this.state.inventory.stock!=null && this.state.inventory.stock.items!=null) {

            let variantKeys = Object.keys(item.variants);
            variantKeys.forEach(k=>{
                if(this.state.inventory.stock.items[k]!=null){
                    // negative stock could affect the total instock, so this check is needed:
                    if(this.state.inventory.stock.items[k].inStock!=null && this.state.inventory.stock.items[k].inStock>0){
                        inStock+= this.state.inventory.stock.items[k].inStock
                    }
                }
            });
        }

        return inStock;
    }

    getHighlightedText=(text, highlight)=> {
        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return <span> {
             parts.map((part, i) => 
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { backgroundColor: 'rgb(255,224,2)' } : {} }>
                { part }
            </span>)
        } </span>;
    }

    renderItems=(cartItems)=>{
        let itemDivs=[];
        if(this.props.exchange.storedSession==null
            || this.props.exchange.storedSession.business==null
            || this.props.exchange.storedSession.business.menu==null
            || this.props.exchange.storedSession.business.menu.items==null
            )return;
        let hasSearch = this.state.searchItem!=='';
        let items = this.props.exchange.storedSession.business.menu.items;
        if(this.state.itemCategoryFilter!=null){
            items=items.filter(it=>it.categoryId===this.state.itemCategoryFilter.id)
        }

        if(hasSearch){
            items=items.filter(it=>it.name.toLowerCase().includes(this.state.searchItem.toLowerCase())||
            (it.barcode!=null&&it.barcode.toLowerCase().includes(this.state.searchItem.toLowerCase()))||
            (it.sku!=null&&it.sku.toLowerCase().includes(this.state.searchItem.toLowerCase())));
        }
        items.forEach((item,idx)=>{
            let barcodeSearchMatch= false;
            let skuSearchMatch=false;

            if(hasSearch){
                barcodeSearchMatch = item.barcode.toLowerCase().includes(this.state.searchItem.toLowerCase())
                skuSearchMatch = item.sku.toLowerCase().includes(this.state.searchItem.toLowerCase())
            }

            let hasVariants = item.variants!=null;
            let qty = cartItems.filter(or=>or.item.id===item.id)
                        .map(it=>it.qty).reduce((partialSum, a) => partialSum + a, 0);
            
            let inStock = null;
            if(item.trackStock){
                inStock=hasVariants?
                        this.getTotalVariantStock(item)
                        :this.getStock(item);
            }
          
            let limitReached = item.trackStock && inStock === qty && inStock>0;
            let hasVariant = ItemUtil.hasVariants(item);

            let canAddToCart = (item.trackStock==null||item.trackStock===false)
                                        ||(item.trackStock && inStock-qty-1>=0);
            itemDivs.push(
                <Link className={(isMobileOnly?'register-item-mobile':'register-item') + (item.trackStock&& (inStock===0||limitReached)?' reg-item-outofstock':'')} 
                     to={hasVariant&&canAddToCart?'/register/'+VARIANT_SELECT_WINDOW_URI+'/'+item.id:null}
                     onClick={()=>this.itemClicked(item,inStock,qty,hasVariant)}

                    style={{marginBottom:(barcodeSearchMatch||skuSearchMatch)?'44px':''}}>
                       {qty>0&&
                       <div class='cart-qty-cont' style={{position:'relative'}}>
                            <div style={{position:'absolute', width:'100%',display:'flex', alignItems:'right',justifyContent:'right',marginLeft:'6px',marginTop:'-6px'}}>
                                <div class={'cart-qty no-select item-qty-bubble-'+item.id} style={isMobileOnly?{width:'24px',height:'24px',fontSize:'0.8rem'}:{}}>
                                   {qty}
                                </div>
                            </div>
                        </div>}
                        <div class={isMobileOnly?'item-img-wrapper-m no-select':'item-img-wrapper no-select'} style={{minHeight:'62%',height:'62%'}}> 
                           <ItemImage image={item.image} size={isMobile?'small':'medium'} opacity='0.1'/>
                        </div>

                        <div class='item-dits'>  
                            <Tooltip title={item.name} placement='top-start'>
                                <p className='reg-item-name no-select' style={isMobile?{fontSize:'0.85rem'}:{}}>{item.name}</p>
                            </Tooltip>
                            <p class={isMobileOnly?'reg-item-price-m no-select':'reg-item-price no-select'} style={item.price==null?{opacity:'0.5',fontWeight:'200'}:{}}>{item.price!=null?(this.pesoWithCommas(item.price)):'Variant'}</p>
                            {item.trackStock ?
                            <p className={'stock-count-elem-'+item.id} style={{ fontSize:'12px',height:'18px', color:inStock>0?'rgb(66,43,163)':'gray',marginTop:'1px'}}>
                                {inStock>0?'Stock: '+inStock:'Out of stock'}
                               
                                {limitReached&&isMobileOnly===false&&
                                <span style={{fontSize:'11px', backgroundColor:'rgb(140,140,140)', marginLeft:'4px', padding:'2px 4px', borderRadius:'3px',color:'white',marginTop:'1px'}}>
                                    {'limit reached'}
                                </span>}
                            </p>:
                            <div style={{height:'18px'}}></div>}
                           
                        </div>

                        {(hasSearch && (barcodeSearchMatch||skuSearchMatch)) &&
                        <p style={{position:'absolute', marginTop:'8px',fontSize:isMobile?'10px':'12px',backgroundColor:'transparent',padding:'2px 4px'}}>
                            {barcodeSearchMatch&&
                                <span> 
                                    {!isMobile&&<span>{'Barcode:'}</span>}
                                    {this.getHighlightedText(item.barcode,this.state.searchItem)}
                                </span>}

                            {skuSearchMatch&&
                                <p> 
                                    {!isMobile&&<span>{'SKU:'}</span>}
                                    {this.getHighlightedText(item.sku,this.state.searchItem)}
                                </p>}
                           
                        </p>}
                </Link>
            );
        });

        

        return (
            <div className='item-select-div' style={{
                width:'100%',
                height:'100%',
                justifyContent: 'center',
                marginTop:'10px',
                paddingBottom:'30px',
                alignItems: 'center',
                textAlign: 'center'}}>
                {itemDivs}
            </div>
        )
    }

    renderActionDiv=()=>{

        let hasFilters=this.state.itemCategoryFilter!=null;
        return(
            <div className='item-action-div' style={{position:'sticky', marginTop:'12px', paddingBottom:'1vh', paddingRight:'2vw'}}>
            <div style={{display:'flex',justifyContent:'right'}}>
                <div style={{justifyContent:'right',textAlign:'left'}}>
                <TextField 
                    id="search-item-input" 
                    variant="outlined"
                    placeholder='Filter items'
                    value={this.state.searchItem}
                    onChange={this.changeInputHandler}
                    inputProps={{ tabIndex:-1 }}
                    color='success'
                    sx={{width:'30%',minWidth:'200px',marginRight:'6px', input: {fontSize:'0.9rem',  padding:'6px 10px'}}}
                />
                {this.state.itemCategoryFilter!=null&&
                    <div className='current-cat-filter-btn' onClick={()=>this.setState({itemCategoryFilter:null})}>
                        <CancelIcon sx={{fontSize:'16px',marginRight:'4px'}}/>
                        <p>{this.state.itemCategoryFilter.name}</p>
                    </div>}
            </div>
                <div className='ibtn2 filter-bt' onClick={()=>this.setState({showFilterModal:true})} ref={this.filterCategoryBtnref}>
                    <TuneIcon sx={hasFilters?{color:'rgb(66,43,163)',backgroundColor:'rgb(242,238,255)'}:{}}/>
                </div>
            </div>
        
        </div>
        )
    }

    getCategoryFilterModalStyle=()=>{
        const filterCategoryBtnDom = ReactDOM.findDOMNode(this.filterCategoryBtnref.current);
        let contentTop=140;
        if(filterCategoryBtnDom!=null){
            let divClientRect = filterCategoryBtnDom.getBoundingClientRect();
            contentTop=divClientRect.bottom;
        }
        return {
            content : {
                 transform:'translate(0%, 0%)', top:contentTop,  left:'auto', right:isMobileOnly?'':'2vw', width:isMobileOnly?'100%':'', bottom:'auto',backgroundColor:'transparent'
                ,padding:'0px', border:'none',borderRadius:'4px'
            },
            overlay: { 
                transition:'0.4s',zIndex:'100' ,backgroundColor:'rgba(0,0,0,0.6)'
            }
        };
    }


    pesoWithCommas=(x)=>{
        let roundedX= Math.round((x + Number.EPSILON) * 100) / 100;
        return '₱'+roundedX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
 
    setItemCategoryFilter=(categoryObj)=>{
        this.setState({itemCategoryFilter:categoryObj,showFilterModal:false});
    } 

    changeInputHandler=(event)=>{
        switch(event.target.id){
            case 'search-item-input':this.setState({searchItem:event.target.value}); break;
            default:break;
        }
    }
    

    noItemsNotif=()=>{
        return(
            <div style={{textAlign:'center',width:'100%',padding:'10vh 10vw'}}>
                <h2>No items yet 🤷</h2>
                <span style={{color:'gray',marginTop:'6px'}}>Add inventory to start making transactions. </span>
                {isMobileOnly&&<div><br/><br/></div>}
                <Link class='link-1' to='/items' style={{}}>Click here to add items</Link>
                <span>.</span>
            </div>
        )
    }
  
 
    render() {
        
        let cartItems = CartService.getCartItems();
        let hasItems = this.props.exchange.storedSession.business.menu!=null 
                     && this.props.exchange.storedSession.business.menu.items!=null
                     && this.props.exchange.storedSession.business.menu.items.length>0;
        
        let itemOnVariantSelect = null;
        if(this.props.windowId===VARIANT_SELECT_WINDOW_URI && this.props.param2!=null){
            itemOnVariantSelect=this.props.exchange.storedSession.business.menu.items.filter(it=>it.id===this.props.param2)[0]
        }

        return (
            <div className='page register-page' style={{display:'grid', alignContent:'space-between'}} >
               
                <PosHeader 
                    title={'Register'}
                    exchange={this.props.exchange}
                    content={this.renderActionDiv()}
                    />

                <div className='page-body' style={{display:'flex',backgroundColor:'rgb(251,251,253)'
                ,overflow:'hidden',height:'100vh'//not sure about this
                }}>
                    {hasItems? this.renderItems(cartItems) : this.noItemsNotif()}

                    <div className='reg-side-action' style={{backgroundColor:'rgb(244,244,246)',minWidth:(isMobile?SIDE_ACTION_WIDTH_M:SIDE_ACTION_WIDTH)+'px'}}>
                        <button className={'btn1 rega-btn ' + (hasItems===false?'rega-btn-disabled':'')} tabIndex={-1} onClick={()=>CartService.clearCartItems(()=>this.forceUpdate())}>Clear</button>
                        <div style={{height:'2px',margin:'4px 0px'}}></div>
                        <Link className={'btn1 rega-btn ' + (hasItems===false?'rega-btn-disabled':'')} tabIndex={-1} style={{borderTop:'solid 1px gainsboro'}}  to='/register/cart' onClick={()=>this.forceUpdate()}>Cart</Link>
                        <Link className={'btn1 rega-btn ' + (cartItems.length===0?'rega-btn-disabled':'')} tabIndex={-1}  to={'/register/'+TOTAL_WINDOW_URI}>Total</Link>
                    </div>
                </div>

                
                <div className='action-btns' style={{position:'sticky',bottom:this.props.exchange.hasSideBar?'0px':'38px', display:'flex',width:'calc(100% - ' + (isMobile?SIDE_ACTION_WIDTH_M:SIDE_ACTION_WIDTH) + 'px)'}}>
                    <div style={{width:'100%',margin:'0px 10px',justifyItems:'center',alignContent:'center',display:'grid',marginBottom:'12px'}}>

                        {cartItems.length>0&&
                        <Link class={isMobile?'btn1 checkout-btn-mobile':'btn1 checkout-btn'} ref={this.checkoutBtnRef}  tabIndex={-1}
                            onClick={cartItems.length===0?()=>{NotificationUtil.makeToast('Cart is empty!','white','black',3000,'top-center');}:null}
                            to={cartItems.length>0?'/checkout':'#'}  >
                        {'Checkout'}
                        </Link>}
                    </div>
                </div>

                <Modal id="item-view-modal" 
                    shouldCloseOnOverlayClick={true} 
                    isOpen={this.state.showFilterModal}
                    shouldCloseOnEsc={true}
                    style={this.getCategoryFilterModalStyle()}
                    onRequestClose={()=>this.setState({showFilterModal:false})}
                    >
                    <FilterModal
                        categoryOptions={this.state.categoryOptions}
                        itemCategoryFilter={this.state.itemCategoryFilter}
                        setItemCategoryFilter={this.setItemCategoryFilter}
                        close={()=>this.setState({showFilterModal:false})}
                        // currentViewType={this.state.viewType}
                        // setViewType={this.setViewType}
                    />
                           
                </Modal>    
                

                {this.props.windowId===TOTAL_WINDOW_URI&&
                    <TotalWindow
                        onCloseLink={'/register'}
                    />}

                {this.props.windowId===CART_WINDOW_URI&&
                <CloseableModal
                     content={
                        <Cart
                            floating={true} 
                            showCheckout={false}
                            onCancelLink={'/register'}
                            parentUpdate={()=>{this.forceUpdate()}}  
                            inventory={this.state.inventory}
                            /> 
                     }
                    // onCancelLink={'/register'}
                />}

                
                {itemOnVariantSelect&&
                    <CloseableModal 
                        content={
                            <VariantSelect
                                item={itemOnVariantSelect}
                                variantClicked={this.onVariantSelect}
                                onCancelLink={'/register'}
                                cartItems={cartItems}
                                inventory={this.state.inventory}
                            />}
                        onCancelLink={'/register'}
                    />
                }
            </div>
        );
    }
}

class FilterModal extends Component {
    render(){

        let categoryOptionBtns=[];  
            categoryOptionBtns.push(
                <button class={'console-btn1'+(this.props.itemCategoryFilter===null?' console-btn1-active':'') + (isMobileOnly?' console-btn1-mobile':'')} style={{width:'120px'}}
                    onClick={()=>this.props.setItemCategoryFilter(null)}  tabIndex={-1} >
                    All
                </button>
        ); 
        this.props.categoryOptions.forEach((cat,idx)=>{
            categoryOptionBtns.push(
                <button class={'console-btn1'+(this.props.itemCategoryFilter!=null&&this.props.itemCategoryFilter.id===cat.id?' console-btn1-active':'') + (isMobileOnly?' console-btn1-mobile':'')}
                    onClick={()=>this.props.setItemCategoryFilter({id:cat.id,name:cat.name})}  tabIndex={-1} >
                {cat.name}
            </button>
            );
        });

        return(
            <div className='filter-modal'>
                <div className='filter-modal-body'>
                    <div className='item-category-selector'>
                        <div style={{display:'flex',justifyContent:'space-between',marginBottom:'6px'}}>
                            <p style={{fontSize:'12px',marginBottom:'4px'}}>Filter by Category</p>
                            <div className='ibtn3' style={{marginTop:'3px'}} onClick={this.props.close}>
                                <CancelIcon sx={{fontSize:'18px',marginRight:'4px'}}/>
                            </div>
                        </div>
                        <div className='cat-opt-btns'>
                            {categoryOptionBtns}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default RegisterPage;