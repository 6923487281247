import React, { Component } from 'react';
import './Sidebar.css';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import AuthService from '../service/AuthService';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/Inbox';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import FirebaseService from '../service/FirebaseService';
import InventoryIcon from '@mui/icons-material/Inventory';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ArrowBackIosTwoToneIcon from '@mui/icons-material/ArrowBackIosTwoTone';
import {SECTION} from '../constants';

class SectionButton extends Component {
 
    render(){
        let isExpanded = this.props.sectionProp.isExpanded;
        let isCurrent = this.props.sectionProp.currentSection===this.props.section;
        return (
            <Link class='section-btn' to={this.props.section.path} onClick={this.props.onClick} tabIndex={-1}
                style={isExpanded?{borderLeft:isCurrent?'solid 4px white':'solid 4px transparent'}:{paddingLeft:'14px',borderLeft:isCurrent?'solid 4px white':'solid 4px transparent'}} >
                
            <Tooltip title={this.props.section.label} placement='right'>
                <div style={{display: 'flex',width:isExpanded?'185px':'30px'}}>
                    <IconButton sx={{padding:'0px',color:'rgb(225,225,240)'}} aria-label="close" tabIndex={-1}>
                    {this.props.icon}
                    </IconButton> 
                    {isExpanded&&
                    <p class='section-btn-lbl'>{this.props.section.label}</p>}
                </div>
            </Tooltip>
            </Link>
        )
    }
}
class SideBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
             width:window.innerWidth
            ,isExpanded:this.getSavedUserSettings()
            ,section: SECTION.REGISTER
        }

        switch(window.location.pathname){
            case SECTION.TRANSACTIONS.path: this.state.section=SECTION.TRANSACTIONS;break;
            case SECTION.REGISTER.path:     this.state.section=SECTION.REGISTER;break;
            case SECTION.CASH_DRAWER.path:  this.state.section=SECTION.CASH_DRAWER;break;
            case SECTION.ITEMS.path:        this.state.section=SECTION.ITEMS;break;
            case SECTION.SETTINGS.path:     this.state.section=SECTION.SETTINGS;break;
            case SECTION.INVENTORY.path:    this.state.section=SECTION.INVENTORY;break;
            default: this.state.section=SECTION.TRANSACTIONS;break;
        }

        
    }

    async componentDidMount(){
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.updateDimensions);
    }

    getSavedUserSettings=()=>{ 
        let userBusinessObj = this.props.exchange.storedSession.user.business[this.props.exchange.storedSession.business.id];
        if(userBusinessObj.settings!=null && userBusinessObj.settings.sidebarOpen!=null){
            return userBusinessObj.settings.sidebarOpen;
        } else {
            return true;
        } 
    }
    
    toggleOpenSideBar=async()=>{
        this.setState({isExpanded:!this.state.isExpanded},()=>{
            FirebaseService.saveUserSettings(this.props.exchange.storedSession.user.uuid,this.props.exchange.storedSession.business.id, {sidebarOpen:this.state.isExpanded})
            .then(()=>{
                console.log('Success:')
            }).catch(err=>{
                console.log('SB0001:',err)
            })
        })
    }

    updateDimensions = () => {
        if(this.state.width !== window.innerWidth){
            this.setState({ width:window.innerWidth});
    }
}
  
    logoutClicked=()=>{
        AuthService.logout();
    }

    shouldAutoClose=()=>{
       return this.state.width<690;
    }

    render() {
        let shouldAutoClose = this.shouldAutoClose();
        let isExpanded = this.state.isExpanded && !shouldAutoClose ;
        
        let sectionProp = {
             isExpanded:isExpanded
            ,currentSection:this.state.section
        }

        return (
            <div className='side-bar' style={isExpanded?{width:'220px',minWidth:'220px'}:{width:'55px',minWidth:'55px'}}>
                <div class='app-title'>
                    {isExpanded&&
                    <h2 class='logo' style={{minWidth:'110px', fontWeight:'500', fontSize:'1.4rem',color:'white'}}>Cashr POS</h2>}
                    {!shouldAutoClose&&
                    <IconButton onClick={this.toggleOpenSideBar}   tabIndex={-1} sx={{"&:hover": {color:'rgb(106,190,55)'}, paddingLeft:'10px', cursor:'pointer',  transition:'0.6s',color:'white',rotate:isExpanded?'0deg':'180deg'}} aria-label="close">
                        <ArrowBackIosTwoToneIcon fontSize="medium"/>
                    </IconButton> }
                </div>
                {this.props.exchange.noInternet&&
                <div style={{backgroundColor:'rgb(30,30,30)',padding:'2px 10px'}}>
                    <p style={{color:'rgb(190,190,190)',fontSize:'13px'}}>Offline</p>
                </div>}
 
                <p class='business-name' style={{width:'220px',opacity:isExpanded?'':'0'}}>
                    {this.props.exchange.storedSession.business.name}
                </p>
                <br/>
                <div>
                
                </div>

                <SectionButton section={SECTION.TRANSACTIONS}  sectionProp={sectionProp} default={true} onClick={()=>{this.setState({section:SECTION.TRANSACTIONS})}}
                        icon=<ReceiptIcon fontSize="medium" /> />
 
                <SectionButton section={SECTION.REGISTER} sectionProp={sectionProp} onClick={()=>{this.setState({section:SECTION.REGISTER})}}
                        icon=<PointOfSaleIcon fontSize="medium" /> />
                
                <SectionButton section={SECTION.CASH_DRAWER}  sectionProp={sectionProp} onClick={()=>{this.setState({section:SECTION.CASH_DRAWER})}}
                        icon=<InboxIcon fontSize="medium" /> />
                
                <SectionButton section={SECTION.ITEMS}  sectionProp={sectionProp} onClick={()=>{this.setState({section:SECTION.ITEMS})}}
                        icon=<FormatListBulletedIcon fontSize="medium" /> />

                <SectionButton section={SECTION.INVENTORY}  sectionProp={sectionProp} onClick={()=>{this.setState({section:SECTION.INVENTORY})}}
                        icon=<InventoryIcon fontSize="medium" /> />

                <SectionButton section={SECTION.SETTINGS}  sectionProp={sectionProp} onClick={()=>{this.setState({section:SECTION.SETTINGS})}}
                        icon=<SettingsIcon fontSize="medium" /> />
{/*                 
                <div style={{position:'absolute',top:'auto',bottom:'0',borderTop:'solid 1px rgba(255,255,255,0.1)', width:'100%'}}>    
                    <SectionButton section={{label:'Log-out',path:'/'}} onClick={this.logoutClicked} sectionProp={sectionProp} 
                        icon=<LogoutIcon fontSize="medium" /> />
                </div>  */}

            </div>
        );
    }
}

export default SideBar;