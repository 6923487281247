import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import {isMobile} from 'react-device-detect';
import {ALLOW_DUPLICATE_VARIANT_NAME} from '../../../constants';

class VariantCreate extends Component {
   
    constructor(props) {
        super(props);
        this.firstInputRef = React.createRef();
        this.state={
            errorMsg:''
        }
    }

    async componentDidMount(){
        this.firstInputRef.current.focus();
    }
    
    checkVariantNameExist=(name,ignoreItemId)=>{
        let variantsCopy = this.props.variants;
        if(ignoreItemId!=null){
            let ignoreItem=(ii) =>{
                return ii.id !== ignoreItemId;
            }
            variantsCopy = variantsCopy.filter(ignoreItem);
        }
        let variantsNameLC = Object.values(variantsCopy).map(v => v.name.toLowerCase()); 
       
        let variantNameExist = variantsNameLC.includes(name.toLowerCase());
        return variantNameExist;
    }

    saveVariant=()=>{
        if(ALLOW_DUPLICATE_VARIANT_NAME===false && this.checkVariantNameExist(this.props.tempVariant.name)) {
            this.setState({errorMsg:'Variant name already exist'});
        } else {
            this.props.saveNewVariant();
        }
    }
    render(){

        let canSave = this.props.tempVariant.name!=='' && 
            (this.props.sameCostPriceAsParent ||
                 ((this.props.tempVariant.cost!=null && this.props.tempVariant.cost!=='')
                 && (this.props.tempVariant.price!=null && this.props.tempVariant.price!=='')));

        return (
            <div className={'variant-in-div '  + (isMobile?'window-fullscreen':'window')}>
                <p class='c-h4' style={{paddingBottom:'10px'}}>New Variant</p>
                <div class='variant-input'>
                    <p class='c-in2'>Name</p>
                    <TextField id="variant-name-input"  inputRef={this.firstInputRef} required  placeholder="e.g. Small" sx={{width:'100%'}}
                        value={this.props.tempVariant.name} onChange={this.props.changeInputHandler}/>
                    <div style={{display:'flex',marginTop:'10px',opacity:this.props.sameCostPriceAsParent?'0.5':'',pointerEvents:this.props.sameCostPriceAsParent?'none':''}}>
                        <div style={{marginRight:'5px',width:'100%'}}>
                            <p class='c-in2'>Cost</p>
                            <TextField id="variant-cost-input" required type="number"  sx={{width:'100%'}}
                            value={this.props.tempVariant.cost} 
                            onChange={this.props.changeInputHandler}/>
                        </div>
                       
                        <div style={{marginLeft:'5px',width:'100%'}}>
                            <p class='c-in2'>Price</p>
                            <TextField id="variant-price-input" required  type="number"  sx={{width:'100%'}}
                            value={this.props.tempVariant.price} 
                            onChange={this.props.changeInputHandler}/>
                        </div>
                    </div>

                    <div style={{display:'flex',marginTop:'10px',opacity:this.props.sameBarcodeAsParent?'0.5':'',pointerEvents:this.props.sameBarcodeAsParent?'none':''}}>
                        <div style={{width:'100%'}}>
                            <p class='c-in2'>Barcode</p>
                            <TextField id="variant-barcode-input"  sx={{width:'100%'}}
                                value={this.props.tempVariant.barcode}
                                onChange={this.props.changeInputHandler}/>
                        </div>
                    </div>

                </div>
                <br/>
                {(this.props.sameCostPriceAsParent||this.props.sameBarcodeAsParent) && 
                <div className='msg-div'>
                    <p class='c-in2'>Note:</p>
                    {this.props.sameCostPriceAsParent&&
                        <p class='c-p2' style={{color:'rgb(70,70,70)'}}>Same cost and price will be applied</p>}
                    {this.props.sameBarcodeAsParent&&
                        <p class='c-p2' style={{color:'rgb(70,70,70)'}}>Same barcode will be applied</p>}
                </div>}

                <p class='error-msg'>{this.state.errorMsg}</p>

                <div style={{justifyContent:'right',display:'flex',paddingTop:'12px'}}>
                    <button class='btn1 btn-second b100' style={{marginRight:'8px'}} onClick={this.props.onCancel} tabIndex={-1}>
                        {'Cancel'}
                    </button>
                    <button className={'btn1  b100 '} style={canSave?{}:{pointerEvents:'none',cursor:'default',backgroundColor:'lightgray'}} onClick={canSave?this.saveVariant:null} tabIndex={-1}>
                        {'Add'}
                    </button>
                </div>
            </div>
        )
    }
}

export default VariantCreate;