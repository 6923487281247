import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import './OrderTotal.css';
import NotificationUtil from '../../../util/NotificationUtil';
import CartService from '../../../service/CartService';
import {isMobileOnly,isMobile} from 'react-device-detect';

class OrderTotal extends Component {

    pesoWithCommas=(x)=>{
        let roundedX= Math.round((x + Number.EPSILON) * 100) / 100;
        return '₱'+roundedX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render() {
        let cartItems = this.props.items==null?CartService.getCartItems():this.props.items;
        let totalPayable = CartService.getTotalPrice(cartItems);
        return (
            <div className='order-total-div' style={this.props.isSmall?{borderTop:'solid gainsboro 1px'}:{}}>
                    <div className={isMobileOnly?'cart-infos-mobile':'cart-infos'}>
                        {this.props.orderInfo!=null?
                        <div classNmae='order-info-wrapp'>
                            <div className='order-info'>
                                <p>Subtotal</p><p>{this.pesoWithCommas(this.props.orderInfo.subTotalPrice)}</p>
                            </div>
                            <div className='order-info'>
                                <p>Discount</p><p>{this.props.orderInfo.discount.label==null?'-':this.props.orderInfo.discount.label}</p>
                            </div>
                           
                            <div className='order-info' style={{fontSize:this.props.isSmall?'1.1rem':'1.3rem',fontWeight:'600'}}>
                                <p>Total</p><p>{this.pesoWithCommas(this.props.orderInfo.totalPrice)}</p>
                            </div>

                            {/* {otherPaymentDiv} */}
                        </div>
                        :<div className='order-info' style={{fontSize:this.props.isSmall?'1.1rem':'1.3rem',fontWeight:'600'}}>
                            <p>Total</p><p>{this.pesoWithCommas(totalPayable)}</p>
                        </div>}
                        {this.props.orderInfo!=null&&this.props.orderInfo.remainingAmountDue!=null&&
                       <div className='order-info' style={{fontSize:this.props.isSmall?'1.1rem':'1.3rem',fontWeight:'600'}}>
                            <p>Amount Due</p><p>{this.pesoWithCommas(this.props.orderInfo.remainingAmountDue)}</p>
                        </div>}

                    </div>
                  
                    <div className='cart-actions' style={{ alignItems:'center', backgroundColor:'white',display:'flex'}}>
                        {(this.props.onCancelLink || this.props.onCancel)&&
                        <Link class={'btn1 btn-second'} to={this.props.onCancelLink}
                            tabIndex={-1}
                             onClick={this.props.onCancel} style={{ borderRadius:'8px',marginRight:'6px',width:this.props.showCheckout?'fit-content':'100%', height:isMobile?'50px':'70px'}}>
                            Close
                        </Link>}
                        {this.props.showCheckout&&
                        <Link class={isMobileOnly?'btn1 checkout-btn-mobile':'btn1 checkout-btn'}

                            onClick={cartItems.length===0?()=>{NotificationUtil.makeToast('Cart is empty!','white','black',3000,'top-center');}:null}
                            to={cartItems.length>0?'/checkout':'#'}  >
                            {'Checkout'}
                        </Link>}
                        {this.props.actionDiv}

                    </div>
                        
                </div>
        );
    }
}

export default OrderTotal;