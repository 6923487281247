import React, { Component } from 'react';
import {DRINK_ICONS,MEAL_ICONS,DESSERT_ICONS,OTHER_ICONS} from '../../assets/item-icons/ItemIcons.js';
class ItemImage extends Component {

    getIcon=()=>{
        switch(this.props.image.val.group){
            case 'Drinks': return DRINK_ICONS[this.props.image.val.id][this.props.size]; 
            case 'Meal': return MEAL_ICONS[this.props.image.val.id][this.props.size]; 
            case 'Desserts': return DESSERT_ICONS[this.props.image.val.id][this.props.size];
            case 'Others': return OTHER_ICONS[this.props.image.val.id][this.props.size]; 
            default: return [];
        }
    }
    render() {
        if(this.props.image==null){
            return null;
        }

        let imageSrc;
        switch(this.props.image.type){
            case 'icon': imageSrc=this.getIcon(); break;
            default: imageSrc = null; break;
        }

        return (
            <img alt='item icon' src={imageSrc} style={{objectFit:'none',opacity:this.props.opacity?this.props.opacity:''}}/>
        );
    }
}

export default ItemImage;