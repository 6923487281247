
export const INVENTORY_TYPE= Object.freeze({
        RETAIL:'RETAIL'
      , INGREDIENTS_BASED:'INGREDIENTS_BASED'
      , SERVICE_BASED:'SERVICE_BASED'
  });


export const CATEGORY_NAME_LIMIT = 100;
export const ITEM_NAME_LIMIT = 100;
export const BUSINESS_NAME_LIMIT = 120;
export const BUSINESS_ADDRESS_LIMIT = 250;
export const EMAIL_LIMIT = 254;
export const NAME_LIMIT = 200;

export const RECEIPT_MESSAGE_LIMIT = 250;


export const SECTION= Object.freeze({
  REGISTER:{label:'Register', path:'/register'},
  TRANSACTIONS:{label:'Transactions', path:'/transactions'},
  CASH_DRAWER:{label:'Cash Drawer', path:'/drawer'},
  ITEMS:{label:'Items', path:'/items/category'},
  SETTINGS:{label:'Settings', path:'/settings'},
  INVENTORY:{label:'Inventory', path:'/inventory'},
});

export const LOGIN_STATUS= Object.freeze({
  NONE:'NONE',
  LOGIN_SELECT:'LOGIN_SELECT',
  LOGIN_BY_EMAIL:'LOGIN_BY_EMAIL',
  LOGIN_EMAIL_NOT_VERIFIED:'LOGIN_EMAIL_NOT_VERIFIED',

  LOGIN_FORGOT_PW:'LOGIN_FORGOT_PW',
  
  SIGNUP_SELECT:'SIGNUP_SELECT',
  SIGNUP_BY_EMAIL:'SIGNUP_BY_EMAIL',
  SIGNUP_VERIFY_EMAIL:'SIGNUP_VERIFY_EMAIL'
});


export const VARIANT_LIMIT = 100;

export const BOTTOM_BAR_HEIGHT=45;
export const ALLOW_DUPLICATE_VARIANT_NAME = false;

export const DATE_RANGES= Object.freeze({
  TODAY:"TODAY"
});

export const DEFAULT_REPORT_DATE_RANGE = DATE_RANGES.TODAY;

export const PAYMENT_METHODS= Object.freeze({
  cash:{index:0,id:'cash',label:'Cash'},
  card:{index:1,id:'card',label:'Credit/Debit Card'},
  bank:{index:2,id:'bank',label:'Bank Transfer'},
  ewallet:{index:3,id:'ewallet',label:'E-Wallet (GCash, PayMaya, etc.)'},
});
