
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class NotificationUtil {
    makeToast = (msg, backgroundColor, color, millis, position,fontWeight) => toast(msg, {
        containerId: 'AppToaster',
        position: position,
        autoClose: millis,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style:{backgroundColor:backgroundColor,color:color,fontSize:'1.1rem',fontWeight:fontWeight!=null?fontWeight:''}
    });
}

export default new NotificationUtil()