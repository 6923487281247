import React, { Component } from 'react';

class ReceiptBasic extends Component {


    async componentDidMount(){
    }
    numberWithCommas=(x)=>{
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    renderOrderList=()=>{
        let orderDivs=[];
        this.props.orderInfo.items.forEach((orderItem,idx)=>{
             
            orderDivs.push(
                <div className='sale-order-item'>
                    <div style={{display:'flex',overflow:'auto'}}>
                        <p style={{minWidth:'fit-content'}}>{orderItem.name + (orderItem.qty>1?' ('+orderItem.qty+')':'')}</p>
                        <p style={{minWidth:'fit-content'}}>{}</p>
                        <div class='dot-dot-dot'/>
                        <p>₱{this.numberWithCommas(orderItem.price*orderItem.qty)}</p>
                    </div>
                </div>
            );
        });

        return (
            <div className='sale-order-div'>
                {orderDivs}
            </div>
        )
    }

    render() {
        return (
            <div className='receipt-basic' style={{padding:'16px',backgroundColor:'rgb(254, 254, 254)'}}>
                <div style={{textAlign:'center'}}>
                    <p>{this.props.exchange.storedSession.business.name}</p>
                    <p>{this.props.exchange.storedSession.business.parentCompany}</p>
                    <p>{this.props.exchange.storedSession.business.address}</p>
                    <p>{"Total: ₱" + this.props.orderInfo.amountDue}</p>
                    <p>{"Change: ₱" + this.props.orderInfo.change}</p>
                </div>
                {this.renderOrderList()}
            </div>
        );
    }
}

export default ReceiptBasic;