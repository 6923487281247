import React, { Component } from 'react';
import Util from '../../util/Util';
import {LOGIN_STATUS} from '../../constants.js';
import TextField from '@mui/material/TextField';
import { auth } from "../../integrations/firebase";
import CircularProgress from '@mui/material/CircularProgress';

import {sendPasswordResetEmail} from 'firebase/auth';

class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            email:'',
            onLoading:false,
            errorMessage:'',
            onSuccess:false
        }
    }

    changeInputHandler=(event)=>{
        switch(event.target.id){
            case 'email-input':this.setState({email:event.target.value, errorMessage:''}); break;
            default:break;
        }
    }

    onPasswordResetClicked=()=>{
        this.setState({onLoading:true},()=>{
            if(this.state.email===''){
                this.setState({onLoading:false,errorMessage:"Please enter your email address"});
            }else if(Util.checkIfEmailValid(this.state.email)===false){
                this.setState({onLoading:false,errorMessage:"Invalid email address"});
            }
            else{
                sendPasswordResetEmail(auth, this.state.email)
                    .then(() => {
                        this.setState({onLoading:false,onSuccess:true});
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.log('errorCode',errorCode)
                        console.log('errorMessage',errorMessage)
                        switch(error.code){
                            case "auth/user-not-found": this.setState({onLoading:false,errorMessage:"Email not found!"});break;
                            default:  this.setState({onLoading:false,errorMessage:errorMessage});break;
                        }
                    });
            }

        });
    }

    renderSuccessReset=()=>{
        return (
            <div className='suc-rest' style={{textAlign:'left',pointerEvents:this.state.onLoading?'none':''}}>
                <h1 class="c-h2" style={{margin:'8px 0px 10px 4px'}}>Check your email</h1>
                <h2 class="c-p1" style={{marginBottom:26}}>We have sent a link to reset your Cashr account password</h2>

                <button class="btn1 b100" onClick={()=>this.props.setLoginStatus(LOGIN_STATUS.LOGIN_BY_EMAIL)}>
                    Go to login
                </button> 
                <br/>
                <div style={{textAlign:'center'}}>
                    <span>Didn't get e-mail? </span>
                    <span className='link-1' onClick={this.onPasswordResetClicked}>Resend</span>
                    {this.state.onLoading&&<span><CircularProgress size="12px" sx={{marginLeft:'6px'}}/></span>}
                </div>
            </div>
        )
    }

    render() {
        return (
            this.state.onSuccess? this.renderSuccessReset():
            <div className='verify-email' style={{pointerEvents:this.state.onLoading?'none':''}}>
                <div style={{display:'flex'}}>
                    {this.props.backButton}
                    <h1 class="c-h3" style={{margin:'8px 0px 14px 0px',textAlign:'center',width:'100%'}}>Forgot password?</h1>
                </div>
                <h2 class="c-p1" style={{marginBottom:26,textAlign:'center'}}>{"Don't worry, we'll send you a message to help you reset your password."}</h2>
                <TextField 
                    id="email-input" 
                    label="Email"
                    error={this.state.errorMessage!==''}
                    variant="outlined"
                    value={this.state.email}
                    onChange={this.changeInputHandler}
                    color='success'
                    sx={{width:'100%',marginBottom:'20px'}}
                />
               
               <p style={{color:'red',textAlign:'center'}}>{this.state.errorMessage}</p>
                <br/>
                <button class="btn1 log-btns b100" onClick={this.onPasswordResetClicked} tabIndex={-1}>
                   {this.state.onLoading?<CircularProgress size="24px" sx={{color:'white'}}/>:"Continue"}
                </button> 
            </div>
        );
    }
}

export default ForgotPassword;