import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OrderTotal from '../order/OrderTotal'
import CloseableModal from '../../../components/CloseableModal';

class TotalWindow extends Component {
    render() {
        return (
            <CloseableModal content={
                <div className='window total-window'>
                    <p style={{marginBottom:'4px'}}>Order Total</p>
                    <OrderTotal/>
                    <div className='tot-action-div' style={{}}>
                        <Link class='btn1 btn-second link-2' to={this.props.onCloseLink} 
                            style={{ color:'rgb(66,43,163)'}}>
                            Close
                        </Link>
                    </div>
                </div>
            }
            />
        );
    }
}

export default TotalWindow;