import React, { Component } from 'react';
import Modal from 'react-modal';
import {LOGIN_STATUS} from '../constants.js';
import { GENERIC_MODAL_STYLE } from '../css/ModalProperties';
import LoginComponent from './LoginComponent';
import SignupComponent from './logins/SignupComponent';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';

class LoginSignupModal extends Component {
    render() {
        let isLogin = this.props.status===LOGIN_STATUS.LOGIN_SELECT || this.props.status === LOGIN_STATUS.LOGIN_BY_EMAIL 
                    || this.props.status===LOGIN_STATUS.LOGIN_EMAIL_NOT_VERIFIED  || this.props.status === LOGIN_STATUS.LOGIN_FORGOT_PW;
        return (
            <Modal id="login-modal-container" isOpen={true} style={GENERIC_MODAL_STYLE}>   
                <div className='login-modal' style={{height:'100%', marginTop:'30vh',marginBottom:'30vh',padding:'40px'}}>
                    <div style={{display:'flex',justifyContent:'right'}}>
                        <IconButton sx={{padding:'10px',position:'absolute'}} aria-label="close" onClick={()=>this.props.setLoginStatus(LOGIN_STATUS.NONE)}><CancelIcon/></IconButton>
                    </div>
                    {isLogin?
                         <LoginComponent status={this.props.status} setLoginStatus={this.props.setLoginStatus}/>
                        :<SignupComponent status={this.props.status} setLoginStatus={this.props.setLoginStatus}/>
                    }
                    
                </div>
            </Modal>
        );
    }
}

export default LoginSignupModal;