import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import {BrowserView,MobileView,isMobile} from 'react-device-detect'; 
import NotificationUtil from '../../util/NotificationUtil';

import FirebaseService from '../../service/FirebaseService';
import './WindowStyles.css'
import {CATEGORY_NAME_LIMIT} from '../../constants';
class AddCategory extends Component {

    constructor(props) {
        super(props);
        this.closeButtonRef = React.createRef();
        this.state = {
            name:'',
            message:''
        } 
    }

    changeInputHandler=(event)=>{
        switch(event.target.id){
            case 'category-input':
                if(event.target.value.length<CATEGORY_NAME_LIMIT){
                    this.setState({name:event.target.value});
                }
            break;
        default:
                break;
        }
    }

    doesCategoryNameExist(name){
        let categoryExist = false;
        if(this.props.business.menu!=null && this.props.business.menu.category!=null ){
            let categoryLowercase = Object.values(this.props.business.menu.category).map(v => v.toLowerCase()); 
            categoryExist = categoryLowercase.includes(name.toLowerCase());
        }
        return categoryExist;
    }

    onSaveCategoryClicked=()=>{
        if(this.canSave()===false) {return null}

        let categoryExist = this.doesCategoryNameExist(this.state.name);
        if(categoryExist){
            this.setState({message:'Category already exist'})
            NotificationUtil.makeToast('Category already exist!','rgb(55,66,71)','white',2500,'top-right');

        } 
        else {
            let businessId = this.props.business.id;
            this.setState({message:''});
            FirebaseService.addMenuCategory(
                businessId
               ,this.state.name
               ,this.onSuccessSave
               ,()=>{ console.log('failed to save category callback')});
        }
      
    }

    onSuccessSave=()=>{
        this.closeButtonRef.current.click()
        this.props.exchange.refreshCache();
        NotificationUtil.makeToast('New category added!','rgb(66,43,163)','white',3000,'top-right',700);
    }

    canSave=()=>{
        return  this.state.name.trim()!=='';;
    }

    render() {
        let canSave =this.canSave();
        return (
            <div className={'add-category-component ' + (isMobile?'window-fullscreen':'window')}>

                    <BrowserView><h1 class='c-h2' style={{margin:'12px 0px 18px 0px'}}>Add New Category</h1></BrowserView>
                    <MobileView> <h1 class='c-h4' style={{margin:'12px 0px 18px 0px'}}>Add New Category</h1></MobileView>
               
                <div style={{display:'flex'}}>
                    <TextField 
                        id="category-input" 
                        label="Name" 
                        sx={{width:'100%',marginBottom:'6px'}} 
                        value={this.state.name} 
                        onChange={this.changeInputHandler}
                        required 
                        />
                </div>

                <p class='error-msg'>{this.state.message}</p>
                <div className='action-btns-ac' style={{display:'flex'}}>
                    <Link class='btn1 btn-second link-2' to={this.props.onCloseLink} 
                        ref={this.closeButtonRef}
                        style={{ color:'rgb(66,43,163)', minWidth:'48%',marginRight:'4px'}}>
                        Cancel
                    </Link>
                    <button class={'btn1 b100 ' + (canSave?'':' -btn1-disabled')} onClick={this.onSaveCategoryClicked}>
                        Save
                    </button>
                </div>
            </div>
        );
    }
}

export default AddCategory;