import React, { Component } from 'react';
import moment from "moment"
import { Link } from 'react-router-dom';
import SubscriptionOption from '../subscription/SubscriptionOption'
import { collection, doc, getDoc } from "firebase/firestore";
import { firestoreDb } from '../../integrations/firebase';
import { TRIAL_PRODUCT} from '../../service/SubscriptionService'
import SubscriptionService from '../../service/SubscriptionService'
import Skeleton from 'react-loading-skeleton'


// Firestore constants =====
const PRODUCT_COLLECTION = 'products'
// Firestore constants =====
class SubscriptionSettings extends Component {

    constructor(props) {
        super(props);

        this.productsViewRef = React.createRef()
        this.state = {
              onLoad:false
            , showProducts:false
        }

    }

    async componentDidMount(){
    }

    scrollToBottom = () => {
        this.productsViewRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    render() {
        return (
            <div class='subscription-settings' style={{pointerEvents:this.state.onLoad?'none':'',width:'100%'}}>
                <div className='subscription-main-content' style={{ padding:'12px 5vw',width:'100%'}}>
                    <SubscriptionView  {...this.props} showChoosePlan={()=>this.setState({showProducts:true},this.scrollToBottom)}/>
                </div>
                <br/><br/>

                {this.state.showProducts&&
                    <ProductsView vRef={this.productsViewRef} {...this.props} />}
                <br/><br/><br/><br/><br/><br/>
            </div>
        );
    }
}

class SubscriptionView extends Component{

   
    constructor(props) {
        super(props);

        this.state = {
              onLoad:true
            , user: null
            , product:null
            , showProducts:false
        }

    } 
    async componentDidMount(){
        let user = await this.getUser()

        let product = null
        if(user!=null){
            product = await this.getProduct(user.productId)
            console.log('ss product',product)
        }

        this.setState({user:user, product:product, onLoad:false})
        
    }

    getProduct=async(productId)=>{ 
        let product = null
        await getDoc(doc(collection(firestoreDb, PRODUCT_COLLECTION), productId)).then(snap => {
             product=snap.data();
        })
        return product
    }

    getUser=async()=>{  
        if( this.props.exchange.firebaseUser==null) {
            return null
        } 
        let user = await SubscriptionService.getUser(this.props.exchange.firebaseUser.uid);
        return user 
    }

    render(){
        let featureDiv =[]
        if(this.state.product!=null){
            if(this.state.product.featureList!=null){
                this.state.product.featureList.forEach(ftr=>{
                    featureDiv.push(<p style={{margin:'4px 8px'}}>{'✓ '+ftr}</p>)
                })
            }
        }

        let nextBillingDate = null
        let isTrial = false
        if(this.state.user!=null){
            nextBillingDate = moment(this.state.user.billingDate)
            isTrial = this.state.user.productId===TRIAL_PRODUCT
        }

        if(this.state.onLoad)
            return(
                <Skeleton inline={true} width={'100%%'} height={'420px'}  baseColor="rgb(236, 238, 241)"  highlightColor="rgb(240, 243, 246)" style={{marginRight:'6px'}} borderRadius={10}/>
                )
        
        return (
            this.state.product==null?
            <div className='subscription-details'>
                <div style={{padding:'10px'}}>
                    <h3>Subsciption not found</h3>
                    <p>No subscription is linked to your account</p>
                </div>
            </div>:
            <div className='subscription-details'>
                <div style={{padding:'10px'}}>
                    <p style={{fontSize:'12px'}}>Your plan</p>
                    <h2>{this.state.product.shortname}</h2>
                    {nextBillingDate&&
                    <p style={{fontSize:'14px'}}>
                        <span>Your next billing date is </span>
                        <span style={{fontWeight:'800'}}>{' '+nextBillingDate.fromNow()}</span> 
                    </p>}
                </div>
                <div style={{backgroundColor:'rgb(40,40,40)',color:'white',padding:'28px 10px 12px 12px'}}>
                    <p style={{fontWeight:'800',fontSize:'16px'}}>{this.state.product.name}</p>
                    <div style={{fontSize:'14px'}}>
                        {featureDiv}
                    </div> 
                <br/>
                {this.state.user!=null&&
                    <div className='billing-details'>
                        <p>
                            <span style={{fontWeight:'800'}}>{isTrial?'Trial End: ':'Billing Date: '}</span> 
                            <span style={{fontWeight:'500'}}>{nextBillingDate.format('LL')}</span> 
                        </p>
                        
                        {isTrial&&
                            <Link 
                            className='btn1 btn-magenta' onClick={this.props.showChoosePlan} style={{width:'200px', padding:'0px 20px',marginTop:'8px'}}>
                                Choose Plan
                            </Link>}
                    </div>}

                    {/* <div style={{display:'flex',justifyContent:'flex-end'}}>
                        <button className='btn1 btn-dkgray' style={{padding:'0px 20px'}}>Update</button>
                    </div>
                */}
                <br/>
                </div>
            </div>
        )
    }
}
class ProductsView extends Component{

    constructor(props) {
        super(props);
        this.state = {
            products:null
        }

    }

    async componentDidMount(){
        let products = await SubscriptionService.getProducts()
        Object.values(products).forEach(async(product)=>{
            console.log(product)
            let price = await SubscriptionService.getPrice(product.id, product.defaultPriceId)
                products[product.id].price = price
        })
        this.setState({products:products})
    }

    render(){
        let productDivs = []
        if(this.state.products!=null){
            Object.values(this.state.products).forEach(product=>{
                productDivs.push(
                    <SubscriptionOption
                        product={product}
                        storedSession={this.props.exchange.storedSession}
                    />
                )
            })
        }
        return(
            <div className='products-view' style={{backgroundColor:'rgb(230,230,230)',padding:'20px 5vw'}}>
                <h2>Select your plan</h2>
                <div className='product-divss' style={{minHeight:'300px'}}>
                {productDivs}
                </div>
                <div ref={this.props.vRef} style={{height:'40px'}}></div>
            </div>
        )
    }
}

export default SubscriptionSettings;