import React, { Component } from 'react';
import {isMobile} from 'react-device-detect';

class ProductSettings extends Component {
    render() {
        return (
            <div className={'product-settings subsettings '+(isMobile?' subsettings-mobile':'')}>
                <p class='c-h4'>Item Settings</p>
            </div>
        );
    }
}

export default ProductSettings;