import { getDatabase, ref, child, get, push, update } from "firebase/database";
import {db} from '../../../integrations/firebase';
const dbRef = ref(getDatabase());
const DRAWER_PATH='drawer';
class DrawerService {

    updateStarting = async (businessId, addCash) => {  
       
        let today = new Date(new Date().setHours(0,0,0,0));

        let path = DRAWER_PATH+'/'+businessId+'/'+today.valueOf()+'/startingCash';

        const newKey = push(child(ref(db), path)).key;

        const updates = {};
        updates[path+'/'+newKey] = addCash;
      
        return update(ref(db), updates);
    }


    deleteStarting = async (businessId) => {  
       
        let today = new Date(new Date().setHours(0,0,0,0));

        let path = DRAWER_PATH+'/'+businessId+'/'+today.valueOf()+'/startingCash';

        const newKey = push(child(ref(db), path)).key;

        const updates = {};
        updates[path+'/'+newKey] = {amount:0,remarks:'****Removed starting cash****',date:new Date()};
      
        return update(ref(db), updates);
    }

    getDrawerByDay = async (businessId, date) => {
        date = date.setHours(0,0,0,0);
        return get(child(dbRef, DRAWER_PATH+'/'+businessId+'/'+date));
    }

    addDrawerCash = async (businessId, addCash) => {  
       
        let today = new Date(new Date().setHours(0,0,0,0));
        let path = DRAWER_PATH+'/'+businessId+'/'+today.valueOf()+'/cashAdded';

        const newKey = push(child(ref(db), path)).key;

        addCash.date = new Date().toISOString();
        addCash.id=newKey;

        const updates = {};
        updates[path+'/'+newKey] =  addCash;
      
        return update(ref(db), updates);
    }
    
    deleteCashItem= async(businessId, id, type)=>{
        if(type==='ADD'){
            return this.deleteAddCash(businessId, id);
        }
        else if(type==='EXPENSE'){
            return this.deleteExpense(businessId, id);
        }
    }

    deleteAddCash= async(businessId, id)=>{
        let today = new Date(new Date().setHours(0,0,0,0));
        let path = DRAWER_PATH+'/'+businessId+'/'+today.valueOf()+'/cashAdded/'+id;
        const updates = {};
        updates[path+'/removed'] = new Date();
      
        return update(ref(db), updates);

    }

    deleteExpense= async(businessId, id)=>{
        let today = new Date(new Date().setHours(0,0,0,0));
        let path = DRAWER_PATH+'/'+businessId+'/'+today.valueOf()+'/expenses/'+id;
        const updates = {};
        updates[path+'/removed'] = new Date();
      
        return update(ref(db), updates);
    }

    addCashExpense = async (businessId, expense) => {  
        let today = new Date(new Date().setHours(0,0,0,0));
        let path = DRAWER_PATH+'/'+businessId+'/'+today.valueOf()+'/expenses';

        const newKey = push(child(ref(db), path)).key;
        expense.date = new Date().toISOString();
        expense.id=newKey;
        const updates = {};
        updates[path+'/'+newKey] =  expense;
      
        return update(ref(db), updates);
    }

    clearDrawer = async (businessId) => {  
        let today = new Date(new Date().setHours(0,0,0,0));
        let path = DRAWER_PATH+'/'+businessId+'/'+today.valueOf();

        let lastClearedPath = path+'/clearEvent';
        const newKey = push(child(ref(db), lastClearedPath)).key;
        

        // let startingCashPath = path+'/startingCash';
        // const newStartingCashKey = push(child(ref(db), startingCashPath)).key;
 
        const updates = {};
        updates[lastClearedPath+'/'+newKey] = new Date().valueOf();
        // updates[startingCashPath+'/'+newStartingCashKey] = {amount:0,remarks:'****Removed starting cash****',date:new Date()};
        updates[path+'/sales'] = null;
      
        return update(ref(db), updates);
    }
    

}

export default new DrawerService()