import React, { Component } from 'react';
import './PosHome.css';
import TransactionsPage from './transactions/TransactionsPage';
 
class PosHome extends Component {
    async componentDidMount(){
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className='pos-home-page page'>
                <TransactionsPage exchange={this.props.exchange}/>
            </div>
        );
    }
}

export default PosHome;