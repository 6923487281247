import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NotificationUtil from '../../util/NotificationUtil';
import ItemUtil from '../../service/ItemUtil';
import { isMobile} from 'react-device-detect';

class VariantSelect extends Component {

    getStock=(variant)=>{
        if(this.props.inventory!=null && this.props.inventory.stock!=null && 
            this.props.inventory.stock.items!=null && this.props.inventory.stock.items[variant.id]!=null) {
              return this.props.inventory.stock.items[variant.id];
        }
        return {inStock:0,deducted:0};
    }

    pesoWithCommas=(x)=>{
        let roundedX= Math.round((x + Number.EPSILON) * 100) / 100;
        return '₱'+roundedX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render() {

        let variantsDiv = [];
        ItemUtil.getVariants(this.props.item).forEach((variant,idx)=>{
            let qty = 0;
            let trackStock = this.props.item.trackStock!=null && this.props.item.trackStock;

            let indexInCart=this.props.cartItems.map(cartEntry=>cartEntry.item.variantId).indexOf(variant.id);
            if(indexInCart>=0){
                qty=this.props.cartItems[indexInCart].qty;
            }
            let stockInfo = null;
            let isOutOfStock = false;
            let orderLimitReached =  false;
            if(trackStock){
                stockInfo = this.getStock(variant);
                isOutOfStock = stockInfo.inStock<=0;
                orderLimitReached = stockInfo.inStock>0&&stockInfo.inStock === qty;
            }
            variantsDiv.push(
                    <div className='variant-option-wrpr' tabIndex={-1} style={{}}>
                        {qty>0&&
                        <div class='cart-qty-cont' style={{position:'relative'}}>
                            <div style={{position:'absolute', width:'100%',display:'flex', alignItems:'right',justifyContent:'right',marginLeft:'6px',marginTop:'-6px'}}>
                                <div class='cart-qty' style={{marginRight:'8px',marginTop:'8px'}}>{qty}</div>
                            </div>
                        </div>}

                        <button className={'btn1 variant-option' + (isOutOfStock || orderLimitReached?' variant-option-outofstock':'')} 
                            tabIndex={-1}
                            onClick={()=>{
                                if(trackStock===false || stockInfo===null || stockInfo.inStock-qty-1>=0){
                                    this.props.variantClicked(this.props.item, variant);
                                }else{ 
                                    NotificationUtil.makeToast(isOutOfStock?'Out of stock':'Stock limit reached','rgb(228,34,19)','white',3000,'top-center');
                                }
                            }}>
                        <p className='vopt-name'>{variant.name}</p>
                        <p className='vopt-price'>{this.pesoWithCommas(variant.price)}</p>
                        {trackStock&&<p style={{fontSize:'12px',color:'rgb(120,120,120)',marginTop:'4px'}}>{stockInfo.inStock>0?'Stock: '+stockInfo.inStock:'Out of stock'}</p>}
                    </button>
                    {trackStock&&<p style={{fontSize:'0.8rem',color:'rgb(80,80,80)',marginTop:'-9px'}}>{orderLimitReached?'Order limit reached':'\u00a0'}</p>}
                </div>
            );
        });


        return (
            <div className={'variant-select-window ' + (!isMobile?'window':'window-fullscreen')}>
                <h1 class='c-p1' style={{margin:'12px 0px 0px 0px',color:'rgb(100,100,100)'}}>
                    {this.props.item.name}
                </h1>
                <h2 class='c-h3' style={{margin:'0px 0px 0px 0px'}}>
                    {'Select variant'}
                </h2>
                <div className='variant-opt'>
                    {variantsDiv}
                </div>
                <div class='cntrols-variant' style={{display:'flex',alignItems:"center",justifyContent:'center',marginTop:'6px',marginBottom:'9px'}}>
                    <Link className='btn1 window-action' style={{display:'flex'}} to={this.props.onCancelLink} tabIndex={-1}>
                        <p>Close</p>
                    </Link>
                </div>
            </div>
        );
    }
}

export default VariantSelect;