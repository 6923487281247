import React, { Component } from 'react';

class PrivacyPolicy extends Component {
    render() {
        return (
            <div class='privacy-policy-page' style={{paddingTop:'70px'}}>
                <h2>Privacy Policy</h2>
                <h3>To be updated</h3>
            </div>
        );
    }
}

export default PrivacyPolicy;