import React, { Component } from 'react';
import Util from '../../util/Util';
import { getAuth } from "firebase/auth";
import {LOGIN_STATUS} from '../../constants.js';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { auth } from "../../integrations/firebase";
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FirebaseService from '../../service/FirebaseService';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import {createUserWithEmailAndPassword,sendEmailVerification} from 'firebase/auth';

const PASSWORD_DOES_NOT_MATCH = 'Password does not match';
class SignUpByEmail extends Component {

    constructor(props) {
        super(props); 
        this.state ={
            email:'',
            password:'',
            confirmPassword:'',
            errInput:'',
            errorMsg:'',
            showPassword:false,
            showConfirmPassword:false,
            onLoad:false
        }
    }

    createAccountClicked=async()=>{ 
        this.setState({onLoad:true},()=>{
            let errInput = '';
            let errorMsg = '';
            if(this.state.email===''){
                errInput=errInput+' email';
            }else if(Util.checkIfEmailValid(this.state.email)===false){
                errInput=errInput+' email';
                errorMsg='Invalid email address';
            }

            if(this.state.password===''){
                errInput=errInput+' password';
            }
            if(this.state.confirmPassword===''){
                errInput=errInput+' confirm-pw';
            }

            if(this.state.password!==''&& this.state.confirmPassword !=='' 
                && this.state.password!==this.state.confirmPassword && errorMsg===''){
                errInput=errInput+' password confirm-pw';
                errorMsg=PASSWORD_DOES_NOT_MATCH;
            }else if(this.state.password!=='' && this.state.confirmPassword!=='' && errorMsg===''){
               let passwordCheck = this.validatePassword(this.state.password)
                if(passwordCheck!=null){
                    errorMsg=passwordCheck;
                }
            }

            if(this.state.email==='' ||this.state.password==='' ||this.state.confirmPassword==='' ){
                errorMsg='Please complete all inputs';
            }
            if(errInput!==''||errorMsg!==''){
                this.setState({onLoad:false,errInput:errInput,errorMsg:errorMsg});
            }
            else{
                this.setState({onLoad:true,errInput:'',errorMsg:''}, this.createAccount);
            }
        });
    } 

    validatePassword=(pw)=>{
        if(pw.length<6){
            return 'Password should be at least 6 characters'
        } else if(/\d/.test(pw)===false){
            return 'Password should have at least one number';;
        }
        return null;
    }

    createAccount=async()=>{
        createUserWithEmailAndPassword(auth,this.state.email,this.state.password).then((response)=>{ 
            FirebaseService.signUpFirebaseUser(response.user).then(()=>{
                sendEmailVerification(response.user).then(()=>{
                    getAuth().signOut();
                    this.props.setLoginStatus(LOGIN_STATUS.SIGNUP_VERIFY_EMAIL);
                }).catch((verErr)=>{
                    console.log('verErr',verErr);
                    this.setState({onLoad:false,errInput:'',errorMsg:'Something went wrong. Please try again later.['+verErr.code+']'});
                });}
            );
        }).catch((error)=>{
            switch(error.code){
                case "auth/email-already-in-use": this.setState({onLoad:false,errorMsg:'Email already taken'}); break; 
                case "auth/weak-password": 
                    if(error.message === 'Firebase: Password should be at least 6 characters (auth/weak-password).'){
                        this.setState({onLoad:false,errorMsg:'Password should be at least 6 characters'})
                    } else {
                        this.setState({onLoad:false,errorMsg:'Password does not meet minimum requirements'})
                    }
                break; 
                case "auth/network-request-failed": this.setState({onLoad:false,errorMsg:'Network error. Check your connection and try again.'}); break; 
                default: this.setState({onLoad:false,errorMsg:error.code}); break; 
            }
        });
    }

    changeInputHandler=(event)=>{
        let errInput = this.state.errInput;
        let errorMsg = this.state.errorMsg;
        switch(event.target.id){
            case 'email-input': 
                errInput=errInput.replace(' email','');
                this.setState({email:event.target.value,errInput:errInput,errorMsg:''});
            break;
            case 'password-input':
                errInput=errInput.replace(' password','');   
                if(errorMsg===PASSWORD_DOES_NOT_MATCH){ errInput=errInput.replace(' confirm-pw',''); }
                this.setState({password:event.target.value,errInput:errInput,errorMsg:''});  
            break;
            case 'confirm-password-input': 
                errInput=errInput.replace(' confirm-pw','');
                if(errorMsg===PASSWORD_DOES_NOT_MATCH){ errInput=errInput.replace(' password',''); }
                this.setState({confirmPassword:event.target.value,errInput:errInput,errorMsg:''}); 
            break;
            default:break;
        }
    }

    renderSignupFields=()=>{
        return (
        <div className='signup-fields' style={this.state.onLoad?{pointerEvents:'none'}:{}}>   
            <div style={{display:'flex'}}>
                {this.props.backButton}
                <h1 class="c-h3" style={{textAlign:'center',width:'100%'}}>Create your account</h1>
            </div>
            <br/>
            <TextField 
                id="email-input" 
                label="Email"
                variant="outlined"
                error={this.state.errInput.includes('email')}
                value={this.state.email}
                placeholder='Email address'
                onChange={this.changeInputHandler}
                color='success'
                autoComplete='off'
                sx={{width:'100%',marginBottom:'20px'}}
            />
            <FormControl sx={{ m: 1, width: '100%', margin:'0px 0px 18px 0px' }} variant="outlined">  
                <OutlinedInput
                    id="password-input"
                    error={this.state.errInput.includes('password')}
                    value={this.state.password}
                    color='success'
                    autoComplete='off'
                    placeholder='Password'
                    onChange={this.changeInputHandler} 
                    type={this.state.showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={()=>{this.setState({showPassword:!this.state.showPassword});}}
                                edge="end">
                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>}
                        />
            </FormControl>

            <FormControl sx={{ m: 1, width: '100%', margin:'0px 0px 14px 0px' }} variant="outlined">  
                <OutlinedInput
                    id="confirm-password-input"
                    error={this.state.errInput.includes('confirm-pw')}
                    value={this.state.confirmPassword}
                    color='success'
                    autoComplete='off'
                    placeholder='Confirm Password'
                    onChange={this.changeInputHandler} 
                    type={this.state.showConfirmPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={()=>{this.setState({showConfirmPassword:!this.state.showConfirmPassword});}}
                                edge="end">
                                {this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>}
                        />
            </FormControl>

            <p class='error-msg'>{this.state.errorMsg}</p>

            <button class="btn1 b100 log-btns" style={{marginTop:'10px'}} onClick={this.createAccountClicked}>
                {this.state.onLoad?<CircularProgress/>:'Create Account'}
            </button>
            <p style={{width:'100%',textAlign:'center',marginTop:'20px'}}>
                <span>Already have an account? </span>
                <span className='link-1' onClick={()=>this.props.setLoginStatus(LOGIN_STATUS.LOGIN_SELECT)}>Login now</span>
            </p>
        </div>
        );
    }

    resendEmailVerification=()=>{

    }

    renderVerifyEmail=()=>{
        return(
            <div className='verify-email'>
                <h1 class="c-h2" style={{margin:'8px 0px 10px 4px'}}>Verify your email address</h1>
                <h2 class="c-p1" style={{marginBottom:26,textAlign:'center'}}>{"To complete your profile, verify your account by clicking the link sent to your email"}</h2>
                <button class="btn1 b100" onClick={()=>this.props.setLoginStatus(LOGIN_STATUS.LOGIN_BY_EMAIL)}>
                  Go to login
                </button>
                <br/>
                <div style={{textAlign:'center'}}>
                    <span>Didn't get e-mail? </span>
                    <span className='link-1' onClick={this.resendEmailVerification}>Resend verification</span>
                </div>
            </div>
        )
    }

    render() {
        let content = null;
        switch(this.props.status){
            case LOGIN_STATUS.SIGNUP_BY_EMAIL:      content=this.renderSignupFields();   break;
            case LOGIN_STATUS.SIGNUP_VERIFY_EMAIL:  content=this.renderVerifyEmail();    break;
            default: break;
        }
        return (
            <div class='email-login'>
                {content}
            </div>
        );
    }
}

export default SignUpByEmail;